import { spaces } from "stores";
import { set } from "immutable-modify";

export const board = {

    handleSearchOnColumns: (searchText) => {
        console.log("handleSearchOnColumns");
        console.log(searchText);


        if (searchText !== '') {

            const searchFields = ['title']; // Array of search fields

            let countNonDisabledTasks = 0;

            const updatedData = spaces().space.board.columns.map((status) => {
                const updatedTasks = status.tasks.map((task) => {
                    const isMatching = searchFields.some(
                        (field) => {
                            return task[field] && task[field].toLowerCase().includes(searchText.toLowerCase())
                        }
                    );
                    return {
                        ...task,
                        disabled: !isMatching,
                    };
                });

                //Count number of non disabled tasks
                const hasMatchingTasks = updatedTasks.some((task) => !task.disabled);
                const numNonDisabledTasks = updatedTasks.filter(
                    (task) => !task.disabled || task.disabled === false
                ).length;

                console.log("numNonDisabledTasks");
                console.log(numNonDisabledTasks);

                countNonDisabledTasks += numNonDisabledTasks;



                return {
                    ...status,
                    disabled: !hasMatchingTasks,
                    tasks: updatedTasks,
                };
            });

            //update columns with filtered tasks
            spaces(set(spaces(), "space.board.columns", updatedData));

            //set search text on store
            spaces(set(spaces(), "space.board.search", {
                text: searchText,
                count: countNonDisabledTasks // To determine if we should show the board or not
            }));


        } else {

            console.log("searchText is empty");

            if (spaces().space.board.columns === null) return;

            // set disabled to false on every task and column
            const updatedData = spaces().space.board.columns.map((status) => {
                const updatedTasks = status.tasks.map((task) => {
                    return {
                        ...task,
                        disabled: false,
                    };
                });

                return {
                    ...status,
                    disabled: false,
                    tasks: updatedTasks,
                };
            }
            );

            //update columns with filtered tasks
            spaces(set(spaces(), "space.board.columns", updatedData));

            //set search text on store
            spaces(set(spaces(), "space.board.search", {
                text: '',
                count: 0, // To determine if we should show the board or not
            }));

        }


    },

    handleDragStart: ({ active }) => {
        console.log("handleDragStart");
        console.log(active);
        spaces(set(spaces(), "space.board.activeItem", {
            item: active.data.current.item,
            type: active.data.current.type,
            index: active.data.current.sortable.index,
            containerId: active.data.current.sortable.containerId
        }))
    },

    handleDragCancel: () => {
        spaces(set(spaces(), "space.board.activeItem", null));
    },

    handleColumnRename: (value, columnId) => {
        console.log("handleColumnRename");
        console.log(value);
        console.log(columnId);

        const columns = spaces().space.board.columns;
        const columnIndex = columns.findIndex(column => column.id === columnId);

        console.log(columnIndex);

        if (columnIndex > -1) {
            const updatedColumn = {
                ...columns[columnIndex],
                title: value
            };

            const updatedColumns = [
                ...columns.slice(0, columnIndex),
                updatedColumn,
                ...columns.slice(columnIndex + 1)
            ];

            console.log(updatedColumns);
            spaces(set(spaces(), "space.board.columns", updatedColumns));
        }
    },

    handleAddSection: (columnId, position) => {
        console.log("handleAddSection");
        console.log(columnId);
        console.log(position);

        const columns = spaces().space.board.columns;
        const columnIndex = columns.findIndex(column => column.id === columnId);

        console.log(columnIndex);

        if (columnIndex > -1) {
            const newColumn = {
                id: 'status-' + (columns.length + 1),
                title: "New Section",
                tasks: []
            };

            let updatedColumns;
            if (position === "left") {
                updatedColumns = [
                    ...columns.slice(0, columnIndex),
                    newColumn,
                    ...columns.slice(columnIndex)
                ];
            } else if (position === "right") {
                updatedColumns = [
                    ...columns.slice(0, columnIndex + 1),
                    newColumn,
                    ...columns.slice(columnIndex + 1)
                ];
            } else {
                // Invalid position value
                console.log("Invalid position value");
                return;
            }

            console.log(updatedColumns);
            spaces(set(spaces(), "space.board.columns", updatedColumns));
        }
    },

    handleDeleteSection: (columnId) => {
        console.log("handleDeleteSection");
        console.log(columnId);

        const columns = spaces().space.board.columns;
        const columnIndex = columns.findIndex(column => column.id === columnId);

        console.log(columnIndex);

        if (columnIndex > -1) {
            const updatedColumns = [
                ...columns.slice(0, columnIndex),
                ...columns.slice(columnIndex + 1)
            ];

            console.log(updatedColumns);
            spaces(set(spaces(), "space.board.columns", updatedColumns));
        }
    },


    handleDragOver: (event) => {
        const { active, over, draggingRect } = event;


        console.log("handleDragOver");
        console.log(event);

        // Check if it's over a container
        if (over === null || over.data.current === undefined) {
            return;
        }

        console.log(111111);

        //if type is a column
        if (over.data.current.type === "column" && active.data.current.type === "column") {
            return;
        }


        console.log(222222);

        const { id } = active;
        const { id: overId } = over;

        // Get columns from state
        const prev = spaces().space.board.columns;

        // Find the containers
        // Get columns from state where id is active.containerId
        const activeContainer = active.data.current.sortable.containerId.id;
        const overContainer = over.data.current.sortable.containerId.id ? over.data.current.sortable.containerId.id : over.data.current.item.id;

        console.log("activeContainer, overContainer");
        console.log(activeContainer, overContainer);

        if (!activeContainer || !overContainer || activeContainer === overContainer) {
            return;
        }

        console.log(activeContainer, overContainer);
        if (activeContainer === overContainer) {
            return;
        }

        const activeColumn = prev.find((column) => column.id === activeContainer);
        const overColumn = prev.find((column) => column.id === overContainer);

        console.log("activeColumn, overColumn");
        console.log(activeColumn, overColumn);

        if (!activeColumn || !overColumn) {
            return;
        }

        const activeItems = activeColumn.tasks;
        const overItems = overColumn.tasks;

        console.log("activeItems, overItems");
        console.log(activeItems, overItems);

        // Find the indexes for the items
        const activeIndex = activeItems.findIndex((item) => item.id === id);
        const overIndex = overItems.findIndex((item) => item.id === overId);

        // Calculate the new index
        let newIndex;
        const isBelowLastItem =
            overIndex === overItems.length - 1 &&
            over.rect && over.rect.offsetTop &&
            draggingRect.offsetTop > over.rect.offsetTop + (over.rect.height || 0);

        if (isBelowLastItem) {
            newIndex = overIndex + 1;
        } else {
            newIndex = overIndex >= 0 ? overIndex : overItems.length;
        }

        const updatedActiveItems = activeItems.filter((item) => item.id !== id);
        const updatedOverItems = overItems.length > 0
            ? [
                ...overItems.slice(0, newIndex),
                activeItems[activeIndex],
                ...overItems.slice(newIndex, overItems.length)
            ]
            : [activeItems[activeIndex]];

        const updatedActiveColumn = {
            ...activeColumn,
            tasks: updatedActiveItems
        };

        const updatedOverColumn = {
            ...overColumn,
            tasks: updatedOverItems
        };

        const updatedColumns = prev.map((column) => {
            if (column.id === activeContainer) {
                return updatedActiveColumn;
            }
            if (column.id === overContainer) {
                return updatedOverColumn;
            }
            return column;
        });

        console.log("updatedColumns");
        console.log(updatedColumns);

        spaces(set(spaces(), "space.board.columns", updatedColumns));
    },

    handleDragEnd: ({ active, over }) => {
        console.log("handleDragEnd");

        let updatedSpaces = spaces();

        // Check if it's over a container
        if (over === null || over.data.current === undefined) {
            return;
        }

        const activeItem = updatedSpaces.space.board.activeItem;
        let activeContainer = active.data.current.sortable.containerId.id;
        let overContainer = over.data.current.sortable.containerId.id;


        console.log("activeItem");
        console.log(activeItem);

        console.log("activeContainer");
        console.log(activeContainer);

        console.log("overContainer");
        console.log(overContainer);


        if (activeItem) {
            const { item, type, containerId } = activeItem;
            const activeIndex = active.data.current.sortable.index;
            const overIndex = over.data.current.sortable.index;

            if (activeIndex !== overIndex) {

                console.log(111);

                if (type === 'column') {
                    // Move column within board
                    console.log("move column within board");
                    const columns = [...updatedSpaces.space.board.columns];
                    const column = columns.splice(activeIndex, 1)[0];
                    columns.splice(overIndex, 0, column);
                    updatedSpaces = set(updatedSpaces, 'space.board.columns', columns);
                } else if (type === 'task') {
                    // Move task within column
                    console.log("move task within column");
                    const columns = [...updatedSpaces.space.board.columns];
                    const activeColumnIndex = columns.findIndex(column => column.id === activeContainer);

                    if (activeColumnIndex > -1) {
                        const activeColumn = { ...columns[activeColumnIndex] };
                        const tasks = [...activeColumn.tasks];

                        console.log("tasks");
                        console.log(tasks);

                        const task = tasks.splice(activeIndex, 1)[0];
                        tasks.splice(overIndex, 0, task);
                        activeColumn.tasks = tasks;
                        columns[activeColumnIndex] = activeColumn;
                        updatedSpaces = set(updatedSpaces, `space.board.columns.${activeColumnIndex}.tasks`, tasks);
                        updatedSpaces = set(updatedSpaces, 'space.board.columns', columns);
                    }
                }
            }
        }

        updatedSpaces = set(updatedSpaces, 'space.board.activeItem', null);
        spaces(updatedSpaces);
    }


}


