import { useEffect, useState } from 'react'

const useIcon = (fileName) => {
    const [iconLoading, setLoading] = useState(true)
    const [iconError, setError] = useState(null)
    const [icon, setIcon] = useState(null)

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await import(`assets/icons/file_extensions/high-contrast/${fileName}.svg`)
                setIcon(response.default)
            } catch (err) {
                setError(err)
            } finally {
                setLoading(false)
            }
        }

        fetchImage()
    }, [fileName])

    return {
        iconLoading,
        iconError,
        icon,
    }
}

export default useIcon