import { useTranslation } from "react-i18next";
import { Grid, Row, Col } from 'rsuite';
import General from "./General";

//Components
import BrowserSeo from "@components/Layout/BrowserSeo/BrowserSeo";


const Settings = () => {
    const { t } = useTranslation();
    <BrowserSeo title={t("global.settings")} />

    return <Grid fluid className="content-view__organization__content__settings">
        <Row>
            <Col md={24}>
                <General />
            </Col>
        </Row>
    </Grid>;
};

export default Settings;
