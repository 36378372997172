import { useState, useRef } from "react";
import { Grid, Row, Col, Form, Input } from "rsuite";
import { useTranslation } from "react-i18next";
import useModalCallback from "@hooks/useModalCallback";
import { modal, schemas } from 'utils';

import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';



const ModalPaymentInfo = ({ modalAction }) => {

    const { t } = useTranslation();
    const stripePromise = loadStripe('pk_test_51IdZOVJbVjI3H7wYIeaTJ6AfkPKRSHai5vcMEkzjqJK79fO0f67xuOy4fnVTGInqPZaIcZoVaLES5ngZrfnMmw7l00SceQPP4p');
    const formRef = useRef();

    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState();

    //ON SUBMIT
    useModalCallback("paymentInfo", () => {

        //Check for errors
        if (!formRef.current.check()) {
            console.error('Form Error');
            return;
        }

        console.log("SUBMITING FORM");
        console.log(formValue);

        modal.loading(true);
    });



    return (
        <Elements stripe={stripePromise}>
            <Form
                ref={formRef}
                model={schemas.organization.paymentInfo}
                formValue={formValue}
                onCheck={setFormError}
                onChange={setFormValue}
            >
                <Grid fluid>
                    <Row className="mb-4">
                        <Col xs={24}>
                            <p>We accept Visa, Mastercard, American Express, UnionPay, and Discover credit cards.</p>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={24} >
                            <Form.Group controlId="cardholder">
                                <Form.ControlLabel>
                                    {t("organization.cardholder")}
                                </Form.ControlLabel>
                                <Form.Control name="cardholder" />
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row className="mb-3">
                        <Col xs={24} >
                            <Form.Group controlId="card_number">
                                <Form.ControlLabel>
                                    {t("organization.card_number")}
                                </Form.ControlLabel>
                                <CardElement />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </Form>
        </Elements>
    );
}

export default ModalPaymentInfo;