import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { spaces } from "stores";
import { useReactiveVar } from "@apollo/client";

//Components 
import AssignUserInput from "../AssignUserInput/AssignUserInput";
import UserInfoBox from "../UserInfoBox/UserInfoBox";
import Icon from "../../Icon/Icon";
import { Whisper, Tooltip, Avatar, Popover } from "rsuite";

let classNames = require("classnames");


const AssignUser = ({ isTask, assigned, callback, withName, label }) => {

    const { t } = useTranslation();

    const whisperRef = useRef();
    const [hideTooltip, setHideTooltip] = useState(false);
    const [hideInfoBox, setHideInfoBox] = useState(false);
    const { space } = useReactiveVar(spaces);

    //handle whisper open action
    const handleWhisperOpen = (userAssigned) => {
        setHideTooltip(true);
        setHideInfoBox(true);
        isTask && isTask.setAssignUser(true);
    };

    //handle whisper close action
    const handleWhisperClose = (userAssigned) => {
        setHideTooltip(false);
        setHideInfoBox(false);
        isTask && isTask.setAssignUser(false);
        isTask && isTask.setDisableDrag && isTask.setDisableDrag(false);
    };

    //we need to create a function to load the whisper or just remove it when scrolling the board
    const assigUserWhisper = (children) => {
        const whisperAssignUserProps = {
            ref: whisperRef,
            enterable: false,
            full: true,
            onOpen: handleWhisperOpen,
            onClose: handleWhisperClose,
            placement: "autoVerticalStart",
            trigger: "click",
            speaker: <Popover arrow={false} className="assign-task" > <AssignUserInput assigned={assigned} assignTome isTask={isTask} callback={(e) => callback(e)} /></Popover >
        };

        if (!space.board.task.showAssignBox) {
            return <div>{children}</div>
        }

        return (<Whisper {...whisperAssignUserProps}>
            <div>{children}</div>
        </Whisper>)

    }

    //if is not scrolling, reset everything
    useEffect(() => {
        if (!space.board.task.showAssignBox) {
            handleWhisperClose();
            isTask.setDisableDrag && isTask.setDisableDrag(false);
        }
    }, [space.board.task.showAssignBox]);



    let avatarClass = classNames({
        'with-name': withName,
    });

    let wrapperAssignClass = classNames({
        'assignUser-wrapper': true,
        'btn-style': label
    });


    return <div
        className={wrapperAssignClass}
        data-no-dnd="true"

        //we need to disable drag when mouse is over the assign user icon
        onMouseEnter={() => isTask ? isTask.setDisableDrag(true) : false}
        onMouseLeave={() => isTask ? isTask.setDisableDrag(false) : false}
    >

        {
            assigned &&
            //if there is an assigned user, we need to show the user info box
            assigUserWhisper(
                <UserInfoBox user={assigned} hide={hideInfoBox}>
                    <div className={avatarClass}>
                        <Avatar className="assignUser-wrapper__user" circle src={assigned.avatar} size="sm" />
                        {withName && <div className="with-name__label">{assigned.name}</div>}
                    </div>
                </UserInfoBox>
            )
        }

        {
            !assigned &&
            //if there is no assigned user, we need to show the assign user icon
            <Whisper
                ref={whisperRef}
                enterable
                placement="autoVerticalStart"
                trigger="hover"
                speaker={(!hideTooltip && !label) ? <Tooltip>{t("space.tasks.task.assign_task")}</Tooltip > : <span></span>}
            >
                <div>
                    {assigUserWhisper(
                        <div className={'assignUser-wrapper__assign'} >
                            <Icon icon="user" />
                            {label && <div className="assignUser-wrapper__assign__label">
                                {label}
                            </div>}
                        </div>
                    )}
                </div>
            </Whisper>
        }

    </div >
};

export default AssignUser;
