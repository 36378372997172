import React from "react";
import Icon from "../../../Icon/Icon";
let classNames = require("classnames");

const WithIcon = ({ content, align, icon }) => {

  let contentClass = classNames({
    "icon-model-body__content": true,
    "align-left": (align === "left"),
    "align-right": (align === "right"),
    "align-center": (align === "center"),
  });

  return (
    <div className="icon-model-body">
      <Icon icon={icon} />
      <div className={contentClass}>{content}</div>
    </div>
  );
};

export default WithIcon;
