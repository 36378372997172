import React, { useRef } from "react";

import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

//Components
import Content from "@components/Layout/Content/Content";
import Sidebar from "./Sidebar/Sidebar";

const Organization = () => {
  const { t } = useTranslation();

  return (
    <Content seo={{ title: t("organization.title"), }} sidebar={<Sidebar />} className="content-view__organization" ><Outlet /></Content>
  );
};
export default Organization;
