import { useState, useEffect, useRef } from "react";
import { Grid, Row, Col, Form, SelectPicker } from "rsuite";
import { useTranslation } from "react-i18next";
import countries from "utils/countries"
import useModalCallback from "@hooks/useModalCallback";
import { modal, schemas } from 'utils'

const ModalBillingInfo = ({ modalAction }) => {

    const { t } = useTranslation();
    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState();
    const [countryList, setCountryList] = useState([]);


    //ON SUBMIT
    useModalCallback("billingInfo", () => {

        //Check for errors
        if (!formRef.current.check()) {
            console.error('Form Error');
            return;
        }

        console.log("SUBMITING FORM");
        console.log(formValue);

        modal.loading(true);
    });

    //SET COUNTRIES
    useEffect(() => {
        let countriesArr = countries.map(country => ({ value: country.code, label: country.name }));
        setCountryList(countriesArr);
    }, []);


    return (
        <>

            <Form
                ref={formRef}
                model={schemas.organization.billingInfo}
                formValue={formValue}
                onCheck={setFormError}
                onChange={setFormValue}
            >
                <Grid fluid>
                    <Row className="mb-4">
                        <Col xs={24}>
                            <p>This address appears on your monthly invoice and should be the legal address of your home or business.</p>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={24} sm={12}>
                            <Form.Group controlId="company">
                                <Form.ControlLabel>
                                    {t("organization.company")}
                                </Form.ControlLabel>
                                <Form.Control name="company" />
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Group controlId="country">
                                <Form.ControlLabel>
                                    {t("organization.country")}
                                </Form.ControlLabel>
                                <SelectPicker
                                    data={countryList}
                                    searchable={true}
                                    cleanable={true}
                                    block
                                    name="country"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={24} sm={12}>
                            <Form.Group controlId="address">
                                <Form.ControlLabel>
                                    {t("organization.address")}
                                </Form.ControlLabel>
                                <Form.Control name="address" />
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Group controlId="state">
                                <Form.ControlLabel>
                                    {t("organization.state")}
                                </Form.ControlLabel>
                                <Form.Control name="state" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={12}>
                            <Form.Group controlId="zip_code">
                                <Form.ControlLabel>
                                    {t("organization.zip_code")}
                                </Form.ControlLabel>
                                <Form.Control name="zip_code" />
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Group controlId="billing_tax_id">
                                <Form.ControlLabel>
                                    {t("organization.billing_tax_id")}
                                </Form.ControlLabel>
                                <Form.Control name="billing_tax_id" />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </Form>
        </>
    );
}

export default ModalBillingInfo;