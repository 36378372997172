import { useState, useRef, useEffect } from "react";
import { Grid, Row, Col, Form } from "rsuite";
import { useTranslation } from "react-i18next";
import useModalCallback from "@hooks/useModalCallback";
import { modal, schemas } from "utils";


const ModalAddFolder = ({ edit }) => {

    const { t } = useTranslation();
    const formRef = useRef();
    const [name, setName] = useState();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState();

    //ON SUBMIT
    useModalCallback("addFolder", () => {

        //Check for errors
        if (!formRef.current.check()) {
            console.error('Form Error');
            return;
        }

        console.log("SUBMITING FORM");
        console.log(formValue);

        modal.loading(true);
    });


    return (
        <>
            <Form
                ref={formRef}
                model={schemas.space.filesAddFolder}
                formValue={formValue}
                onCheck={setFormError}
                onChange={setFormValue}
                className="mb-3"
            >
                <Grid fluid>
                    <Row className="">
                        <Col xs={24} >
                            <Form.ControlLabel>
                                {t("space.files.modal.folder_name")}
                            </Form.ControlLabel>
                            <Form.Group controlId="folder_name">
                                <Form.Control block type="text" name="folder_name" value={name} onChange={value => setName(value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </Form>
        </>
    );
}

export default ModalAddFolder;