import React from "react";
import { Badge } from "rsuite";
import { IconContext } from "react-icons";
import {
  FaTasks,
  FaHome,
  FaChartBar,
  FaCircle,
  FaThLarge,
  FaPlus,
  FaAngleUp,
  FaAngleDown,
  FaEllipsisH,
  FaSquare,
  FaBell,
  FaRegUser,
  FaRegBuilding,
  FaLayerGroup,
  FaCcVisa,
  FaFileInvoice,
  FaCcAmex,
  FaCcMastercard,
  FaCcDiscover,
  FaCcDinersClub,
  FaUserAlt,
  FaUsers,
  FaEllipsisV,
  FaSort,
  FaAngleLeft,
  FaAngleRight,
  FaComment
} from "react-icons/fa";
import { GoGear, GoMention } from "react-icons/go";
import { HiPlay, HiStop, HiSwitchHorizontal, HiTrash, HiDotsVertical, HiOutlineDatabase, HiOutlineKey } from "react-icons/hi";
import { GrClose, GrCheckmark, GrSearch } from "react-icons/gr";
import { FcGoogle } from "react-icons/fc";
import { AiOutlineTeam, AiOutlineQuestionCircle, AiOutlineCreditCard, AiOutlineLock, AiOutlineProject, AiOutlineMail, AiOutlineMenu, AiOutlineCalendar, AiOutlineDownload } from "react-icons/ai";
import { MdWorkspacesFilled, MdEdit, MdOutlineLogout, MdOutlineDragIndicator, MdPassword, MdOutlineDriveFileRenameOutline, MdOutlineViewColumn } from "react-icons/md";
import { BiImageAdd, BiServer, BiCodeAlt, BiCopy, BiHide, BiShowAlt, BiFolder, BiFolderPlus, BiFolderMinus } from "react-icons/bi";
import { ImRadioUnchecked, ImRadioChecked } from "react-icons/im";
import { BsInputCursorText, BsUpload, BsLink45Deg, BsLayersFill, BsFillPlayFill } from "react-icons/bs";
import { FiFile, FiFileMinus, FiFilePlus } from "react-icons/fi";
import { TbCalendarEvent, TbCalendarTime, TbClockHour3 } from "react-icons/tb";


var classNames = require("classnames");

const Icon = ({ icon, size, badge, color, className }) => {

  //Get Icon from name
  const getIconForName = (name) =>
  ({
    "radio-unchecked": <ImRadioUnchecked />,
    "radio-checked": <ImRadioChecked />,
    columns: <MdOutlineViewColumn />,
    drag: <MdOutlineDragIndicator />,
    reorder: <AiOutlineMenu />,
    bell: <FaBell />,
    gear: <GoGear />,
    tasks: <FaTasks />,
    projects: <AiOutlineProject />,
    home: <FaHome />,
    logout: <MdOutlineLogout />,
    chart: <FaChartBar />,
    circle: <FaCircle />,
    dashboard: <FaThLarge />,
    plus: <FaPlus />,
    "arrow-down": <FaAngleDown />,
    "arrow-up": <FaAngleUp />,
    "arrow-left": <FaAngleLeft />,
    "arrow-right": <FaAngleRight />,
    "ellipsis-h": <FaEllipsisH />,
    "ellipsis-v": <FaEllipsisV />,
    square: <FaSquare />,
    play: <BsFillPlayFill />,
    stop: <HiStop />,
    user: <FaRegUser />,
    userAlt: <FaUserAlt />,
    users: <FaUsers />,
    edit: <MdEdit />,
    close: <GrClose />,
    check: <GrCheckmark />,
    building: <FaRegBuilding />,
    switch: <HiSwitchHorizontal />,
    google: <FcGoogle />,
    search: <GrSearch />,
    team: <AiOutlineTeam />,
    group: <FaLayerGroup />,
    workspace: <MdWorkspacesFilled />,
    trash: <HiTrash />,
    verticalDots: <HiDotsVertical />,
    visa: <FaCcVisa />,
    amex: <FaCcAmex />,
    mastercard: <FaCcMastercard />,
    discover: <FaCcDiscover />,
    diners: <FaCcDinersClub />,
    jcb: <FaCcVisa />,
    invoice: <FaFileInvoice />,
    "question-mark": <AiOutlineQuestionCircle />,
    "payment-card": <AiOutlineCreditCard />,
    "add-image": <BiImageAdd />,
    lock: <AiOutlineLock />,
    file: <FiFile />,
    "file-minus": <FiFileMinus />,
    "file-plus": <FiFilePlus />,
    envelope: <AiOutlineMail />,
    server: <BiServer />,
    password: <MdPassword />,
    "input-cursor-text": <BsInputCursorText />,
    code: <BiCodeAlt />,
    database: <HiOutlineDatabase />,
    key: <HiOutlineKey />,
    copy: <BiCopy />,
    show: <BiShowAlt />,
    hide: <BiHide />,
    folder: <BiFolder />,
    "folder-plus": <BiFolderPlus />,
    "folder-minus": <BiFolderMinus />,
    calendar: <TbCalendarEvent />,
    "calendar-time": <TbCalendarTime />,
    download: <AiOutlineDownload />,
    rename: <MdOutlineDriveFileRenameOutline />,
    upload: <BsUpload />,
    link: <BsLink45Deg />,
    layers: <BsLayersFill />,
    sort: <FaSort />,
    clock: <TbClockHour3 />,
    comment: <FaComment />,
    mention: <GoMention />,
  }[name]);

  let iconClasses = classNames(className, "icon-wrap");

  if (badge) {
    return (
      <Badge content={badge}>
        <IconContext.Provider value={{ color: color, size: size }}>
          <span className={iconClasses}>{getIconForName(icon)}</span>
        </IconContext.Provider>
      </Badge>
    );
  } else {
    return (
      <IconContext.Provider value={{ color: color, size: size }}>
        <span className={iconClasses}>{getIconForName(icon)}</span>
      </IconContext.Provider>
    );
  }
};

export default Icon;
