import React from "react";
import { Loader } from "rsuite";

const Loading = ({ label, className, size, backdrop }) => {
  return (
    <div className={`loading-wrapper ${className}`}>
      <Loader
        content={label}
        size={size ? size : "sm"}
        backdrop={backdrop ? backdrop : true}
        vertical
        center
      />
    </div>
  );
};

export default Loading;
