import { Workspaces, Groups, Settings, Team, Billing, Plans, Dashboard } from "../../views/Organization";
import { Navigate } from "react-router-dom";

import i18n from "../../locale/i18n";

export default [
  {
    path: "/organization",
    children: [
      { path: "workspaces", element: <Workspaces />, breadcrumb: i18n.t("organization.workspaces") },
      { path: "groups", element: <Groups />, breadcrumb: i18n.t("organization.groups"), exact: true },
      { path: "settings", element: <Settings />, breadcrumb: i18n.t("organization.organization_settings"), },
      { path: "billing", element: <Billing />, breadcrumb: i18n.t("organization.billing"), },
      { path: "team", element: <Team />, breadcrumb: i18n.t("organization.team"), },
      { path: "plans", element: <Plans />, breadcrumb: i18n.t("organization.plans"), },
      { path: "*", element: <Navigate to="/not-found/" /> },
    ]

  },

];