import React from "react";
import { useTranslation } from "react-i18next";
import Name from './Name/Name';
import Navigation from './Navigation/Navigation';
import Members from './Members/Members';
import { SubHeader } from "components";

const SpaceHeader = () => {
    const { t } = useTranslation();

    return (
        <SubHeader className="space-header">
            <div className="space-header__left">
                <Name />
            </div>
            <div className="space-header__right">
                <Members />
                <Navigation />
            </div>
        </SubHeader>
    );
};

export default SpaceHeader;
