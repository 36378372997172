import React, { useState, useEffect } from "react";

//Components
import Content from "@components/Layout/Content/Content";
import { Pagination } from "rsuite";
import NotificationsList from "@components/Layout/Header/Notifications/NotificationsList/NotificationsList";
import Sidebar from "./Sidebar/Sidebar";
import ScrollLoadMore from "@components/ScrollLoadMore/ScrollLoadMore";

const Notifications = () => {
    const [hasMore, setHasMore] = useState(true);
    const [notifications, setNotifications] = useState("");

    useEffect(() => {
        const notifications = [
            {
                new: true,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "comment",
                object: {
                    id: 123,
                    type: "comment",
                    details: {
                        excerpt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget aliquam ultricies, nunc nisl aliquet nunc, eget aliquam nisl nisl eget.",
                    }
                }
            },
            {
                new: false,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "assigned_task",
                object: {
                    id: 123,
                    type: "task",
                    details: {
                        name: "Task #1",
                    }
                }
            },
            {
                new: false,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "assigned_group",
                object: {
                    id: 123,
                    type: "group",
                    details: {
                        name: "Group #1",
                    }
                }
            },
            {
                new: true,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "comment",
                object: {
                    id: 123,
                    type: "comment",
                    details: {
                        excerpt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget aliquam ultricies, nunc nisl aliquet nunc, eget aliquam nisl nisl eget.",
                    }
                }
            },
            {
                new: false,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "assigned_task",
                object: {
                    id: 123,
                    type: "task",
                    details: {
                        name: "Task #1",
                    }
                }
            },
            {
                new: false,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "assigned_group",
                object: {
                    id: 123,
                    type: "group",
                    details: {
                        name: "Group #1",
                    }
                }
            },
            {
                new: true,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "comment",
                object: {
                    id: 123,
                    type: "comment",
                    details: {
                        excerpt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget aliquam ultricies, nunc nisl aliquet nunc, eget aliquam nisl nisl eget.",
                    }
                }
            },
            {
                new: false,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "assigned_task",
                object: {
                    id: 123,
                    type: "task",
                    details: {
                        name: "Task #1",
                    }
                }
            },
            {
                new: false,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "assigned_group",
                object: {
                    id: 123,
                    type: "group",
                    details: {
                        name: "Group #1",
                    }
                }
            },
            {
                new: true,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "comment",
                object: {
                    id: 123,
                    type: "comment",
                    details: {
                        excerpt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget aliquam ultricies, nunc nisl aliquet nunc, eget aliquam nisl nisl eget.",
                    }
                }
            },
            {
                new: false,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "assigned_task",
                object: {
                    id: 123,
                    type: "task",
                    details: {
                        name: "Task #1",
                    }
                }
            },
            {
                new: false,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "assigned_group",
                object: {
                    id: 123,
                    type: "group",
                    details: {
                        name: "Group #1",
                    }
                }
            },
            {
                new: true,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "comment",
                object: {
                    id: 123,
                    type: "comment",
                    details: {
                        excerpt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget aliquam ultricies, nunc nisl aliquet nunc, eget aliquam nisl nisl eget.",
                    }
                }
            },
            {
                new: false,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "assigned_task",
                object: {
                    id: 123,
                    type: "task",
                    details: {
                        name: "Task #1",
                    }
                }
            },
            {
                new: false,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "assigned_group",
                object: {
                    id: 123,
                    type: "group",
                    details: {
                        name: "Group #1",
                    }
                }
            },
            {
                new: false,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "mentioned",
                object: {
                    id: 123,
                    type: "comment",
                    details: {
                        excerpt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget aliquam ultricies, nunc nisl aliquet nunc, eget aliquam nisl nisl eget.",
                    }
                }
            },
        ];
        setNotifications(notifications);
    }, []);

    return (
        <Content className="notifications__view" sidebar={<Sidebar />} >
            <ScrollLoadMore callback={() => alert("Reached bottom!")} hasMore={hasMore}>
                <NotificationsList items={notifications} />
            </ScrollLoadMore>
        </Content>
    );
};
export default Notifications;
