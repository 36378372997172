import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";

const Dropdown = ({ onChange, isSearchable, options, OptionsWidth, ControlWidth }) => {

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedTitle, setSelectedTitle] = useState();

    useEffect(() => {
        let activeOption = options.find(o => o.active === true);
        if (activeOption) {
            setSelectedTitle(activeOption.label);
            setSelectedOption(activeOption);
        }
    }, [])

    const formatOptionLabel = ({ value, label, desc }) => (
        <div className="option-wrapper">
            <div className="option-wrapper__label">{label}</div>
            <div className="option-wrapper__description">{desc}</div>
        </div>
    );

    const onSelect = (val) => {
        setSelectedTitle(val.label);
        setSelectedOption(val);
        if (onChange) {
            onChange(val.value);
        }
    }


    return (
        <div className="simple-dropdown-wrapper" style={{ width: ControlWidth }}>
            <Select
                className="simple-dropdown"
                classNamePrefix="simple-dropdown"
                options={options}
                menuPortalTarget={document.getElementById('dialog-32')}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999, width: OptionsWidth ? OptionsWidth : "auto", }) }}
                menuShouldBlockScroll={true}
                menuPlacement="auto"
                menuPosition="fixed"
                placeholder={selectedOption ? selectedOption.label : t("global.please_select")}
                formatOptionLabel={formatOptionLabel}
                closeMenuOnScroll={true}
                isSearchable={isSearchable ? isSearchable : false}
                onChange={onSelect}
                value={selectedOption && selectedOption}

            />
        </div>)

}

export default Dropdown;