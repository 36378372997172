import { useState, useRef, useEffect } from "react";
import { modal, settings } from "utils";
import { useTranslation } from "react-i18next";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable, arrayMove } from '@dnd-kit/sortable';


//Components
import { Grid, Row, Col, Form, Button, IconButton, SelectPicker, ButtonToolbar } from "rsuite";
import Icon from "@components/Icon/Icon";
import IconText from "@components/IconText/IconText";
import useModalCallback from "@hooks/useModalCallback";
import Field from "./Field/Field";
import { json } from "react-router-dom";




const ModalAddPassword = ({ edit }) => {

    const { t } = useTranslation();
    const formRef = useRef();
    const [fields, setFields] = useState([]);
    const [title, setTitle] = useState("");
    const [type, setType] = useState("");

    const accessTypes = settings.spaces.accesses.types.map(
        item => ({ label: item.label, value: item.value })
    );

    const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));



    //ON SUBMIT
    useModalCallback("addPassword", () => {

        alert("FORM 123");
        console.log("SUBMITING FORM");

        const data = {
            title: title,
            type: type,
            fields: fields
        }

        console.log(data);

        modal.loading(true);
    });


    const addField = () => {

        const dummyField = {
            type: "text",
            placeholder: "Label",
            label: "",
            value: "",
            id: "field-" + (fields.length + 1)
        }

        const newFields = [...fields, dummyField]
        setFields(newFields);

        //scroll modal content to bottom
        let modal = document.getElementById("modal-addPassword") ? document.getElementById("modal-addPassword") : document.getElementById("modal-editAccess");
        modal.scrollTo(0, modal.scrollHeight + 100);
    }

    const chooseFieldtype = (index, type) => {
        //change input type
        const newFields = [...fields];
        newFields[index].type = type;
        setFields(newFields);
    }


    const updateField = (value, id, key) => {
        const newFields = [...fields];
        const index = newFields.findIndex(field => field.id === id);
        newFields[index][key] = value;
        setFields(newFields);
    }


    const deleteField = (id) => {
        const newFields = [...fields];
        const index = newFields.findIndex(field => field.id === id);
        console.log(index);
        newFields.splice(index, 1);
        setFields(newFields);
    }

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {

            const oldIndex = fields.findIndex(field => field.id === active.id);
            const newIndex = fields.findIndex(field => field.id === over.id);

            const newFields = arrayMove(fields, oldIndex, newIndex);
            setFields(newFields);

        }
    };



    return (
        <>
            <Form ref={formRef}>
                <Grid fluid>
                    <Row className="mb-3 access-type sub-header">
                        <Col xs={24}>
                            <Form.Group controlId="access_title">
                                <Form.ControlLabel>
                                    {t("space.passwords.modal.access_title")}
                                </Form.ControlLabel>
                                <Form.Control block type="text" name="access_title" onChange={value => setTitle(value)} />
                            </Form.Group>
                            <Form.Group controlId="access_type">
                                <Form.ControlLabel>
                                    {t("space.passwords.modal.access_type_title")}
                                </Form.ControlLabel>
                                <SelectPicker data={accessTypes} searchable={false} cleanable={false} onChange={value => setType(value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={24} >

                            {fields.length > 0 &&
                                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                                    <SortableContext items={fields} strategy={verticalListSortingStrategy}>

                                        {fields.map((field, index) => <Field
                                            key={field.id}
                                            field={field}
                                            index={index}
                                            updateField={updateField}
                                            deleteField={deleteField}
                                            chooseFieldtype={chooseFieldtype}
                                        />)}

                                    </SortableContext>
                                </DndContext>
                            }
                            {fields.length === 0 &&
                                <IconText icon="input-cursor-text" text={t("space.passwords.modal.no_fields")} className="md" />
                            }

                        </Col>
                        <Col xs={24} className="modal-addPassword__add-field">
                            <IconButton appearance="default" icon={<Icon icon="plus" />} onClick={() => addField()}>
                                {t("space.passwords.modal.add_field")}
                            </IconButton>
                        </Col>
                    </Row>
                </Grid>
            </Form>
        </>
    );
}

export default ModalAddPassword;