import { Form, Button } from "rsuite";
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from "react-i18next";
import { settings } from "utils";

//Components
import DropdownIcon from "@components/DropdownIcon/DropdownIcon";
import Icon from "@components/Icon/Icon";


const Field = ({ field, updateField, deleteField, chooseFieldtype, index }) => {
    const { t } = useTranslation();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: field.id,
        index,
        data: {
            item: field,
            type: 'field'
        }
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };
    return <div key={field}
        ref={setNodeRef}
        style={style}
    ><Form.Group

    >

            <div className={`input-wrapper`} id={field.id}>
                <div className="input-wrapper__label">
                    <Form.Control name={"field-placeholder-" + index} block placeholder={field.placeholder} onChange={(value) => updateField(value, field.id, "label")} value={field.label} />
                </div>
                <div className="input-wrapper__field">
                    <Form.Control name={"field-input-" + index} block type={field.type} onChange={(value) => updateField(value, field.id, "value")} />
                </div>
                <div className="input-wrapper__actions others">
                    <Button appearance="subtle" onClick={() => deleteField(field.id)}><Icon icon="trash" /></Button>
                    <DropdownIcon icon="ellipsis-v" placement="bottomEnd" appearance="subtle" title={t("space.passwords.modal.field_type_title")} options={
                        settings.spaces.accesses.field_types.map((fieldType) => {
                            return {
                                label: fieldType.label,
                                icon: (field.type === fieldType.type) ? "radio-checked" : "radio-unchecked",
                                onClick: () => chooseFieldtype(index, fieldType.type)
                            }
                        })}
                    />

                    <Button appearance="subtle" {...attributes} {...listeners}><Icon icon="reorder" /></Button>
                </div>
            </div>

        </Form.Group >
    </div>


}

export default Field;