import React, { useState, useEffect, useRef } from "react";
import InfiniteScroll from 'react-infinite-scroller';
import Loading from "@components/Loading/Loading";

/* This is a wrapping component because at some point we may need to change this to a custom made solution */

const ScrollLoadMore = ({ children, callback, hasMore }) => {

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);


    return loaded && <InfiniteScroll
        pageStart={0}
        loadMore={callback}
        hasMore={hasMore}
        loader={<Loading size="md" />}
        useWindow={false}
    >
        {children}
    </InfiniteScroll>;
};

export default ScrollLoadMore;