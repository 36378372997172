import { Schema } from "rsuite";

export const organization = {

    billingInfo: Schema.Model({
        company: Schema.Types.StringType().isRequired('This field is required.'),
    }),

    paymentInfo: Schema.Model({
        card_number: Schema.Types.StringType().isRequired('This field is required.'),
    }),

    generalSettings: Schema.Model({
        name: Schema.Types.StringType().isRequired('This field is required.'),
    })

}