import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { api } from 'utils';

//Components
import Icon from "@components/Icon/Icon";
import NotificationsList from './NotificationsList/NotificationsList';
import { Whisper, Popover } from 'rsuite';

const Notifications = () => {

    const { t } = useTranslation();

    const [notifications, setNotifications] = useState([]);
    const triggerRef = useRef();

    useEffect(() => {

        const notifications = [
            {
                new: true,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "comment",
                object: {
                    id: 123,
                    type: "comment",
                    details: {
                        excerpt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget aliquam ultricies, nunc nisl aliquet nunc, eget aliquam nisl nisl eget.",
                    }
                }
            },
            {
                new: false,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "assigned_task",
                object: {
                    id: 123,
                    type: "task",
                    details: {
                        name: "Task #1",
                    }
                }
            },
            {
                new: false,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "assigned_group",
                object: {
                    id: 123,
                    type: "group",
                    details: {
                        name: "Group #1",
                    }
                }
            },
            {
                new: true,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "comment",
                object: {
                    id: 123,
                    type: "comment",
                    details: {
                        excerpt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget aliquam ultricies, nunc nisl aliquet nunc, eget aliquam nisl nisl eget.",
                    }
                }
            },
            {
                new: false,
                user: {
                    id: 123,
                    name: "Bruno Santos",
                    avatar: "https://i.pravatar.cc/300",
                },
                action: "assigned_task",
                object: {
                    id: 123,
                    type: "task",
                    details: {
                        name: "Task #1",
                    }
                }
            }
        ];

        setNotifications(notifications);

    }, []);

    return <div className='notifications'>
        <Whisper
            placement="bottomEnd"
            trigger="click"
            ref={triggerRef}
            speaker={
                <Popover arrow={false} className="notifications__popover">
                    <NotificationsList items={notifications} viewAllCallback={() => triggerRef.current.close()} />
                </Popover>
            }
        >
            <a><Icon icon="bell" badge={notifications.length > 0 && notifications.length} /></a>
        </Whisper>
    </div>
}

export default Notifications;