

import { Grid, Row, Col } from "rsuite";

//Components
import BrowserSeo from "../BrowserSeo/BrowserSeo";
import ActionHeader from "../ActionHeader/ActionHeader";

const Content = ({ sidebar, actionHeader, seo, children, className }) => {

    const calculateContentWidth = () => {
        if (sidebar) {
            return 18;
        }
        return 24;
    }

    return (
        <Grid fluid className={`content-wrapper ${className}`} >
            <BrowserSeo {...seo} />

            {actionHeader &&
                <ActionHeader left={actionHeader.left} right={actionHeader.right} />
            }

            <Row gutter={0} className="content-wrapper__row__content">
                {sidebar &&
                    <>
                        <Col md={6} sm={6} className="content-wrapper__row__content__sidebar-wrapper">
                            {sidebar}
                        </Col>
                    </>
                }
                <Col md={calculateContentWidth()} sm={calculateContentWidth()} className="content-wrapper__content">
                    {children}
                </Col>
            </Row>

        </Grid>
    );
}

export default Content;