import Icon from "@components/Icon/Icon";
import { utils, settings } from 'utils';

export const notifications = {

    generateDetails: (notification) => {
        let actiontext = settings.notifications.types[notification.action].message;
        let objectName = notification.object.details.name || notification.object.details.excerpt;
        let objectIcon = settings.notifications.types[notification.action].icon;

        return <>
            <div className='action'><strong>{notification.user.name}</strong> {actiontext}</div>
            {objectName && <div className='object'><Icon icon={objectIcon} /> {utils.ellipsis(objectName, 50)}</div>}
        </>
    },

}