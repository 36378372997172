import { useState, useRef, useEffect } from "react";
import { Grid, Row, Col, Form } from "rsuite";
import { useTranslation } from "react-i18next";
import useModalCallback from "@hooks/useModalCallback";

import { modal } from "utils";


const ModalRenameFile = ({ edit }) => {

    const { t } = useTranslation();
    const formRef = useRef();
    const [name, setName] = useState("Testing....");


    //ON SUBMIT
    useModalCallback("renameFile", () => {

        alert("FORM 123");
        console.log("SUBMITING FORM");

        modal.loading(true);
    });


    return (
        <>
            <Form ref={formRef}>
                <Grid fluid>
                    <Row className="">
                        <Col xs={24}>
                            <Form.Group controlId="rename_file">
                                <Form.Control block type="text" name="rename_file" value={name} onChange={value => setName(value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </Form>
        </>
    );
}

export default ModalRenameFile;