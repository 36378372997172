import React, { useState, useEffect } from "react";
import { api } from "utils"
import useModalCallback from "@hooks/useModalCallback";

//Components
import SearchList from "../../../../../../Search/SearchList";
import SearchInput from "../../../../../../Search/SearchInput";
import AvatarLink from "../../../../../../AvatarLink/AvatarLink";


const WorkspaceSwitch = () => {
  const [searchText, setSearchText] = useState();
  const [workspaces, setWorkspaces] = useState();

  //ON SUBMIT
  useModalCallback("workspaceSwitch", () => {
    alert(1);
  });

  const fetchWorkspaces = async () => {
    console.log("fetching workspaces");
    console.log(api);
    const workspaces = await api.workspaces.getWorkspaces();
    console.log(workspaces);
    setWorkspaces(workspaces.results);
  }
  useEffect(() => {
    fetchWorkspaces();
  }, [])

  return (
    <div className="workspace-modal-body">
      <div className="search-header">
        <SearchInput callback={setSearchText} />
      </div>
      <SearchList search={searchText} data={workspaces} fields={["name", "id"]}>
        <AvatarLink light onClick={(id) => alert(id)} />
      </SearchList>
    </div>
  );
};

export default WorkspaceSwitch;