import React from "react";
import { useTranslation } from "react-i18next";
import { Avatar, AvatarGroup, Tooltip, Whisper } from 'rsuite';
import { Modals } from "components";

import { useReactiveVar } from "@apollo/client";
import { spaces } from "stores";
import ModalMembers from './Modals/ModalMembers'
import { modal } from 'utils';

const Members = () => {
    const { t } = useTranslation();
    const { space } = useReactiveVar(spaces);


    const users = [
        { avatar: 'https://avatars.githubusercontent.com/u/12592949', name: 'superman66' },
        { avatar: 'https://avatars.githubusercontent.com/u/8225666', name: 'SevenOutman' },
        { avatar: 'https://avatars.githubusercontent.com/u/15609339', name: 'hiyangguo' },
        { avatar: 'https://avatars.githubusercontent.com/u/14308293', name: 'MarvelSQ' },
        { avatar: 'https://avatars.githubusercontent.com/u/1203827', name: 'simonguo' },
        { avatar: 'https://avatars.githubusercontent.com/u/9625224', name: 'theJian' },
        { avatar: 'https://avatars.githubusercontent.com/u/15884443', name: 'LeightonYao' },
        { avatar: 'https://avatars.githubusercontent.com/u/10924138', name: 'zmhawk' },
        { avatar: 'https://avatars.githubusercontent.com/u/2797600', name: 'posebear1990' },
        { avatar: 'https://avatars.githubusercontent.com/u/23637144', name: 'Sleaf' }
    ];

    let max = 6;

    const popover = (
        <Tooltip arrow={false} >
            {t("space.members.avatar_tooltip")}
        </Tooltip>
    );


    return (
        <div className="space-header__right__members">

            <Modals
                id="spaceMembers"
                size="sm"
                title={t("space.members.modal.title")}
                content={<ModalMembers />}
                overflow={false}
                className="disable-overflow"
                tabs={[
                    {
                        label: t("space.members.modal.invite"),
                        screen: "invite",
                    },
                    {
                        label: t("space.members.modal.members"),
                        screen: "members",
                        default: true
                    },
                ]}
            />


            <Whisper followCursor placement="bottom" trigger="hover" controlId="space-members" speaker={popover}>

                <AvatarGroup stack onClick={() => modal.open("spaceMembers", {
                    space_id: space.currentId
                })}>
                    {users
                        .filter((user, i) => i < max)
                        .map(user => (
                            <Avatar circle key={user.name} src={user.avatar} alt={user.name} size="sm" />
                        ))}
                    {users.length > max &&
                        <Avatar circle style={{ background: '#111' }} size="sm">
                            +{users.length - max}
                        </Avatar>
                    }
                </AvatarGroup>

            </Whisper>
        </div>
    );
};

export default Members;
