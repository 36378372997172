import React from 'react';
import { useTranslation } from "react-i18next";
import { api, utils, notifications } from 'utils';
import { Link } from 'react-router-dom';

//Components
import Icon from "@components/Icon/Icon";
import Loading from '@components/Loading/Loading';
import { Avatar } from "rsuite";

//import classnames
let classNames = require('classnames');

const NotificationsList = ({ items, viewAllCallback }) => {

    const { t } = useTranslation();

    return <div className='notifications__list'>
        <div className='notifications__list__top'>
            {items.length > 0 ? items.map((notification, index) => {

                var notificationClass = classNames({
                    'notifications__list__top__item': true,
                    'new': notification.new,
                });

                return <Link to='#' className={notificationClass}>
                    <div className='notifications__list__top__item__avatar'>
                        <Avatar src={notification.user.avatar}>{utils.getInitials(notification.user.name)}</Avatar>
                    </div>
                    <div className='notifications__list__top__item__content'>
                        {notifications.generateDetails(notification)}
                        <div className='time'><Icon icon="clock" /> 2 hours ago</div>
                    </div>

                </Link>
            })
                : <Loading />
            }
        </div>
        {items.length > 0 && <div className='notifications__list__bottom'>
            <Link to='/notifications/' onClick={viewAllCallback}>
                {t('global.view_all')}
            </Link>
        </div>
        }
    </div>
}

export default NotificationsList;