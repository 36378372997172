import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { modal, utils } from 'utils';

//Components
import Modals from "../../../../Modals/Modals";
import Icon from "../../../../Icon/Icon";
import { Avatar, Tooltip, Whisper } from "rsuite";
import WorkspaceSwitch from "./Modals/Switch/WorkspaceSwitch";


const Workplace = () => {
  const { t } = useTranslation();

  return (
    <>
      <Modals
        id="workspaceSwitch"
        size="xs"
        title={t("workspace.switch_workspace")}
        content={<WorkspaceSwitch />}
        actions={[
          {
            label: t("global.change"),
            appearance: "primary",
            hasLoading: true,
            callback: () => modal.setCallback("workspaceSwitch"),
          },
        ]}
      />

      <div className="layout-sidebar__footer__workplace">
        <div className="layout-sidebar__footer__workplace__logo">
          <Avatar
            size="xs"
            src={"https://avatars.githubusercontent.com/u/12592949"}
          >
            {utils.getInitials("MindK")}
          </Avatar>
        </div>
        <div className="layout-sidebar__footer__workplace__name">
          MindK sdfdsfsdf sdfdsfds fsdf sdf
        </div>
        <div className="layout-sidebar__footer__workplace__action">
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>{t("workspace.switch_workspace")}</Tooltip>}
          >
            <NavLink
              to="/workspace/switch"
              onClick={(e) => {
                e.preventDefault();
                modal.open("workspaceSwitch")
              }}
            >
              <Icon icon="switch" color="#fff" />
            </NavLink>
          </Whisper>
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>{t("workspace.worksapce_settings")}</Tooltip>}
          >
            <NavLink to="/workspace">
              <Icon icon="gear" color="#fff" />
            </NavLink>
          </Whisper>
        </div>
      </div>
    </>
  );
};

export default Workplace;
