import React, { useEffect } from "react";
import { Grid, Row, Col } from "rsuite";
import { useTranslation } from "react-i18next";
import { modal } from 'utils'
import { Outlet, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

//Components
import IconText from "@components/IconText/IconText";
import IconCard from "@components/IconCard/IconCard";
import SidebarList from "@components/SidebarList/SidebarList";
import Content from "@components/Layout/Content/Content";
import Modals from "@components/Modals/Modals";
import ModalAddFolder from "./Modals/ModalAddFolder/ModalAddFolder";


let classNames = require("classnames");

const SpaceFiles = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { folderId, spaceId } = useParams();

    const folders = [
        {
            id: 1,
            title: "All files",
            count: 120
        },
        {
            id: 2,
            title: "Folder A",
            count: 23
        },
        {
            id: 3,
            title: "Folder B",
            count: 0
        },
        {
            id: 4,
            title: "Folder C",
            count: 23
        }

    ];
    //const folders = [];

    useEffect(() => {
        //Redirect to first password if no password is selected
        if (folders.length > 0 && folderId === undefined) {
            let redirect = `/space/${folderId}/files/${folders[0].id}`;
            navigate(redirect);
        }
    })


    const FolderItem = (props) => {

        let folderLinkClass = classNames({
            active: props.item.id === folderId,
        });

        return <IconCard
            icon="folder"
            title={props.item.title}
            subtitle={`${props.item.count} files`}
            url={`/space/${spaceId}/files/${props.item.id}`}
            className={folderLinkClass}
        />
    };

    return <>


        <Modals
            id="addFolder"
            title={t("space.files.add_folder")}
            content={<ModalAddFolder />}
            size="xs"
            actions={[
                {
                    label: t("global.add"),
                    appearance: "primary",
                    hasLoading: true,
                    callback: () => modal.setCallback("addFolder"),
                },
            ]}
        />



        <Content
            seo={
                {
                    title: t("organization.title"),
                }
            }
            sidebar={

                folders.length > 0 && <SidebarList
                    searchFields={["title"]}
                    searchBtns={[
                        {
                            tooltip: t("space.files.add_folder"),
                            callback: () => modal.open("addFolder"),
                            icon: "plus"
                        }
                    ]}
                    data={folders}
                    listComponent={<FolderItem />}
                />

            }
            className="content-view__spaces__files"
        >
            {folders.length > 0 && <Outlet />}

            {folders.length === 0 &&
                <Row gutter={30} >
                    <Col md={24} className="content-view__spaces__files__no-data">
                        <IconText icon="file" title={t("space.files.no_data.title")} text={t("space.files.no_data.text")} className="md" button={
                            {
                                label: t("space.files.add_file"),
                                callback: () => modal.open("addFile"),
                                appearance: "primary"
                            }
                        } />
                    </Col>
                </Row>
            }
        </Content>




    </>;
};
export default SpaceFiles;
