import React, { useEffect, useCallback, useRef } from "react";
import { board, modal } from "utils";

//Drag stuff
import {
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
    closestCenter,
    closestCorners
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates, SortableContext, horizontalListSortingStrategy, arrayMove as dndKitArrayMove } from "@dnd-kit/sortable";

//hooks
import useDetectScroll from "@hooks/useDetectScroll";

//Store
import { useReactiveVar } from "@apollo/client";
import { spaces, modals } from "stores";
import { set } from "immutable-modify";
import { useTranslation } from "react-i18next";

//Components
import Column from "./Column/Column";
import Task from './Tasks/Task/Task';
import LoadingColumns from './Column/LoadingColumns';
import Modals from '@components/Modals/Modals';


const Board = () => {

    const { t } = useTranslation();
    const { space } = useReactiveVar(spaces);
    const { open } = useReactiveVar(modals);
    const { handleScroll } = useDetectScroll();

    //Load data
    useEffect(() => {
        const data = [
            {
                id: 'status-1',
                title: "Backlog sdf sf sdfdsfsdf sdf sff sdfds f",
                tasks: [
                    {
                        id: '1',
                        title: "Card 1",
                        description: "Description 1",
                        assigned: {
                            id: 1,
                            name: "John Doe",
                            avatar: "https://avatars.githubusercontent.com/u/12592949",
                            email: "email@gmail.com"
                        }
                    },
                    {
                        id: '2',
                        title: "Card 2",
                        description: "Description 2",
                        assigned: {
                            id: 2,
                            name: "Bruno Santos",
                            avatar: "https://avatars.githubusercontent.com/u/12592929",
                            email: "email@gmail.com"
                        }
                    }
                ]
            },
            {
                id: 'status-2',
                title: "To Do",
                tasks: [
                    {
                        id: '11',
                        title: "Card 1",
                        description: "Description 1",
                    },
                    {
                        id: '22',
                        title: "Card 2",
                        description: "Description 2",
                    },
                    {
                        id: '33',
                        title: "Card 3",
                        description: "Description 2",
                    },
                    {
                        id: '44',
                        title: "Card 4",
                        description: "Description 2",
                        assigned: {
                            id: 2,
                            name: "Christophe Lévis Blatyup Yep",
                            avatar: "https://avatars.githubusercontent.com/u/12591929",
                            email: "email.emaildogajo@githubusercontent.com"
                        }
                    },
                    {
                        id: '55',
                        title: "Card 1",
                        description: "Description 1",
                    },
                    {
                        id: '66',
                        title: "Card 2",
                        description: "Description 2",
                    },
                    {
                        id: '77',
                        title: "Card 3",
                        description: "Description 2",
                    },
                    {
                        id: '88',
                        title: "Card 4",
                        description: "Description 2",
                    },
                    {
                        id: '99',
                        title: "Card 1",
                        description: "Description 1",
                    },
                    {
                        id: '111',
                        title: "Card 2",
                        description: "Description 2",
                    },
                    {
                        id: '222',
                        title: "Card 3",
                        description: "Description 2",
                    },
                    {
                        id: '333',
                        title: "Card 4",
                        description: "Description 2",
                    }

                ]
            },
            {
                id: 'status-3',
                title: "Done",
                tasks: []
            },
            {
                id: 'status-4',
                title: "Other",
                tasks: []
            },
            {
                id: 'status-5',
                title: "Other",
                tasks: []
            },
            {
                id: 'status-6',
                title: "Other",
                tasks: []
            }
        ];
        spaces(set(spaces(), "space.board.columns", data));
    }, []);


    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor)
    );

    //We need to use useCallback to avoid infinite loop
    const handleDragOver = useCallback(
        (event) => {
            board.handleDragOver(event);
        },
        [spaces]
    );


    const deleteColumn = () => {
        modal.loading(true);
        setTimeout(() => {
            board.handleDeleteSection(open.args.column_id);
            modal.close();
        }, 1000);
    };


    return <>

        <Modals
            id="deleteColumn"
            size="xs"
            type="icon"
            icon="question-mark"
            title={t("space.tasks.column.modal.remove_section_title")}
            content={t("space.tasks.column.modal.remove_section_text")}
            align="center"
            actions={[
                {
                    label: t("global.delete"),
                    appearance: "primary",
                    hasLoading: true,
                    callback: () => deleteColumn(),
                },
            ]}
        />

        <div id="board" className="view__spaces__tasks__board" onScroll={handleScroll}>

            {space && space.board.columns && space.board.columns.length > 0 ?

                <DndContext
                    sensors={sensors}
                    onDragStart={board.handleDragStart}
                    onDragCancel={board.handleDragCancel}
                    onDragEnd={board.handleDragEnd}
                    onDragOver={handleDragOver}
                    collisionDetection={closestCenter}
                >
                    <SortableContext
                        items={space.board.columns.map(column => column.id)}
                        strategy={horizontalListSortingStrategy}
                    >
                        {
                            space.board.columns.map((column, index) => !column?.disabled && <Column column={column} key={column.id} index={index} />)
                        }
                    </SortableContext>


                    <DragOverlay style={{ zIndex: 999 }} className="task-dragged" >
                        {(space.board.activeItem && space.board.activeItem.type === "task") && <Task isOver key={space.board.activeItem.item.id} task={space.board.activeItem.item} index={space.board.activeItem.index} />}
                        {(space.board.activeItem && space.board.activeItem.type === "column") && <Column isOver column={space.board.activeItem.item} key={space.board.activeItem.item.id} index={space.board.activeItem.index} />}
                    </DragOverlay>

                </DndContext>


                : <LoadingColumns />}

        </div>
    </>;
}

export default Board;
