import React, { useState, useEffect } from "react";
import { Uploader, Loader } from "rsuite";

//Components
import Icon from "../../../Icon/Icon";

function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

const ProfileAvatar = () => {
  const [uploading, setUploading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);

  return (
    <div className="avatar-uploader-wrapper">
      <Uploader
        className="avatar-uploader"
        fileListVisible={false}
        listType="picture"
        action="//jsonplaceholder.typicode.com/posts/"
        onUpload={(file) => {
          setUploading(true);
          previewFile(file.blobFile, (value) => {
            setFileInfo(value);
          });
        }}
        onSuccess={(response: Object, file: FileType) => {
          setUploading(false);
          console.log(response);
        }}
        onError={() => {
          setFileInfo(null);
          setUploading(false);
        }}
      >
        <button>
          {uploading && <Loader backdrop center />}
          {fileInfo ? (
            <img src={fileInfo} width="100%" height="100%" />
          ) : (
            <>
              <Icon icon="edit" className="icon-edit" />
              <Icon icon="user" className="icon-center" />
            </>
          )}
        </button>
      </Uploader>
    </div>
  );
};

export default ProfileAvatar;
