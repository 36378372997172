
import { useTranslation } from "react-i18next";
import ModalPaymentInfo from './Modals/PaymentInfo/ModalPaymentInfo'
import { modal, utils } from 'utils';

//Components
import Block from "@components/Block/Block";
import Modals from "@components/Modals/Modals";
import Icon from "@components/Icon/Icon";

const PaymentInfo = () => {
    const { t } = useTranslation();


    return <>

        <Modals
            id="paymentInfo"
            size="xs"
            title={t("organization.payment_info")}
            content={<ModalPaymentInfo />}
            actions={[
                {
                    label: t("global.save"),
                    appearance: "primary",
                    hasLoading: true,
                    callback: () => modal.setCallback("paymentInfo"),
                },
            ]}
        />

        <Block title={t("organization.payment_info")} className="payment-block" actions={[
            {
                label: t("organization.change_payment"),
                icon: "edit",
                callback: () => modal.open("paymentInfo")
            }
        ]}>
            <div className="payment-block__card-icon">
                <Icon icon="visa" />
            </div>
            <div className="payment-block__details">
                <dl>
                    <dt>{t('organization.card')}</dt>
                    <dd>{utils.creditCard.getType(4242424242424242)} ending in 2091</dd>
                    <dt>{t('organization.expiration')}</dt>
                    <dd>06/20</dd>
                </dl>
            </div>
        </Block>
    </>;
}

export default PaymentInfo;