import React, { useEffect } from "react";
import Task from "./Task/Task";
import { useTranslation } from "react-i18next";

import { useDroppable } from "@dnd-kit/core";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import useDetectScroll from '@hooks/useDetectScroll';


let classNames = require("classnames");

const Tasks = ({ tasks, id }) => {

    const { t } = useTranslation();
    const { setNodeRef } = useDroppable({ id });
    const { handleScroll } = useDetectScroll();

    let columnInsideTasks = classNames({
        'empty': tasks.length === 0,
        'view__spaces__tasks__board__column__inside__tasks': true,
    });




    return (
        <SortableContext
            id={id}
            items={tasks.map(task => task.id)}
            strategy={rectSortingStrategy}
        >
            <div className={`${columnInsideTasks}`} ref={setNodeRef} onScroll={handleScroll}>
                {tasks.length === 0 && <div className="view__spaces__tasks__board__column__inside__tasks__empty" />}

                {tasks?.map((task, index) => (
                    (task && !task?.disabled) && <Task key={task.id} id={task.id} task={task} index={index} />
                ))}

                <a href="#" className="view__spaces__tasks__board__column__inside__tasks__add-task">+ {t("space.tasks.add_task")}</a>
            </div >
        </SortableContext>
    )
}

export default Tasks;





