
import { useTranslation } from "react-i18next";

//Components
import Block from "@components/Block/Block";
import RemoteTable from "@components/RemoteTable/RemoteTable";
import RemoteTableActions from "@components/RemoteTable/CustomCell/RemoteTableActions";
import RemotePad from "@components/RemoteTable/CustomCell/RemotePad";
import RemoteDate from "components/RemoteTable/CustomCell/RemoteDate";

const Invoices = () => {
    const { t } = useTranslation();

    const columns = [
        {
            dataKey: 'id',
            label: '#',
            width: 70,
            customCell: <RemotePad number={5} />
        }, {
            dataKey: 'date',
            label: t('tables.date'),
            customCell: <RemoteDate />
        }, {
            dataKey: 'description',
            label: t('tables.description'),
            flexGrow: 2
        }, {
            dataKey: 'amount',
            label: t('tables.amount'),
            flexGrow: 1
        }, {
            dataKey: 'id',
            label: '',
            align: 'center',
            customCell: <RemoteTableActions actions={[
                {
                    label: t("global.download"),
                    callback: (id) => { alert(id) }
                }
            ]} />
        },
    ];

    return <Block title={t("organization.billing_history")} className="invoices-block" withTable noPadding>
        <RemoteTable columns={columns} itemsPerPage="20" tableKey="1" remoteUrl="/invoices" sizePerPage={10} />
    </Block>;
}

export default Invoices;