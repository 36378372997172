import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

//Components
import Link from "../../../Link/Link";
import Spaces from "./Spaces/Spaces";


const SideNav = () => {
  const { t } = useTranslation();

  useEffect(() => {
    console.log("SideNav mounted");
  });

  return (
    <div className="layout-sidebar__nav">
      <div className="widget widget__top no-padding">
        <ul className="widget__menu widget__menu__simple">
          <li>
            <Link path="/" label={t("mainMenu.dashboard")} icon="dashboard" />
          </li>
          <li>
            <Link path="/my-tasks" label={t("mainMenu.my_tasks")} icon="tasks" />
          </li>
          <li>
            <Link path="/reports" label={t("mainMenu.reports")} icon="chart" />
          </li>
          <li>
            <Link path="/passwords" label={t("mainMenu.passwords")} icon="lock" />
          </li>
          <li>
            <Link path="/files" label={t("mainMenu.files")} icon="file" />
          </li>
        </ul>
      </div>

      <Spaces />
    </div>
  );
};

export default SideNav;
