import { Placeholder } from 'rsuite';

const LoadingColumns = () => {

    return [...Array(2)].map((_, i) => {

        //get random number between 1 and 4
        const random = Math.floor(Math.random() * 4) + 1;

        return <div className="view__spaces__tasks__board__column loading">
            <div className="view__spaces__tasks__board__column__inside">
                <div className='view__spaces__tasks__board__column__inside__header'>
                    <div className='view__spaces__tasks__board__column__inside__header__title'>
                        <Placeholder.Graph active height={40} />
                    </div>
                </div>
                <div className='view__spaces__tasks__board__column__inside__tasks'>
                    {[...Array(random)].map((_, i) => (
                        <Placeholder.Graph active height={70} />
                    ))}
                </div>
            </div>
        </div>;
    });
}

export default LoadingColumns;