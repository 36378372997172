import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

//Components
import { Container, Header, Content, Footer, Sidebar, Grid, Row } from "rsuite";
import LayoutSidebar from "./Sidebar/Sidebar";
import { Header as LayoutHeader } from "./Header/Header";
import { Footer as LayoutFooter } from "./Footer/Footer";

var classNames = require("classnames");


const Layout = ({ className, children }) => {

  useEffect(() => {
    console.log("Layout mounted");
  });


  let layoutClasses = classNames(className, "layout-wrap");

  return (
    <Container className={layoutClasses}>
      <Sidebar>
        <LayoutSidebar />
      </Sidebar>
      <Container>
        <Header>
          <LayoutHeader />
        </Header>
        <Content className="content-view">
          <Grid>
            <Row>
              <Outlet />
            </Row>
          </Grid>
          {/* <Footer>
            <LayoutFooter />
          </Footer>*/}
        </Content>
      </Container>
    </Container>
  );
};
export default Layout;
