import React, { useState } from "react";

import { utils } from "utils";

//Components
import Icon from "../Icon/Icon";
import { Badge, Avatar } from "rsuite";
import { NavLink, Link } from "react-router-dom";
import ScrollText from "../ScrollText/ScrollText";

let classNames = require("classnames");

const AvatarLink = (props) => {

    //{ item, name, id, avatar, linkTo, badge, state }
    //Props "item" is used when this component is on a object loop

    let name, id, avatar, badge, active;
    if (props.item) {
        name = props.item.name;
        id = props.item.id;
        avatar = props.item.avatar;
        badge = props.item.badge;
        active = props.item.active;
    } else {
        name = props.name;
        id = props.id;
        avatar = props.avatar;
        badge = props.badge;
        active = props.active;
    }

    const [itemActive, setItemActive] = useState(active);

    const trasnformLinkTo = (link) => {
        let findKey = link.substring(
            link.indexOf("[") + 1,
            link.lastIndexOf("]")
        );
        return props.linkTo.replace("[" + findKey + "]", (props.item) ? props.item[findKey] : props[findKey]);
    }


    const RenderAvatar = () => {
        if (badge) {
            return (
                <Badge content={badge}>
                    <Avatar src={avatar} alt={name}>
                        {utils.getInitials(name)}
                    </Avatar>
                </Badge>
            );
        } else {
            return (
                <Avatar src={avatar} alt={name}>
                    {utils.getInitials(name)}
                </Avatar>
            );
        }
    };

    //Constructm Breadcrumb State - this is used to set the name on the breadcrumbs
    let breadcrumbStateObject = {};
    if (props.breadcrumbState) {
        //breadcrumbStateObject[props.breadcrumbState.key] = (props.item) ? props.item[props.breadcrumbState.value] : props[props.breadcrumbState.value];
    }


    let avatarLinkClass = classNames({
        "avatar-link": true,
        light: props.light,
        dark: props.dark,
        active: itemActive,
    });


    return (
        <Link
            to={(props.linkTo) ? trasnformLinkTo(props.linkTo) : "/-"}
            state={breadcrumbStateObject}
            className={avatarLinkClass}
            onClick={(e) => {
                if (props.onClick) {
                    e.preventDefault();
                    props.onClick(id)
                }
            }}
        >
            <div className="avatar-link__logo">
                <RenderAvatar />
            </div>
            <div className="avatar-link__name">
                {name.length > 17 && <ScrollText text={name} />}
                {name.length <= 17 && <span>{name}</span>}
            </div>
            {
                props.active &&
                <div className="avatar-link__selected">
                    <Icon icon="check" color="#fff" />
                </div>
            }
        </Link >
    );
};

export default AvatarLink;