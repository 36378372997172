/***
 * This file contains all modal's callbacks.
 */

import { modals } from "stores";
import { set } from "immutable-modify";

export const modal = {

    setCallback: (callback) => {
        modals(set(modals(), "callback", callback))
    },

    open: (id, args = null) => {
        modals(set(modals(), "open.id", id));
        modals(set(modals(), "open.args", args));
    },

    close: () => {
        modals(set(modals(), "open.id", null));
        modals(set(modals(), "open.args", null));
        modals(set(modals(), "loading", false))
    },

    loading: (status) => {
        modals(set(modals(), "loading", status))
    },

    activetab: (screen) => {
        modals(set(modals(), "activeTab", screen))
    }

};