import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { modal } from 'utils/modals';

//Components
import IconTitle from '@components/IconTitle/IconTitle';
import Modals from '@components/Modals/Modals';
import FileList from './FileList/FileList';
import ModalAddFolder from "../Modals/ModalAddFolder/ModalAddFolder";
import ModalAddFile from "./Modals/ModalAddFile/ModalAddFile";
import Uploader from "./Uploader/Uploader";


const Folder = () => {
    const { t } = useTranslation();
    const { folderId } = useParams();
    const [showUploader, setShowUploader] = useState(false);

    const items = [
        {
            id: 1,
            label: "Demo - Text file",
            file: "demo.txt",
            extension: "txt",
            size: "1.2MB",
            uploaded: "2020-01-01",
        },
        {
            id: 2,
            label: "Demo - PDF file",
            file: "demo.pdf",
            extension: "pdf",
            size: "6.2MB",
            uploaded: "2020-01-01",
        },
        {
            id: 3,
            label: "Demo - Word file",
            file: "demo.docx",
            extension: "docx",
            size: "0.2MB",
            uploaded: "2022-05-31",
        },
        {
            id: 4,
            label: "Demo - Video file",
            file: "demo.avi",
            extension: "avi",
            size: "12.2MB",
            uploaded: "2022-05-31",
        },


    ];

    const deleteFolder = () => {
        console.log(folderId);
    }



    return (
        <>
            <Modals
                id="deleteFolder"
                size="xs"
                type="icon"
                icon="question-mark"
                title={t("space.files.delete_folder")}
                content={t("space.files.modal.sure_delete_folder")}
                align="center"
                actions={[
                    {
                        label: t("global.delete"),
                        appearance: "primary",
                        hasLoading: true,
                        callback: () => deleteFolder(),
                    },
                ]}
            />

            <Modals
                id="renameFolder"
                title={t("space.files.rename_folder")}
                content={<ModalAddFolder edit />}
                size="xs"
                actions={[
                    {
                        label: t("global.rename"),
                        appearance: "primary",
                        hasLoading: true,
                        callback: () => modal.setCallback("renameFolder")
                    },
                ]}
            />

            <Modals
                id="addFile"
                title={t("space.files.add_files")}
                content={<ModalAddFile />}
                size="sm"
                actions={[
                    {
                        label: t("global.save"),
                        appearance: "primary",
                        hasLoading: true,
                        callback: () => modal.setCallback("addFile")
                    },
                ]}
            />



            <div className='content-view__spaces__folders__item'>

                <IconTitle subtitle={`${120} files`} title={t("space.files.all_files")} icon="folder"
                    actions={[
                        {
                            tooltip: t("space.files.add_files"),
                            //callback: () => modalOpen("addFile"),
                            callback: () => setShowUploader(!showUploader),
                            icon: "plus"
                        },
                        {
                            tooltip: t("space.files.rename_folder"),
                            callback: () => modal.open("renameFolder", {
                                folder_id: folderId
                            }),
                            icon: "rename"
                        },
                        {
                            tooltip: t("space.files.delete_folder"),
                            callback: () => modal.open("deleteFolder"),
                            icon: "trash"
                        }
                    ]}
                />

                <Uploader show={showUploader} />

                <FileList items={items} />

            </div>
        </>
    );
}

export default Folder;