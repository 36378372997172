import UserNav from "./UserNav/UserNav";
import Breadcrumbs from "./Breadcrumbs/Breadcrumbs";
import TimeRecord from "./TimeRecord/TimeRecord";

export const Header = () => {
  return (
    <div className="layout-header">
      <div className="layout-header__breadcrumbs">
        <Breadcrumbs />
      </div>
      <div className="layout-header__time-record">
        <TimeRecord />
      </div>
      <div className="layout-header__user">
        <UserNav />
      </div>
    </div>
  );
};
