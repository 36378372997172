import React, { useState, useEffect, useRef } from "react";
import { useSignIn, useIsAuthenticated } from 'react-auth-kit'

import Logo from "../../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { schemas, api } from "utils"

import BrowserSeo from "@components/Layout/BrowserSeo/BrowserSeo";
import Icon from "@components/Icon/Icon";
import Block from "@components/Block/Block";
import Loading from "@components/Loading/Loading";
import { Grid, Row, Col, Form, Button, Divider } from "rsuite";

const Login = ({ recover }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated()

  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormValue();
  }, [recover])

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/");
    }
  }, [])


  const doSubmit = async (type) => {

    if (!formRef.current.check()) {
      console.error('Form Error');
      return;
    }

    setLoading(true);

    const login = await api.auth.login(type, { email: formValue.email, password: formValue.password });

    if (login && signIn(
      {
        token: login.access_token,
        expiresIn: login.access_token_expires_in,
        tokenType: "Bearer",
        refreshToken: login.refresh_token,
        refreshTokenExpireIn: login.refresh_token_expires_in,
        authState: {}
      }
    )) {
      setLoading(false);
      navigate("/");
    } else {
      setLoading(false);
    }

  }

  return (
    <Grid fluid className="content-view__login">
      <Row className="content-view__login__wrapper">
        <Col
          md={24}
          xs={24}
          className="content-view__login__wrapper__top"
        ></Col>
        <Col md={24} xs={24} className="content-view__login__wrapper__form">

          <div className="content-view__login__wrapper__form__inside">
            <img src={Logo} className="logo" />
            {loading &&
              <Block>
                <Row className="loading-wrapper">
                  <Col md={24} xs={24} >
                    <Loading size="md" label={t("modal.please_wait")} />
                  </Col>
                </Row>
              </Block>
            }
            {(!recover && !loading) && (
              <>
                <BrowserSeo title={t("login.login_title")} />

                <Block>
                  <Form
                    ref={formRef}
                    model={schemas.auth.login}
                    formValue={formValue}
                    onCheck={setFormError}
                    onChange={setFormValue}
                  >
                    <Row className="mb-4 mt-4 title-wrapper ">
                      <Col md={24} xs={24} className="text-center">
                        <h4 className="center mb-3">{t("login.login_title")}</h4>
                        {t("login.login_text")}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={24}
                        xs={24}
                        className="content-view__login__wrapper__form__inside__google-login"
                      >
                        <Button appearance="default" block>
                          <Icon icon="google" /> {t("login.google_login")}
                        </Button>
                      </Col>
                    </Row>
                    <Divider>or</Divider>
                    <Row>
                      <Col md={24} xs={24}>
                        <Form.Group controlId="email">
                          <Form.ControlLabel>
                            {t("login.email")}
                          </Form.ControlLabel>
                          <Form.Control name="email" />
                        </Form.Group>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Group controlId="password">
                          <Form.ControlLabel>
                            {t("login.password")}
                          </Form.ControlLabel>
                          <Form.Control type="password" name="password" />
                        </Form.Group>
                      </Col>
                      <Col
                        md={24}
                        xs={24}
                        className="content-view__login__wrapper__form__inside__submit-wrapper"
                      >
                        <Button appearance="primary" block type="submit" onClick={() => doSubmit("login")}>
                          {t("login.submit")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Block>
                <div className="content-view__login__wrapper__form__inside__footer-links">
                  <Link to="/recover-password">
                    {t("login.forgot_password")}
                  </Link>
                </div>
              </>
            )}
            {(recover && !loading) && (
              <>
                <BrowserSeo title={t("login.recover_title")} />
                <Block>
                  <Form
                    ref={formRef}
                    model={schemas.recover}
                    formValue={formValue}
                    onCheck={setFormError}
                    onChange={setFormValue}
                  >
                    <Row className="mb-4 mt-4 title-wrapper">
                      <Col md={24} xs={24} className="text-center">
                        <h4 className="center mb-3">{t("login.recover_title")}</h4>
                        {t("login.recover_text")}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={24} xs={24}>
                        <Form.Group controlId="password-recover">
                          <Form.ControlLabel>
                            {t("login.password")}
                          </Form.ControlLabel>
                          <Form.Control type="password" name="password-recover" />
                        </Form.Group>
                      </Col>
                      <Col
                        md={24}
                        xs={24}
                        className="content-view__login__wrapper__form__inside__submit-wrapper"
                      >
                        <Button appearance="primary" block type="submit" onClick={() => doSubmit("recover")}>
                          {t("login.recover_title")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Block>
                <div className="content-view__login__wrapper__form__inside__footer-links">
                  <Link to="/login">{t("login.form_title")}</Link>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </Grid>
  );
};
export default Login;
