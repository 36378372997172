import { useState, useRef, useEffect } from "react";
import { Grid, Row, Col, Form, CheckPicker } from "rsuite";
import { useTranslation } from "react-i18next";
import useModalCallback from "@hooks/useModalCallback";
import WorkspaceLogo from "./WorkspaceLogo";
import { set } from "immutable-modify";
import {
    api,
    schemas,
    utils,
    modal
} from "utils"

const ModalWorkspace = ({ edit }) => {

    const { t } = useTranslation();
    const formRef = useRef();

    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState({});
    const [members, setMembers] = useState();

    const fetchData = async () => {
        const members = await api.team.getTeam();
        setMembers(utils.setSelectOptions("name", "id", members.results));
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        console.log(formValue);
    }, [formValue])

    //SET LOGO PATH/ID
    const setUploadedImage = (id) => {
        const newFormValue = set(formValue, 'logo', id);
        setFormValue(newFormValue);
    }

    //ON SUBMIT
    useModalCallback("addWorkspace", () => {

        //Check for errors
        if (!formRef.current.check()) {
            console.error('Form Error');
            return;
        }

        console.log("SUBMITING FORM");
        console.log(formValue);

        modal.loading(true);
    });

    return (
        <Form
            ref={formRef}
            model={schemas.organization.generalSettings}
            formValue={formValue}
            onCheck={setFormError}
            onChange={setFormValue}
        >
            <Grid fluid>
                <Row className="mb-3">
                    <Col xs={24} >
                        <WorkspaceLogo uploadedImage={setUploadedImage} />
                        <Form.Control className="hidden" name="logo" value={formValue && formValue.logo} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={24} >
                        <Form.Group controlId="name">
                            <Form.ControlLabel>
                                {t("workspace.workspace_name")}
                            </Form.ControlLabel>
                            <Form.Control name="name" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={24} >
                        <Form.Group controlId="members">
                            <Form.ControlLabel>
                                {t("organization.group_members")}
                            </Form.ControlLabel>
                            <Form.Control name="members" block data={members} accepter={CheckPicker} />
                        </Form.Group>
                    </Col>
                </Row>
            </Grid>
        </Form>
    );
}

export default ModalWorkspace;