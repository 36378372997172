import { useState, useRef, useEffect, forwardRef } from "react";
import { modal } from "utils";
import { useTranslation } from "react-i18next";
import { forms, schemas } from "utils";


//Components
import { Grid, Row, Col, Form, Input } from "rsuite";
import Editor from "@components/Editor/Editor";
import useModalCallback from "@hooks/useModalCallback";
import AssignUserIcon from "@components/User/AssignUserIcon/AssignUserIcon";


const ModalSettings = ({ edit }) => {

    const { t } = useTranslation();
    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState();

    //ON SUBMIT
    useModalCallback("updateSpaceSettings", () => {

        console.log("SUBMITING FORM");
        console.log(formValue);

        if (!formRef.current.check()) {
            console.error('Form Error');
            return;
        }

        //modal.loading(true);
    });


    return (
        <>
            <Form
                ref={formRef}
                model={schemas.space.settings}
                formValue={formValue}
                onCheck={setFormError}
                onChange={setFormValue}
            >
                <Grid fluid>
                    <Row className="mb-3">
                        <Col xs={24} sm={12}>
                            <Form.Group controlId="name">
                                <Form.ControlLabel>
                                    {t("space.modal.name")}
                                </Form.ControlLabel>
                                <Form.Control name="name" />
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Group controlId="owner">
                                <Form.ControlLabel>
                                    {t("space.modal.owner")}
                                </Form.ControlLabel>
                                <AssignUserIcon
                                    label={t("space.no_owner")}
                                    assigned_={{
                                        id: 1,
                                        name: "John Doe asdsdfsdfs asd asd asd sad df df df dfdfdf",
                                        avatar: "https://avatars.githubusercontent.com/u/12592949",
                                        email: "email@gmail.com"
                                    }}
                                    callback={(id) => { alert(id) }}
                                    withName
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24}>
                            <Form.Group controlId="description">
                                <Form.ControlLabel>
                                    {t("space.modal.description")}
                                </Form.ControlLabel>
                                <Editor
                                    value={formValue?.description ? formValue.description : ""}
                                    onChange={(value) => {
                                        setFormValue(forms.saveOption("description", value, formValue));
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </Form>
        </>
    );
}

export default ModalSettings;