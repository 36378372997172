import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Highcharts, { chart } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useReactiveVar } from "@apollo/client";
import { global } from "stores";
import { useResizeDetector } from 'react-resize-detector';
import Moment from "react-moment";
import moment from 'moment';



const HoursOverTime = () => {
    const { t } = useTranslation();
    const { colors, charts } = useReactiveVar(global);
    const { width, height, resizeRef } = useResizeDetector();
    const chartRef = useRef(null);


    useEffect(() => {
        const chart = chartRef.current?.chart;
        if (chart) chart.reflow(false);
    }, [width, height]);


    //Convert hours to minutes
    const parseTime = (times) => {
        const newTimes = times.map((time) => moment.duration(time).asMinutes());
        return newTimes
    }


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxis: {
                ticks: {
                    callback: function (value, index, ticks) {
                        return moment.duration(value, 'minutes').format("HH:mm");
                    }
                }
            },
            xAxis: {
                //barThickness: 0.4
            }
        },
        plugins: {

            legend: {
                display: false,
                position: 'bottom',
            },
            tooltip: {
                padding: charts.tooltip.padding,
                boxPadding: charts.boxPadding,
                callbacks: {
                    label: function (value, data) {
                        return "Hours: " + moment.duration(value.formattedValue, 'minutes').format("HH:mm");
                    }
                }
            },
        },
    };


    const data = {
        labels: ['22/05', '23/05', '24/05', '25/05', '26/05', '27/05', '28/05'],
        datasets: [
            {
                label: 'Hours',
                data: parseTime(['18:33', '8:02', '24:12', '45:21', '1:43', '12:41', '3:03']),
                backgroundColor: colors.blue,
                //barPercentage: 0.5,
                barThickness: charts.barThickness,
            },
        ],
    };

    const chartOptions = {
        chart: {
            type: 'column',
            height: 250,
        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
                formatter: function () {
                    return moment.duration(this.value, 'minutes').format("HH:mm");
                }
            }
        },

        colors: [colors.green],
        credits: {
            enabled: false
        },
        title: {
            text: ''
        },


        tooltip: {
            formatter: function () {
                return this.point.series.name + ': <b>' + moment.duration(this.point.y, 'minutes').format("HH:mm");
            }
        },



        xAxis: {
            categories: ['22/05', '23/05', '24/05', '25/05', '26/05', '27/05', '28/05'],
            crosshair: true
        },

        series: [
            {
                name: "Hours",
                showInLegend: false,
                pointWidth: charts.barThickness,
                data: parseTime(['18:33', '8:02', '24:12', '45:21', '1:43', '12:41', '3:03'])
            }
        ]
    };




    return (
        <div style={{ "max-height": 250, "height": 250 }} ref={resizeRef}>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                ref={chartRef}
            />
        </div>
    );
};

export default HoursOverTime;
