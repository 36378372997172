import { useState, useRef, useEffect } from "react";
import { Grid, Row, Col, Form, TagInput, CheckPicker, Avatar } from "rsuite";
import { useTranslation } from "react-i18next";
import useModalCallback from "@hooks/useModalCallback";
import { useReactiveVar } from "@apollo/client";
import { modals } from "stores";
import {
    api,
    schemas,
    modal,
    utils
} from "utils"


const ModalAddTeamMember = ({ edit }) => {

    const { t } = useTranslation();
    const formRef = useRef();
    const { open } = useReactiveVar(modals);

    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState();
    const [workspaces, setWorkspaces] = useState();
    const [groups, setGroups] = useState();

    const [user, setUser] = useState({
        name: 'Bruno Santos',
        id: (open.args) ? open.args.member_id : null,
        email: 'bsantos@scorepixel.com',
        avatar: 'https://i.pravatar.cc/300'
    });

    const fetchData = async () => {
        const workspaces = await api.workspaces.getWorkspaces();
        const groups = await api.groups.getGroups();
        setWorkspaces(utils.setSelectOptions("name", "id", workspaces.results));
        setGroups(utils.setSelectOptions("name", "id", groups.results));
    }
    useEffect(() => {
        fetchData();
    }, [])

    //ON SUBMIT
    useModalCallback("addTeamMember", () => {



        //Check for errors
        if (!formRef.current.check()) {
            console.log('Form Error');
            console.log(formValue);
            return;
        }

        console.log("SUBMITING FORM");
        console.log(formValue);

        modal.loading(true);
    });

    return (
        <Form
            ref={formRef}
            model={schemas.organization.generalSettings}
            formValue={formValue}
            onCheck={setFormError}
            onChange={setFormValue}
        >
            <Grid fluid>
                {edit &&
                    <>
                        <Row className="mb-4 modal-header">
                            <Col xs={24} className="modal-header__avatar">
                                <Avatar circle src={user.avatar}>{utils.getInitials(user.name)}</Avatar>
                            </Col>
                            <Col xs={24} className="modal-header__name">
                                Bruno Santos
                            </Col>
                            <Col xs={24} className="modal-header__emial">
                                email@domain.com
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={24} >
                                <Form.Group controlId="groups">
                                    <Form.ControlLabel>
                                        {t("organization.groups")}
                                    </Form.ControlLabel>
                                    <CheckPicker data={groups} block name="groups" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                }
                {!edit &&
                    <>
                        <Row className="mb-4">
                            <Col xs={24}>
                                <p>When you invite new members, your monthly fee will be recalculated accordingly.</p>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={24} >
                                <Form.Group controlId="members_emails">
                                    <Form.ControlLabel>
                                        {t("organization.members_emails")}
                                    </Form.ControlLabel>
                                    <Form.Control name="members_emails" block accepter={TagInput} trigger={'Space'} onChange={utils.tagInputOnChange} />
                                    <Form.HelpText>{t("helpTexts.add_member_space")}</Form.HelpText>
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                }
                <Row className="mb-3">
                    <Col xs={24} >
                        <Form.Group controlId="workspaces">
                            <Form.ControlLabel>
                                {t("organization.workspaces")}
                            </Form.ControlLabel>
                            <Form.Control name="workspaces" block accepter={CheckPicker} data={workspaces} />
                        </Form.Group>
                    </Col>
                </Row>
            </Grid>
        </Form>
    );
}

export default ModalAddTeamMember;