//import React from "react";
//import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from 'App';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-auth-kit'
import { api } from "utils"


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <AuthProvider authType={'localstorage'}
        authName={'_auth'}
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}
        refresh={api.auth.refreshApi}
    >
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </AuthProvider>
);