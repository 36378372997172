import React from "react";
import { useTranslation } from "react-i18next";

//Components
import SimpleSidebarMenu from "@components/Navigation/SimpleSidebarMenu/SimpleSidebarMenu";


const Sidebar = () => {
    const { t } = useTranslation();

    const menuItems = [
        {
            path: "/organization/workspaces",
            icon: "workspace",
            label: t("organization.workspaces"),
            bigIcon: true
        },
        {
            path: "/organization/groups",
            icon: "group",
            label: t("organization.groups"),
            bigIcon: true
        },
        {
            path: "/organization/team",
            icon: "team",
            label: t("organization.team"),
            bigIcon: true
        },
        {
            divider: true
        },
        {
            path: "/organization/settings",
            icon: "gear",
            label: t("global.settings"),
            bigIcon: true
        },
        {
            path: "/organization/billing",
            icon: "invoice",
            label: t("organization.billing"),
            bigIcon: true
        },
    ]

    return (
        <div className="content-view__organization__sidebar">
            <div className="content-view__organization__sidebar__inside">
                <SimpleSidebarMenu items={menuItems} />
            </div>
        </div>
    );
};

export default Sidebar;
