import { useState, useRef, useEffect } from "react";
import { Grid, Row, Col, Form, CheckPicker } from "rsuite";
import { useTranslation } from "react-i18next";
import useModalCallback from "utils/hooks/useModalCallback";
import {
    api,
    schemas,
    utils,
    modal,
} from "utils"


const ModalAddGroup = ({ edit }) => {

    const { t } = useTranslation();
    const formRef = useRef();

    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState();
    const [members, setMembers] = useState();
    const [workspaces, setWorkspaces] = useState();


    const fetchData = async () => {

        const members = await api.team.getTeam();
        const workspaces = await api.workspaces.getWorkspaces();

        setMembers(utils.setSelectOptions("name", "id", members.results));
        setWorkspaces(utils.setSelectOptions("name", "id", workspaces.results));
    }

    useEffect(() => {
        fetchData();
    }, [])

    //ON SUBMIT
    useModalCallback("addGroup", () => {

        //Check for errors
        if (!formRef.current.check()) {
            console.error('Form Error');
            return;
        }

        console.log("SUBMITING FORM");
        console.log(formValue);

        modal.loading(true);
    });

    return (
        <Form
            ref={formRef}
            model={schemas.organization.generalSettings}
            formValue={formValue}
            onCheck={setFormError}
            onChange={setFormValue}
        >
            <Grid fluid>
                <Row className="mb-3">
                    <Col xs={24} >
                        <Form.Group controlId="name">
                            <Form.ControlLabel>
                                {t("organization.group_name")}
                            </Form.ControlLabel>
                            <Form.Control name="name" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={24} >
                        <Form.Group controlId="members">
                            <Form.ControlLabel>
                                {t("organization.group_members")}
                            </Form.ControlLabel>
                            <CheckPicker data={members} block name="members" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={24} >
                        <Form.Group controlId="workspaces">
                            <Form.ControlLabel>
                                {t("organization.workspaces")}
                            </Form.ControlLabel>
                            <CheckPicker data={workspaces} block name="workspaces" />
                        </Form.Group>
                    </Col>
                </Row>
            </Grid>
        </Form>
    );
}

export default ModalAddGroup;