import { useState, useRef, useEffect, forwardRef } from "react";
import { Row, Col, Form, TagInput, Input, Modal, Button, Avatar, Toggle } from "rsuite";
import { useTranslation } from "react-i18next";
import Nav from "@rsuite/responsive-nav";
import { schemas } from "utils"
import {
    utils,
    modal
} from 'utils'
import { Dropdown } from "components";
import { modals } from "stores";
import { useReactiveVar } from "@apollo/client";

import { settings } from "@utils";


const ModalMembers = ({ edit }) => {

    const { t } = useTranslation();
    const { activeTab } = useReactiveVar(modals);

    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState();
    const [textareaValue, setTextareaValue] = useState();


    const spaceMembers = [
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },
        {
            name: "Bruno Santos",
            avatar: "https://i.pravatar.cc/300",
            email: "bsantos@scorepixel.com"
        },

    ];

    const Textarea = forwardRef((props, ref) => <Input {...props} rows={3} as="textarea" ref={ref} />);


    const inviteCallback = () => {

        //Check for errors
        if (!formRef.current.check()) {
            console.log('Form Error');
            console.log(formValue);
            return;
        }

        console.log("SUBMITING FORM");
        console.log(formValue);

        modal.loading(true);
    }


    return (
        <>

            {activeTab === "invite" &&
                <Form
                    ref={formRef}
                    model={schemas.project.membersShare}
                    formValue={formValue}
                    onCheck={setFormError}
                    onChange={setFormValue}
                >
                    {/*JSON.stringify(formValue)*/}
                    {/*JSON.stringify(textareaValue)*/}

                    <Row className="modal-projectMembers__share">
                        <Col xs={24} >

                            <Form.Group controlId="members_emails">
                                <Form.ControlLabel>
                                    {t("space.members.modal.invite_with_email")}
                                </Form.ControlLabel>
                                <div className="email-selector">

                                    <Form.Control name="members_emails" block accepter={TagInput} trigger={'Space'} onChange={utils.tagInputOnChange} />

                                    <Dropdown
                                        placement="bottomEnd"
                                        onChange={() => console.log(1)}
                                        ControlWidth={220}
                                        options={settings.spaces.members.roleOptions}
                                    />


                                </div>
                                <Form.HelpText>{t("helpTexts.add_member_space")}</Form.HelpText>
                            </Form.Group>




                        </Col>


                        <Col xs={24} className="mt-4">


                            <Form.Group controlId="message">
                                <Form.ControlLabel>
                                    {t("space.members.modal.add_message")}
                                </Form.ControlLabel>
                                <Input name="message" rows={3} as="textarea" onChange={setTextareaValue} />
                            </Form.Group>

                        </Col>

                        <Col xs={24} className="mt-4">

                            <Form.Group controlId="notify_them" className="notify-them">
                                <Form.Control name="notify_them" size="sm" defaultChecked value="true" accepter={Toggle} />
                                <span>{t("space.members.modal.notify_them")}</span>
                            </Form.Group>

                        </Col>

                    </Row>
                    <Modal.Footer>
                        <Button appearance="primary" onClick={() => inviteCallback()}>
                            {t("space.members.modal.send_invitation")}
                        </Button>
                    </Modal.Footer>
                </Form>

            }

            {activeTab === "members" &&

                <>
                    <Row className="mb-4 modal-projectMembers__member-list">
                        {spaceMembers.map((member) =>
                            <Col xs={24}>
                                <div className="modal-projectMembers__member-list__item">
                                    <div className="modal-projectMembers__member-list__item__avatar">
                                        <Avatar src={member.avatar}>{utils.getInitials(member.name)}</Avatar>
                                    </div>
                                    <div className="modal-projectMembers__member-list__item__name">
                                        <div className="name">{member.name}</div>
                                        <div className="email">{member.email}</div>
                                    </div>
                                    <div className="modal-projectMembers__member-list__item__select">
                                        <Dropdown
                                            defaultValue="comment"
                                            placement="bottomEnd"
                                            onSelect={() => console.log(1)}
                                            options={settings.spaces.members.roleOptions}
                                        />
                                    </div>
                                </div>
                            </Col>
                        )}
                    </Row>

                    <Row className="mb-4 modal-projectMembers__user-count">
                        <Col xs={24}>
                            <span>{spaceMembers.length}</span> {t("space.members.modal.members_in_space")}
                        </Col>
                    </Row>
                </>

            }
        </>
    );
}

export default ModalMembers;