import { makeVar } from "@apollo/client";

const global = makeVar({
    colors: {
        blue: '#3498ff',
        green: '#63ea8c',
        orange: '#ff9a00',
        lightGray: '#f0f0f0'
    },
    charts: {
        tooltip: {
            padding: 8,
            boxPadding: 10
        },
        barThickness: 12
    },
});

export default global;
