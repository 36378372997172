import React, { useEffect } from "react";
import { Input, InputGroup, MaskedInput } from 'rsuite';
import { useTranslation } from "react-i18next";

//Components
import Icon from "../Icon/Icon";

const SearchInput = ({ callback }) => {
    const { t } = useTranslation();
    let searchHandler = (e) => {
        //convert input text to lower case
        let lowerCase = e.toLowerCase();
        callback(lowerCase);
    };

    return (
        <InputGroup size="md" inside className="search-input">
            <Input placeholder={t("search.search") + "..."} onChange={searchHandler} />
            <InputGroup.Addon>
                <Icon icon="search" color="#000" />
            </InputGroup.Addon>
        </InputGroup>
    );
};

export default SearchInput;
