import React from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { modals } from "stores";
import { modal } from 'utils';

//Components
import ModalAddPassword from "../Modals/ModalAddPassword";
import FieldList from './FieldList/FieldList';
import IconTitle from '@components/IconTitle/IconTitle';
import Modals from '@components/Modals/Modals';


const Access = () => {
    const { t } = useTranslation();
    const { accessId } = useParams();
    const { open } = useReactiveVar(modals);

    const items = [
        {
            label: "Username",
            value: "bsantos",
            type: "text"
        },
        {
            label: "Password",
            value: "123456",
            type: "password"
        },
        {
            label: "Email",
            value: "bsantos@scorepixel.com",
            type: "email"
        },
        {
            label: "Username",
            value: "bsantos",
            type: "text"
        },
        {
            label: "Password",
            value: "123456",
            type: "password"
        },
        {
            label: "Email",
            value: "bsantos@scorepixel.com",
            type: "email"
        },
        {
            label: "Username",
            value: "bsantos",
            type: "text"
        },
        {
            label: "Password",
            value: "123456",
            type: "password"
        },
        {
            label: "Email",
            value: "bsantos@scorepixel.com",
            type: "email"
        },
        {
            label: "Username",
            value: "bsantos",
            type: "text"
        },
        {
            label: "Password",
            value: "123456",
            type: "password"
        },
        {
            label: "Email",
            value: "bsantos@scorepixel.com",
            type: "email"
        },
        {
            label: "Username",
            value: "bsantos",
            type: "text"
        },
        {
            label: "Password",
            value: "123456",
            type: "password"
        },
        {
            label: "Email",
            value: "bsantos@scorepixel.com",
            type: "email"
        },
        {
            label: "Username",
            value: "bsantos",
            type: "text"
        },
        {
            label: "Password",
            value: "123456",
            type: "password"
        },
        {
            label: "Email",
            value: "bsantos@scorepixel.com",
            type: "email"
        },
        {
            label: "Username",
            value: "bsantos",
            type: "text"
        },
        {
            label: "Password",
            value: "123456",
            type: "password"
        },
        {
            label: "Email",
            value: "bsantos@scorepixel.com",
            type: "email"
        },
        {
            label: "Username",
            value: "bsantos",
            type: "text"
        },
        {
            label: "Password",
            value: "123456",
            type: "password"
        },
        {
            label: "Email",
            value: "bsantos@scorepixel.com",
            type: "email"
        },
        {
            label: "Username",
            value: "bsantos",
            type: "text"
        },
        {
            label: "Password",
            value: "123456",
            type: "password"
        },
        {
            label: "Email",
            value: "bsantos@scorepixel.com",
            type: "email"
        },
        {
            label: "Username",
            value: "bsantos",
            type: "text"
        },
        {
            label: "Password",
            value: "123456",
            type: "password"
        },
        {
            label: "Email",
            value: "bsantos@scorepixel.com",
            type: "email"
        },
        {
            label: "Username",
            value: "bsantos",
            type: "text"
        },
        {
            label: "Password",
            value: "123456",
            type: "password"
        },
        {
            label: "Email",
            value: "bsantos@scorepixel.com",
            type: "email"
        },
        {
            label: "Username",
            value: "bsantos",
            type: "text"
        },
        {
            label: "Password",
            value: "123456",
            type: "password"
        },
        {
            label: "Email",
            value: "bsantos@scorepixel.com",
            type: "email"
        },
    ];

    const removeAccess = () => {
        modal.loading(true);
        setTimeout(() => {
            alert("Deleted " + open.args.access_id);
            modal.close();
        }, 1000);
    }

    return (
        <>
            <Modals
                id="deleteAccess"
                size="xs"
                type="icon"
                icon="question-mark"
                title={t("modal.delete_access")}
                content={t("modal.sure_delete_access")}
                align="center"
                actions={[
                    {
                        label: t("global.delete"),
                        appearance: "primary",
                        hasLoading: true,
                        callback: () => removeAccess(),
                    },
                ]}
            />

            <Modals
                id="editAccess"
                title={t("space.passwords.edit_access")}
                content={<ModalAddPassword edit />}
                actions={[
                    {
                        label: t("global.edit"),
                        appearance: "primary",
                        hasLoading: true,
                        callback: () => modal.setCallback("editAccess"),
                    },
                ]}
            />

            <div className='content-view__spaces__passwords__item'>
                <IconTitle subtitle="Login" title="Access name here..." icon="server" actions={[
                    {
                        icon: "edit",
                        tooltip: t("space.passwords.edit_access"),
                        callback: () => modal.open("editAccess", {
                            access_id: accessId
                        })
                    },
                    {
                        icon: "trash",
                        tooltip: t("space.passwords.delete_access"),
                        callback: () => modal.open("deleteAccess", {
                            access_id: accessId
                        })
                    }
                ]} />
                <FieldList items={items} />
            </div>

        </>
    );
}

export default Access;