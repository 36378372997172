import { useRef, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { spaces } from "stores";
import { set } from "immutable-modify";

const useDetectScroll = () => {

    const timeoutRef = useRef(null);
    const { space } = useReactiveVar(spaces);

    const handleScroll = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            timeoutRef.current = null;
            spaces(set(spaces(), "space.board.task.showAssignBox", true));
        }, 100);

        if (!space.board.isScrolling) {
            spaces(set(spaces(), "space.board.task.showAssignBox", false));
        }
    };

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return {
        handleScroll
    };
};

export default useDetectScroll;