import React, { useState, useRef, useEffect } from 'react';

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { board } from "utils";
import useOutsideClick from '@hooks/useOutsideClick';
import { useTranslation } from "react-i18next";

//Components
import { Badge, IconButton, Tooltip, Whisper, Input } from 'rsuite';
import Icon from '@components/Icon/Icon';
import DropdownIcon from '@components/DropdownIcon/DropdownIcon';
import Tasks from "../Tasks/Tasks";

//Stores
import { modal } from 'utils';


let classNames = require("classnames");


const Column = ({ column, index, isOver, scrolling }) => {

    const { t } = useTranslation();
    const [hovered, setHovered] = useState(false);
    const [headerDropdownOpen, setHeaderDropdownOpen] = useState(false);
    const [disableDrag, setDisableDrag] = useState(false);
    const [columnRename, setColumnRename] = useState(false);
    const refTitleInput = useOutsideClick(() => {
        setColumnRename(false);
    });


    //Generate title - if title is too long, show tooltip and limit it - also if column is being renamed, show input
    const generateTitle = (title) => {
        let limit = 18;

        const titleWrapper = (title) => {
            return <div draggable
            //onMouseEnter={() => setDisableDrag(true)}
            //onMouseLeave={() => setDisableDrag(false)}
            //onClick={() => setColumnRename(true)}
            >
                {title}
            </div >;
        };

        if (columnRename) {
            return <Input
                size="sm"
                onMouseEnter={() => setDisableDrag(true)}
                onMouseLeave={() => setDisableDrag(false)}
                onChange={(value) => { handleColumnRename(value) }}
                value={title}
                ref={refTitleInput}
                autofocus
            />
        }

        if (title.length > limit) {
            return <Whisper placement="top" trigger="hover" speaker={<Tooltip>{title}</Tooltip >}>
                {titleWrapper(title.substring(0, limit) + '...')}
            </Whisper>;
        } else {
            return titleWrapper(title);
        }

    };

    //Focus on input when column is being renamed
    useEffect(() => {
        if (columnRename) {
            refTitleInput.current.focus();
        }
    }, [columnRename]);

    //Handle Column rename
    const handleColumnRename = (value) => {
        board.handleColumnRename(value, column.id);
    };

    //Mouse events
    const handleColumnMouseOver = () => {
        setHovered(true);
    };

    const handleColumnMouseOut = () => {
        setHovered(false);
    };


    //Draggable and sortable stuff
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: column.id,
        index,
        disabled: disableDrag,
        data: {
            item: column,
            type: 'column'
        }
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };

    //Classnames stuff
    let columnClass = classNames({
        'hover-drag': hovered,
        'dragging': isDragging,
        'dropdown-opened': headerDropdownOpen,
        'view__spaces__tasks__board__column': true,
        'is-over': isOver
    });


    return (
        <>

            <div
                className={columnClass}
                style={style}
                ref={setNodeRef}
                onMouseLeave={handleColumnMouseOut}
                id={column.id}
            >
                <div className="view__spaces__tasks__board__column__inside" id={column.id} >

                    <div className="view__spaces__tasks__board__column__inside__header"
                        {...attributes} {...listeners}
                        onMouseEnter={handleColumnMouseOver}
                        onMouseLeave={handleColumnMouseOut}
                    >

                        <div className='view__spaces__tasks__board__column__inside__header__title'>
                            {generateTitle(column.title)} <Badge content={column.tasks.length} /> {column?.disabled && column.disabled.toString()}
                        </div>

                        <div className='view__spaces__tasks__board__column__inside__header__actions'
                            onMouseEnter={() => setDisableDrag(true)}
                            onMouseLeave={() => setDisableDrag(false)}
                        >
                            <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("space.tasks.add_task")}</Tooltip >}>
                                <IconButton size="sm" icon={<Icon icon="plus" />} />
                            </Whisper>
                            <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("global.more_options")}</Tooltip >}>
                                <DropdownIcon
                                    onOpen={() => setHeaderDropdownOpen(true)}
                                    onClose={() => setHeaderDropdownOpen(false)}
                                    customIcon={<IconButton size="sm" icon={<Icon icon="ellipsis-h" />} />}
                                    placement="autoVerticalEnd"
                                    appearance="link"
                                    options={[
                                        {
                                            label: t("space.tasks.column.rename_section"),
                                            icon: "rename",
                                            onClick: (e) => {
                                                e.preventDefault();
                                                setColumnRename(true);
                                            }
                                        },
                                        {
                                            label: t("space.tasks.column.add_section"),
                                            icon: "columns",
                                            submenu: [
                                                {
                                                    label: t("space.tasks.column.add_section_left"),
                                                    icon: "arrow-left",
                                                    onClick: (e) => {
                                                        e.preventDefault();
                                                        board.handleAddSection(column.id, "left");
                                                        handleColumnMouseOut();
                                                    }
                                                },
                                                {
                                                    label: t("space.tasks.column.add_section_right"),
                                                    icon: "arrow-right",
                                                    onClick: (e) => {
                                                        e.preventDefault();
                                                        board.handleAddSection(column.id, "right");
                                                        handleColumnMouseOut();
                                                    }
                                                },
                                            ],
                                            onClick: (e) => {
                                                e.preventDefault();
                                            }
                                        },
                                        {
                                            label: t("space.tasks.column.delete_section"),
                                            icon: "trash",
                                            onClick: (e) => {
                                                e.preventDefault();
                                                modal.open("deleteColumn", {
                                                    column_id: column.id
                                                });
                                            }
                                        },
                                    ]} />
                            </Whisper>
                        </div>


                    </div>

                    <Tasks id={column} tasks={column.tasks} scrolling={scrolling} />


                </div>

            </div>
        </>
    );



};

export default Column;
