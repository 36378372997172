import { makeVar } from "@apollo/client";

const layout = makeVar({
  menu: {
    openMenu: 0,
  },
  timer: {
    active: true,
    taskId: 'TM-3487',
    startTime: '2023-06-01 00:00:00',
  }
});

export default layout;
