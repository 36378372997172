//Components
import Icon from 'components/Icon/Icon';
import { Button } from 'rsuite';

const IconText = ({ icon, title, text, className, button }) => {
    return (<div className={`icontext-wrapper ${className}`}>
        <div className="icontext-wrapper__icon" >
            <Icon icon={icon} />
        </div >
        <div className="icontext-wrapper__text">
            {title && <h5 className="center">{title}</h5>}
            {text && <p>{text}</p>}
        </div>
        {button &&
            <div className="icontext-wrapper__button">
                <Button appearance={button.appearance} size="sm" onClick={button.callback}>{button.label}</Button>
            </div>
        }
    </div >);
}

export default IconText;