import { makeVar } from "@apollo/client";

const spaces = makeVar({
    space: {
        currentId: null,
        board: {
            search: {
                text: '',
                count: 0,
            },
            columns: null,
            activeItem: null,
            task: {
                showAssignBox: true,
            },
        },
    }
});

export default spaces;
