import i18n from "i18next";


/***
 * This file contains all the settings for the application.
 */

export const settings = {
    time: {
        dateFormat: [
            {
                label: "MM/DD/YYYY",
                value: "MM/DD/YYYY",
            },
            {
                label: "DD/MM/YYYY",
                value: "DD/MM/YYYY",
            },
            {
                label: "MM-DD-YYYY",
                value: "MM-DD-YYYY",
            },
            {
                label: "DD-MM-YYYY",
                value: "DD-MM-YYYY",
            },
            {
                label: "YYYY-MM-DD",
                value: "YYYY-MM-DD",
            },
        ],
        timeFormat: [
            {
                label: "12-hour",
                value: "12-hour",
            },
            {
                label: "24-hour",
                value: "24-hour",
            },
        ],
        durationFormat: [
            {
                label: "Classic (HH:MM)",
                value: "HH:MM",
            },
            {
                label: "Detailed (HH:MM:SS)",
                value: "HH:MM:SS",
            },
        ],
        daysWeek: [
            {
                label: "Monday",
                value: "monday",
            },
            {
                label: "Tuesday",
                value: "tuesday",
            },
            {
                label: "Wednesday",
                value: "wednesday",
            },
            {
                label: "Thursday",
                value: "thursday",
            },
            {
                label: "Friday",
                value: "friday",
            },
            {
                label: "Saturday",
                value: "saturday",
            },
            {
                label: "Sunday",
                value: "sunday",
            },
        ]
    },
    spaces: {
        members: {
            roleOptions: [
                {
                    label: i18n.t("roles.types.admin.label"),
                    value: "admin",
                    desc: i18n.t("roles.types.admin.desc"),
                },
                {
                    label: i18n.t("roles.types.member.label"),
                    value: "member",
                    desc: i18n.t("roles.types.member.desc"),
                    active: true
                },
                {
                    label: i18n.t("roles.types.viewer.label"),
                    value: "viewer",
                    desc: i18n.t("roles.types.viewer.desc"),
                },
            ]
        },
        accesses: {
            types: [
                { label: 'Login', value: 'login', icon: 'lock' },
                { label: 'Email Account', value: 'email_account', icon: 'envelope' },
                { label: 'Server', value: 'server', icon: 'server' },
                { label: 'Api Credentials', value: 'api_credentials', icon: 'code' },
                { label: 'Database', value: 'database', icon: 'database' },
                { label: 'Password', value: 'password', icon: 'password' },
                { label: 'Software License', value: 'software_license', icon: 'key' },
            ],
            field_types: [
                { label: 'Text', type: 'text' },
                { label: 'Password', type: 'password' },
                { label: 'Email', type: 'email' }
            ],
            icon_types: {
                "email_account": "envelope",
                "password": "password",
                "server": "server",
                "api_credentials": "code",
                "database": "database",
                "software_license": "key",
                "login": "lock",
            }
        },
        files: {


        }
    },
    notifications: {
        types: {
            comment: {
                message: i18n.t("notifications.types.comment.message"),
                label: i18n.t("notifications.types.comment.label"),
                value: "comment",
                icon: "comment",
                slug: "comments"
            },
            assigned_task: {
                message: i18n.t("notifications.types.assigned_task.message"),
                label: i18n.t("notifications.types.assigned_task.label"),
                value: "assigned_task",
                icon: "tasks",
                slug: "assigned-task"
            },
            mentioned: {
                message: i18n.t("notifications.types.mentioned.message"),
                label: i18n.t("notifications.types.mentioned.label"),
                value: "mentioned",
                icon: "mention",
                slug: "mentioned"
            },
            assigned_group: {
                message: i18n.t("notifications.types.assigned_group.message"),
                label: i18n.t("notifications.types.assigned_group.label"),
                value: "assigned_group",
                icon: "group",
                slug: "assigned-group"
            },
        }
    },
};

