import { utils } from 'utils';
import { useState } from 'react';

//Components
import Icon from "@components/Icon/Icon";

const FieldItem = ({ item, index }) => {

    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="content-view__spaces__passwords__item__field-list__item" key={index}>
            <div className="content-view__spaces__passwords__item__field-list__item__label">{item.label}</div>
            <div className="content-view__spaces__passwords__item__field-list__item__value">
                <div className='value'>{item.type === "password" && !showPassword ? "******" : item.value}</div>
                <div className='actions'>
                    {item.type === "password" && <span onClick={() => setShowPassword(!showPassword)} > <Icon icon={showPassword ? "hide" : "show"} /></span>}
                    <span onClick={() => {
                        navigator.clipboard.writeText(item.value);
                        utils.notify("Copied to clipboard");
                    }}  >
                        <Icon icon="copy" />
                    </span>
                </div>
            </div>
        </div>
    );
}

export default FieldItem;