import { useTranslation } from "react-i18next";
import { utils, modal } from "utils";
import { Grid, Row, Col, Avatar } from 'rsuite';
import { useReactiveVar } from "@apollo/client";
import { modals } from "stores";

//Components
import Block from "@components/Block/Block";
import BrowserSeo from "@components/Layout/BrowserSeo/BrowserSeo";
import RemoteTable from "@components/RemoteTable/RemoteTable";
import RemoteTableActions from "@components/RemoteTable/CustomCell/RemoteTableActions";
import Modals from "@components/Modals/Modals";
import ModalWorkspace from "./Modals/ModalWorkspace";
import RemoteAvatar from "@components/RemoteTable/CustomCell/RemoteAvatar";



const Workspaces = () => {
    const { t } = useTranslation();
    const { open } = useReactiveVar(modals);

    <BrowserSeo title={t("organization.workspaces")} />

    const columns = [
        {
            dataKey: 'logo',
            label: '',
            width: 60,
            customCell: <RemoteAvatar />,
            resizable: false
        }, {
            dataKey: 'name',
            label: t('tables.name'),
            sort: true,
        }, {
            dataKey: 'members',
            label: t('tables.members'),
            width: 120
        }, {
            dataKey: 'admins',
            label: t('tables.admins'),
        }, {
            dataKey: 'id',
            label: '',
            align: "center",
            width: 60,
            customCell: <RemoteTableActions actions={[
                {
                    label: t("global.edit"),
                    callback: (id) => modal.open("editWorkspace", {
                        workspace_id: id
                    })
                },
                {
                    label: t("global.delete"),
                    callback: (id) => modal.open("removeWorkspace", {
                        workspace_id: id
                    })
                }
            ]} />
        }
    ];

    const removeWorkspace = () => {
        modal.loading(true);
        setTimeout(() => {
            alert("Deleted " + open.args.workspace_id);
            modal.close();
        }, 1000);
    }


    return <>

        <Modals
            id="addWorkspace"
            size="xs"
            title={t("workspace.add_workspace")}
            content={<ModalWorkspace />}
            actions={[
                {
                    label: t("global.add"),
                    appearance: "primary",
                    hasLoading: true,
                    callback: () => modal.setCallback("addWorkspace"),
                },
            ]}
        />

        <Modals
            id="editWorkspace"
            size="xs"
            title={t("workspace.edit_workspace")}
            content={<ModalWorkspace edit />}
            actions={[
                {
                    label: t("global.save"),
                    appearance: "primary",
                    hasLoading: true,
                    callback: () => modal.setCallback("editWorkspace"),
                },
            ]}
        />

        <Modals
            id="removeWorkspace"
            size="xs"
            type="icon"
            icon="question-mark"
            title={t("workspace.delete_workspace")}
            content={t("modal.sure_delete_workspace")}
            align="center"
            actions={[
                {
                    label: t("global.delete"),
                    appearance: "primary",
                    hasLoading: true,
                    callback: () => removeWorkspace(),
                },
            ]}
        />

        <Grid fluid className="content-view__organization__content__workspaces">
            <Row>
                <Col md={24}>
                    <Block title={t("organization.workspaces")} className="workspace-block" withTable noPadding actions={[
                        {
                            label: t("workspace.add_workspace"),
                            icon: "plus",
                            callback: () => modal.open("addWorkspace")
                        }
                    ]}>


                        <RemoteTable wordWrap={"break-word"} rowHeight={65} columns={columns} itemsPerPage="20" tableKey="1" remoteUrl="/workspaces" sizePerPage={10} search />
                    </Block>
                </Col>
            </Row>
        </Grid>
    </>;
};

export default Workspaces;
