import { set } from "immutable-modify";

export const forms = {
    saveOption: (optionName, optionValue, formValue) => {
        if (formValue === undefined) {
            formValue = {};
        }
        
        return set(formValue, optionName, optionValue);
    }
}