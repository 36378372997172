import React, { isValidElement, cloneElement, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Components
import IconText from "../IconText/IconText";
import Loading from "../Loading/Loading";

const SearchList = ({ search, data, fields, children }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [filteredData, setFilteredData] = useState();

    const filterList = async () => {
        if (data && search && search.length > 0) {
            let checkData = data.filter((el) => {
                //if no input the return the original
                if (search === '') {
                    //if empty - return all
                    return el;
                } else {
                    //return the item which contains the user input
                    let arr = [];
                    fields.map((field) => {
                        arr.push(el[field].toString().toLowerCase().includes(search));
                    });
                    return arr.some(e => e === true);
                }
            });
            setFilteredData(checkData);
        } else {
            setFilteredData(data);
        }
    }

    useEffect(() => {
        filterList();
    }, [data, search]);

    useEffect(() => {
        //Data Loaded - so disable loading
        setIsLoading(false);
    }, [filteredData])


    const addPropsToReactElement = (element, props) => {
        if (isValidElement(element)) {
            return cloneElement(element, props)
        }
        return element
    }

    const addPropsToChildren = (children, props) => {
        if (!Array.isArray(children)) {
            return addPropsToReactElement(children, props)
        }
        return children.map(childElement =>
            addPropsToReactElement(childElement, props)
        )
    }

    return (
        <div className="search__items" >
            {(!isLoading && filteredData) ? (filteredData.length > 0) ?
                filteredData.map((el, index) => {
                    return addPropsToChildren(children, { key: index, item: el })
                }) : <IconText icon="search" title={t("search.no_results.title")} text={t("search.no_results.text")} />
                : <Loading label={t("modal.please_wait")} />}
        </div>
    )
}

export default SearchList;
