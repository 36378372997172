import React, { useEffect, useState } from "react";

/*
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
//import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
//import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
*/
import { useTranslation } from "react-i18next";
import api from 'utils/axios';

//Components
import { Grid, Row, Col, SelectPicker, Form, Table, Pagination } from "rsuite";
import SearchInput from "../Search/SearchInput";
import IconText from "../IconText/IconText";
const { Column, HeaderCell, Cell } = Table;



const RemoteTable = ({ columns, tableKey, remoteUrl, sizePerPage, search, filters, rowHeight, wordWrap }) => {
    const { t } = useTranslation();

    //General
    const [firstInit, setFirstInit] = useState(true);

    //Table data
    const [tableloading, setTableloading] = useState(false);
    const [data, setData] = useState([]);

    //Search
    const [searchText, setSearchText] = useState(null);

    //Pagination
    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(0);
    const [limit, setLimit] = useState(10);

    //Sort
    const [sortField, setSortField] = useState();
    const [sortOrder, setSortOrder] = useState();

    //Filters
    const [activeFilters, setActiveFilters] = useState(null);
    const [filterFields, setFilterFields] = useState(null);
    const [filterValues, setFilterValues] = useState();

    //Load Filter Fields
    useEffect(() => {
        if (filters) {
            const filterArr = [];
            let element;
            filters.map((field) => {
                switch (field.type) {
                    case "select":
                        element = <Form.Control size="sm" block name={field.name} placeholder={field.placeholder} data={field.values} accepter={SelectPicker} />
                        break;
                }
                filterArr.push(<div className="field">{element}</div>);
            });
            setFilterFields(filterArr);
        }
    }, [filters])


    //Load data 1st time
    useEffect(() => {
        fetchData(page);
        setFirstInit(false);
    }, []);

    //ON search input change
    useEffect(() => {
        if (searchText) {
            fetchData(1);
        }
    }, [searchText]);

    useEffect(() => {
        console.log("----");
        console.log(filterValues);
    }, [filterValues])


    const fetchData = (page = 1) => {

        console.log("fetchData INIT");

        let params = {
            page: page,
            limit: sizePerPage,
        };

        //if some text on search input
        if (searchText) {
            params.search = searchText;
        }

        //if some text on search input
        if (sortField) {
            params.sortBy = sortField;
        }

        //if some text on search input
        if (sortOrder) {
            params.order = sortOrder;
        }

        var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');

        remoteUrl = `${remoteUrl}?${queryString}`;

        setTableloading(true);

        api({
            method: 'get',
            url: remoteUrl,
        }).then(response => {

            console.log("###");
            console.log(response);

            if (response.data.results.length > 0) {

                console.log(response.data.results);
                setData(response.data.results);
                setTotalSize(response.data.total);

            } else {
                setData(null);
                setTotalSize(0);
            }
            setTableloading(false);

        })
            .catch(error => {
                console.log(error);
            });

    }


    useEffect(() => {
        //Prevent reload on init because on fetch we update the page
        if (!firstInit) {
            console.log("handleTableChange INIT");
            setTableloading(true);
            setPage(page);
            fetchData(page);
        }
    }, [page])


    return (
        <div className="remote-table-wrapper">
            {(search || filters) && <Grid fluid className="remote-table-wrapper__top" >
                <Row gutter={30} >
                    {filters && <Col sm={17} className="remote-table-wrapper__top__filters">
                        <div className="label">{t("search.filters.label")}</div>
                        <Form onChange={setFilterValues} formValue={filterValues} >
                            {filterFields}
                        </Form>
                    </Col>}
                    {search && <Col xsPush={(filters) ? false : 17} md={7} className="remote-table-wrapper__top__search">
                        <SearchInput callback={setSearchText} />
                    </Col>}
                </Row>
            </Grid>}
            {(data) ?

                <>
                    <Table
                        data={data}
                        className="remote-table"
                        loading={tableloading}
                        autoHeight={true}
                        rowHeight={rowHeight}
                        wordWrap={wordWrap && wordWrap}
                    >
                        {columns.map((column, index) => {
                            console.log(column);
                            return <Column fullText={column.fullText} resizable={column.resizable} verticalAlign="middle" width={column.width} flexGrow={column.width ? false : column.flexGrow ? column.flexGrow : 1} key={index} align={column.align}>
                                <HeaderCell>{column.label}</HeaderCell>
                                {column.customCell ? column.customCell : <Cell dataKey={column.dataKey} />}
                            </Column>
                        })}
                    </Table>
                    <div className="pagination">
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={5}
                            size="xs"
                            layout={['pager']}
                            total={totalSize}
                            limitOptions={[10, 30, 50]}
                            limit={limit}
                            size="sm"
                            activePage={page}
                            onChangePage={setPage}
                        />
                    </div>
                </>
                : <IconText icon="search" title={t("search.no_results.title")} />}

        </div>
    );

};


export default RemoteTable;