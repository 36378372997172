import i18n from "locale/i18n";
import { settings } from "utils";
import { Navigate } from "react-router-dom";

import Dashboard from "@views/Dashboard/Dashboard";
import Login from "@views/Login/Login";
import Organization from "@views/Organization/Organization";
import Notifications from "@views/Notifications/Notifications";

export default [
  { path: "/", breadcrumb: i18n.t("mainMenu.dashboard"), element: <Dashboard /> },
  { path: "/login", breadcrumb: i18n.t("login.title"), element: <Login /> },
  {
    path: "/spaces",
    breadcrumb: i18n.t("projects.title"),
    element: <Dashboard />,
  },
  {
    path: "/notifications",
    breadcrumb: i18n.t("notifications.title"),
    element: <Notifications />,
    children: [
      { path: settings.notifications.types.comment.slug, element: <Notifications />, breadcrumb: settings.notifications.types.comment.label },
      { path: settings.notifications.types.assigned_task.slug, element: <Notifications />, breadcrumb: settings.notifications.types.assigned_task.label },
      { path: settings.notifications.types.mentioned.slug, element: <Notifications />, breadcrumb: settings.notifications.types.mentioned.label },
      { path: settings.notifications.types.assigned_group.slug, element: <Notifications />, breadcrumb: settings.notifications.types.assigned_group.label },
      { path: "*", element: <Navigate to="/not-found/" /> },
    ]
  },
  { path: "/organization/*", breadcrumb: i18n.t("organization.title"), element: <Organization /> },
];

