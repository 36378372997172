/***
 * This file contains all axio's settings for the application.
 */

import axios from "axios";
import { utils } from "utils/utils";

// Get access token from local storage
const accessToken = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME);

// Get refresh token from local storage
const refreshToken = localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_NAME);


const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    method: 'post',
    crossDomain: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(async config => {
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});


api.interceptors.response.use(
    response => response, error => {

        switch (error.response.status) {
            case 204:
                // Unauthorized
                break;
            case 400:
                // Error messages
                if (error.response.data.errors) {
                    error.response.data.errors.map(error => {
                        utils.notify(error.message, "error");
                    });
                } else {
                    utils.notify(error.response.data.error.message, "error");
                }

                break;
        }
    }
);

export default api;