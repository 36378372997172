import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { modal } from 'utils';
import useIcon from 'utils/hooks/useIcon';

//Components
import ModalRenameFile from '../../Modals/ModalRenameFile/ModalRenameFile';
import Icon from '@components/Icon/Icon';
import Modals from '@components/Modals/Modals';
import { Whisper, Tooltip, Button, ButtonToolbar } from 'rsuite';



const FileItem = ({ item }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { iconLoading, iconError, icon } = useIcon(item.extension)


    const deleteFile = (fileID) => {
        modal.loading(true);
        setTimeout(() => {
            alert("Deleted " + fileID);
            modal.close();
        }, 1000);
    };

    const download = (fileID) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    };


    return (

        <>

            <Modals
                id="renameFile"
                title={t("space.files.modal.rename_file")}
                content={<ModalRenameFile />}
                size="xs"
                actions={[
                    {
                        label: t("global.rename"),
                        appearance: "primary",
                        hasLoading: true,
                        callback: () => modal.setCallback("renameFile"),
                    },
                ]}
            />

            <Modals
                id="deleteFile"
                size="xs"
                type="icon"
                icon="question-mark"
                title={t("space.files.modal.delete_file")}
                content={t("space.files.modal.sure_delete_file")}
                align="center"
                actions={[
                    {
                        label: t("global.delete"),
                        appearance: "primary",
                        hasLoading: true,
                        callback: () => deleteFile(item.id),
                    },
                ]}
            />
            <div className="content-view__spaces__folders__item__file-list__item" >

                <div className="content-view__spaces__folders__item__file-list__item__icon">
                    <img src={icon} />
                </div>

                <div className="content-view__spaces__folders__item__file-list__item__data">
                    <div className="content-view__spaces__folders__item__file-list__item__data__info">
                        <div className="content-view__spac ses__folders__item__file-list__item__data__info__name">
                            <a href='#' onClick={(e) => {
                                e.preventDefault();
                                download(1);
                            }} >{item.label}</a>
                        </div>
                        <div className="content-view__spaces__folders__item__file-list__item__data__info__details">
                            <div className="content-view__spaces__folders__item__file-list__item__data__info__details__size">
                                <Whisper placement="top" trigger="hover" speaker={<Tooltip>{t("space.files.file_size")}</Tooltip>} >
                                    <div><Icon icon="file" /> {item.size}</div>
                                </Whisper>
                            </div>
                            <div className="content-view__spaces__folders__item__file-list__item__data__info__details__uploaded">
                                <Whisper placement="top" trigger="hover" speaker={<Tooltip>{t("space.files.uploaded_date")}</Tooltip>} >
                                    <div><Icon icon="calendar" /> {item.uploaded}</div>
                                </Whisper>
                            </div>
                            <div className="content-view__spaces__folders__item__file-list__item__data__info__details__file-extension">
                                <Whisper placement="top" trigger="hover" speaker={<Tooltip>{t("space.files.file_extension")}</Tooltip>} >
                                    <div><Icon icon="file" /> {item.extension}</div>
                                </Whisper>
                            </div>
                        </div>
                    </div>
                    <div className="content-view__spaces__folders__item__file-list__item__data__actions">
                        <ButtonToolbar>
                            <Whisper placement="top" trigger="hover" speaker={<Tooltip>{t("space.files.download_file")}</Tooltip>} >
                                <Button appearance="subtle" onClick={() => download(1)} loading={loading}><Icon icon="download" /></Button>
                            </Whisper>
                            <Whisper placement="top" trigger="hover" speaker={<Tooltip>{t("space.files.rename_file")}</Tooltip>} >
                                <Button appearance="subtle" onClick={() => modal.open("renameFile", {
                                    file_id: item.id
                                })}><Icon icon="rename" /></Button>
                            </Whisper>
                            <Whisper placement="top" trigger="hover" speaker={<Tooltip>{t("space.files.delete_file")}</Tooltip>} >
                                <Button appearance="subtle" onClick={() => modal.open("deleteFile", {
                                    file_id: item.id
                                })}><Icon icon="trash" /></Button>
                            </Whisper>
                        </ButtonToolbar>
                    </div>
                </div>
            </div>


        </>
    );

}

export default FileItem;

