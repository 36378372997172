import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Row, Col } from 'rsuite';

//Components
import BillingInfo from "./BillingInfo";
import CurrentPlan from "./CurrentPlan";
import PaymentInfo from "./PaymentInfo";
import BillingHistory from "./BillingHistory";
import BrowserSeo from "@components/Layout/BrowserSeo/BrowserSeo";

const Billing = () => {
    const { t } = useTranslation();
    <BrowserSeo title={t("organization.billing")} />

    return <Grid fluid className="content-view__organization__content__billing">
        <Row gutter={30}>
            <Col xs={24} sm={12} md={16}>
                <BillingInfo />
                <BillingHistory />
            </Col>
            <Col xs={24} sm={12} md={8}>
                <CurrentPlan />
                <PaymentInfo />
            </Col>
        </Row>
    </Grid>;
};

export default Billing;
