import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Highcharts, { chart } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useResizeDetector } from 'react-resize-detector';
import { useReactiveVar } from "@apollo/client";
import { global } from "stores";


const TaskCompletionTime = () => {
    const { t } = useTranslation();
    const { colors, charts } = useReactiveVar(global);
    const { width, height, resizeRef } = useResizeDetector();
    const chartRef = useRef(null);

    useEffect(() => {
        const chart = chartRef.current?.chart;
        if (chart) chart.reflow(false);
    }, [width, height]);

    const chartOptions = {
        chart: {
            type: 'column',
            height: 250
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        colors: [colors.green],
        credits: {
            enabled: false
        },
        title: {
            text: ''
        },



        xAxis: {
            categories: ['22/05', '23/05', '24/05', '25/05', '26/05', '27/05', '28/05'],
            crosshair: true
        },

        series: [
            {
                name: "Tasks",
                showInLegend: false,
                pointWidth: charts.barThickness,
                data: [15, 6, 34, 16, 3, 13, 29]
            }
        ]
    };




    return (
        <div style={{ "maxHeight": 250, "height": 250 }} ref={resizeRef}>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                ref={chartRef}
            />
        </div>
    );
};

export default TaskCompletionTime;
