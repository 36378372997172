import Workplace from "./Workplace/Workplace";

const SideFooter = () => {
  return (
    <div className="layout-sidebar__footer">
      <Workplace />
    </div>
  );
};

export default SideFooter;
