export const TRANSLATIONS_EN = {
  global: {
    cancel: "Cancel",
    delete: "Delete",
    save: "Save",
    change: "Change",
    settings: "Settings",
    edit: "Edit",
    general: "General",
    download: "Download",
    month: "Month",
    year: "Year",
    add: "Add",
    please_select: "Please select",
    rename: "Rename",
    copy_link: "Copy Link",
    more_options: "More options",
    view_all: "View all",
  },
  roles: {
    types: {
      admin: {
        label: "Administrador",
        desc: "Admins can do most things, like update settings and add other admins.",
      },
      member: {
        label: "Member",
        desc: "Members are part of the team, and can add, edit, and collaborate on all work.",
      },
      viewer: {
        label: "Viewer",
        desc: "Viewers can search through, view, and comment on your team's work, but not much else.",
      }
    }
  },
  tables: {
    name: "Name",
    groups: "Groups",
    members: "Members",
    admins: "Admins",
    actions: "Actions",
    invoice_number: "Invoice #",
    date: "Date",
    description: "Description",
    amount: "Amount"
  },
  search: {
    filters: {
      label: "Filter by"
    },
    search: "Search",
    no_results: {
      title: "Sorry! No results found :(",
      text: "Try again with different words."
    }
  },
  logout: {
    title: "Logout",
  },
  not_found: {
    title: "404",
    text: "Sorry, the page you are looking for doesn't exist.",
    go_back: "Go to dashboard"
  },
  login: {
    login_title: "Log in",
    login_text: "Log in to your account.",
    recover_title: "Recover Password",
    form_title: "Log in to your account",
    email: "Email",
    password: "Password",
    submit: "Log in",
    forgot_password: "Forgot password?",
    google_login: "Log in with Google",
    recover_title: "Reset your password",
    recover_text: "Enter the email address associated with your account and we will send you a link to reset your password."
  },
  mainMenu: {
    dashboard: "Dashboard",
    my_tasks: "My Tasks",
    reports: "Reports",
    passwords: "Passwords",
    files: "Files",
  },
  tasks: {
    add_task: "Add Task"
  },
  organization: {
    title: "Organization",
    workspaces: "Workspaces",
    organization_settings: "Organization Settings",
    team: "Team",
    groups: "Groups",
    current_plan: "Current Plan",
    billing_info: "Billing Info",
    payment_info: "Payment Info",
    change_plan: "Change plan",
    change_billing: "Change billing info",
    change_payment: "Change payment method",
    users: "Users",
    cost_user: "Cost user",
    country: "Country",
    address: "Address",
    state: "State",
    zip_code: "Zip Code",
    payer: "Payer",
    contact_email: "Contact email",
    contact_name: "Contact name",
    payment_method: "Payment Method",
    card: "Card",
    expiration: "Expiration",
    organization_ownership: "Organization ownership",
    billing_history: "Billing History",
    billing: "Billing",
    billing_tax_location: "Tax Location",
    billing_tax_no_applied: "Tax not applied.",
    billing_tax_id: "Tax ID",
    company: "Company",
    plans: "Plans",
    card_number: "Card Number",
    cardholder: "Cardholder Name",
    expiration: "Expiration Date",
    general_settings: "General Settings",
    invite: "Invite Members",
    add_members: "Add Members",
    edit_members: "Edit Member",
    members_emails: "Members Emails",
    add_group: "Add Group",
    edit_group: "Edit Group",
    group_members: "Group Members",
    group_name: "Group Name",
  },
  helpTexts: {
    add_member_space: "To add multiple emails, just press the space key after an email is inserted."
  },
  plans: {
    title: "Plans",
    starter: "Starter",
    free: "Free",
    premium: "Premium"
  },
  calendar: {
    year: "Year",
    month: "Month"
  },
  workspace: {
    switch_workspace: "Switch Workspace",
    worksapce_settings: "Workspace Settings",
    add_workspace: "Add Workspace",
    edit_workspace: "Edit Workspace",
    workspace_name: "Workspace Name"
  },
  spaces: {
    title: "Spaces",
    no_spaces: "No spaces yet...",
    loading_spaces: "Loading spaces...",
  },
  space: {
    title: "Space",
    add_space: "Add Space",
    settings: "Space Settings",
    details: "Space Details",
    edit_details: "Edit Details",
    space_details: "Space Details",
    add_members: "Add Members",
    no_owner: "No owner",
    set_me_owner: "Set me as owner",
    link_copied_clipboard: "Space link copied to clipboard.",
    modal: {
      add_space: "Add Space",
      name: "Name",
      owner: "Owner",
      description: "Description",
      sure_delete: "Are you sure you want to delete this space?",
      delete_space_title: "Delete Space"
    },
    submenu: {
      list: "List",
      board: "Board",
      reports: "Reports",
      passwords: "Accesses",
      files: "Files",
      tasks: "Tasks",
    },
    members: {
      avatar_tooltip: "Members that are involved in this space.",
      modal: {
        title: "Space Members",
        members_in_space: "members in this space",
        invite_with_email: "Invite with email",
        send_invitation: "Send Invitation",
        add_message: "Add message (optional)",
        notify_them: "Notify them when new tasks are added to this space",
        can_edit: "Can edit",
        can_edit_desc: "This member can add, edit, and delete anything in the space.",
        can_comment: "Can comment",
        can_comment_desc: "This member can comment, but can't edit anything in the space.",
        invite: "Invite",
        members: "Members"
      }
    },
    tasks: {
      add_task: "Add new task",
      column: {
        rename_section: "Rename section",
        add_section: "Add section",
        add_section_left: "Add section to left",
        add_section_right: "Add section to right",
        delete_section: "Delete section",
        modal: {
          remove_section_title: "Remove section",
          remove_section_text: "Are you sure you want to remove this section?",
        }
      },
      task: {
        assign_task: "Assign task",
        assignee: "Assignee",
        assign_me: "Assign to Me",
        due_date: "Due date",
        description: "Description",
        created_at: "Created at",
        work_time: "Work time",
        start_task: "Start task",
      },

    },
    reports: {
      completed_tasks: "Completed Tasks",
      incomplete_tasks: "Incomplete Tasks",
      overdue_tasks: "Overdue Tasks",
      total_tasks: "Total Tasks",
      incomplete_tasks_by_section: "Incomplete Tasks by Section",
      task_completion_time: "Task completion over time",
      task_completion_status: "Tasks by completion status",
      hours_over_time: "Hours over time",
      upcoming_tasks: "Upcoming tasks by assignee"
    },
    passwords: {
      add_access: "Add new access",
      edit_access: "Edit access",
      delete_access: "Delete access",
      no_data: {
        title: "No data yet...",
        text: "This space does not have any passwords. Do you want to add one?"
      },
      modal: {
        title: "Add new access",
        add_field: "Add field",
        no_fields: "No fields added yet, please add at least one.",
        field_type_title: "Field Type",
        access_type_title: "Access Type",
        access_title: "Access Title",
      }
    },
    files: {
      add_file: "Add new file",
      add_files: "Add files",
      add_folder: "Add new folder",
      file_size: "File size",
      file_type: "File type",
      file_extension: "File extension",
      uploaded_date: "Uploaded date",
      all_files: "All files",
      download_file: "Download file",
      rename_file: "Rename file",
      delete_file: "Delete file",
      rename_folder: "Rename folder",
      delete_folder: "Delete folder",
      no_data: {
        title: "No files yet...",
        text: "This space does not have any files. Do you want to add one?"
      },
      modal: {
        title: "Add new file",
        rename_file: "Rename file",
        delete_file: "Delete file",
        sure_delete_file: "Are you sure you want to delete this file?",
        sure_delete_folder: "Are you sure you want to delete this folder?",
        folder_name: "Folder name",
      }
    }
  },
  profile: {
    settings: "Profile Settings",
    your_details: "Your Details",
    firstname: "First name",
    lastname: "Last name",
    email: "Email",
    phone: "Phone",
    time_and_date: "Time and Date",
    time_zone: "Time Zone",
    date_format: "Date Format",
    duration_format: "Duration Format",
    time_format: "Time Format",
    first_day: "First Day of the Week",
    change_password: "Change Password",
    current_password: "Current Password",
    new_password: "New Password",
    confirm_password: "Confirm New Password",
    account_actions: "Account Actions",
    delete_account: "Delete Account",
    notifications: {
      title: "Notifications",
      campaigns: "Receive new features and updates.",
      reminders: "Receive reminders about your tasks.",
      comments: "Receive notifications about comments.",
      weekly_report: "Receive weekly reports.",
      monthly_report: "Receive monthly reports.",
      daily_report: "Receive daily reports.",
    },
  },
  notification: {
    user_deleted: "Your user was successfully deleted.",
    profile_settings_saved: "Your profile settings were saved.",
  },
  notifications: {
    title: "Notifications",
    no_notifications: "No notifications yet...",
    all_notifications: "All notifications",
    types: {
      comment: {
        message: "commented on a task",
        label: "Comment",
      },
      assigned_task: {
        message: "assigned a task",
        label: "Assigned Task",
      },
      mentioned: {
        message: "mentioned you on a comment",
        label: "Mentioned",
      },
      assigned_group: {
        message: "assigned you to the group",
        label: "Assigned Group",
      },
    },
  },
  modal: {
    please_wait: "Please wait...",
    sure_logout: "Are you sure you want to logout?",
    sure_delete_account: "Are you sure you want to delete you account?",
    sure_delete_workspace: "Are you sure you want to delete this workspace?",
    sure_delete_member: "Are you sure you want to delete this team member?",
    sure_delete_group: "Are you sure you want to delete this group?",
    delete_group: "Delete Group",
    delete_member: "Delete Team Member",
    delete_workspace: "Delete Workspace",
  },
  charts: {
    completed: "Completed",
    incomplete: "Incomplete",
    hours: "Hours"
  }
};
