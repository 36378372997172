import React, { useEffect, useState } from "react";
import { Popover } from 'rsuite';

const DetailsPopover = () => {

    return <div className="details-popover">
        <div className="details-popover__row">
            <div className="details-popover__row__label">Space</div>
            <div className="details-popover__row__desc">Scorepixel</div>
        </div>
        <div className="details-popover__row">
            <div className="details-popover__row__label">Task</div>
            <div className="details-popover__row__desc">Task Description will be here ahahah yeah...</div>
        </div>
    </div>;

}

export default DetailsPopover;