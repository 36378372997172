import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

//Components
import Block from "@components/Block/Block";


const CurrentPlan = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    return <Block title={t("organization.current_plan")} className="plan-block" actions={[
        {
            label: t("organization.change_plan"),
            icon: "edit",
            callback: () => navigate('/organization/plans/')
        }
    ]}>
        <div className="plan-block__plan-name">
            <span>Premium</span>
        </div>
        <div className="plan-block__plan-details">
            <div className="plan-block__plan-details__costs_users">
                <label>{t("organization.cost_user")}/{t("calendar.month")}</label>
                <div>123 USD</div>
            </div>
            <div className="plan-block__plan-details__users">
                <label>{t("organization.users")}</label>
                <div>123</div>
            </div>
        </div>
    </Block>;
}

export default CurrentPlan;