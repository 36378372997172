import React, { useState, useEffect } from "react";
import { useParams, Outlet, useLocation } from "react-router-dom";
import { set } from "immutable-modify";
import { useTranslation } from "react-i18next";
import { modal } from '@utils';

//Components
import { BrowserSeo } from "components";
import SpaceHeader from "./SpaceHeader/SpaceHeader";
import Modals from "@components/Modals/Modals";
import ModalSettings from "./Modals/ModalSettings/ModalSettings";


//Reactive variables
import { useReactiveVar } from "@apollo/client";
import { spaces } from "stores";


const Space = () => {
  let params = useParams();
  const location = useLocation();

  const { t } = useTranslation();
  const { space } = useReactiveVar(spaces);

  useEffect(() => {
    //Save current space id into store
    spaces(set(spaces(), "space.currentId", params.spaceId));
  }, []);



  return (
    <div className={`content-view__spaces view-${location.pathname.split("/").pop()}`} id={`space-${space.currentId}`}>

      <Modals
        id="updateSpaceSettings"
        title={t("space.space_details")}
        content={<ModalSettings edit />}
        actions={[
          {
            label: t("global.save"),
            appearance: "primary",
            hasLoading: true,
            callback: () => modal.setCallback("updateSpaceSettings"),
          },
        ]}
      />


      <BrowserSeo title={`Space ${space.currentId}`} />
      <SpaceHeader />
      <Outlet />
    </div >
  );
};
export default Space;
