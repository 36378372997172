import { Grid, Row, Col } from "rsuite";

const ActionHeader = ({ left, right, className }) => {

    return (
        <Row gutter={0} className={`action-header ${className}`}>
            <Col md={12} className="action-header__left">
                <div className="action-header__left__inside">{left}</div>
            </Col>
            <Col md={12} className="action-header__right">
                <div className="action-header__right__inside">{right}</div>
            </Col>
        </Row>
    );
};
export default ActionHeader;
