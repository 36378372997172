/***
 * This file contains all countries data.
 */

let countries = [
    { "name": "Afghanistan", "code": "AF", "phone": 93, "symbol": "؋", "currency": "AFN", "continent_code": "AS" },
    { "name": "Aland Islands", "code": "AX", "phone": 358, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Albania", "code": "AL", "phone": 355, "symbol": "Lek", "currency": "ALL", "continent_code": "EU" },
    { "name": "Algeria", "code": "DZ", "phone": 213, "symbol": "دج", "currency": "DZD", "continent_code": "AF" },
    { "name": "American Samoa", "code": "AS", "phone": 1684, "symbol": "$", "currency": "USD", "continent_code": "OC" },
    { "name": "Andorra", "code": "AD", "phone": 376, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Angola", "code": "AO", "phone": 244, "symbol": "Kz", "currency": "AOA", "continent_code": "AF" },
    { "name": "Anguilla", "code": "AI", "phone": 1264, "symbol": "$", "currency": "XCD", "continent_code": "NA" },
    { "name": "Antarctica", "code": "AQ", "phone": 672, "symbol": "$", "currency": "AAD", "continent_code": "AN" },
    { "name": "Antigua and Barbuda", "code": "AG", "phone": 1268, "symbol": "$", "currency": "XCD", "continent_code": "NA" },
    { "name": "Argentina", "code": "AR", "phone": 54, "symbol": "$", "currency": "ARS", "continent_code": "SA" },
    { "name": "Armenia", "code": "AM", "phone": 374, "symbol": "֏", "currency": "AMD", "continent_code": "AS" },
    { "name": "Aruba", "code": "AW", "phone": 297, "symbol": "ƒ", "currency": "AWG", "continent_code": "NA" },
    { "name": "Australia", "code": "AU", "phone": 61, "symbol": "$", "currency": "AUD", "continent_code": "OC" },
    { "name": "Austria", "code": "AT", "phone": 43, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Azerbaijan", "code": "AZ", "phone": 994, "symbol": "m", "currency": "AZN", "continent_code": "AS" },
    { "name": "Bahamas", "code": "BS", "phone": 1242, "symbol": "B$", "currency": "BSD", "continent_code": "NA" },
    { "name": "Bahrain", "code": "BH", "phone": 973, "symbol": ".د.ب", "currency": "BHD", "continent_code": "AS" },
    { "name": "Bangladesh", "code": "BD", "phone": 880, "symbol": "৳", "currency": "BDT", "continent_code": "AS" },
    { "name": "Barbados", "code": "BB", "phone": 1246, "symbol": "Bds$", "currency": "BBD", "continent_code": "NA" },
    { "name": "Belarus", "code": "BY", "phone": 375, "symbol": "Br", "currency": "BYN", "continent_code": "EU" },
    { "name": "Belgium", "code": "BE", "phone": 32, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Belize", "code": "BZ", "phone": 501, "symbol": "$", "currency": "BZD", "continent_code": "NA" },
    { "name": "Benin", "code": "BJ", "phone": 229, "symbol": "CFA", "currency": "XOF", "continent_code": "AF" },
    { "name": "Bermuda", "code": "BM", "phone": 1441, "symbol": "$", "currency": "BMD", "continent_code": "NA" },
    { "name": "Bhutan", "code": "BT", "phone": 975, "symbol": "Nu.", "currency": "BTN", "continent_code": "AS" },
    { "name": "Bolivia", "code": "BO", "phone": 591, "symbol": "Bs.", "currency": "BOB", "continent_code": "SA" },
    { "name": "Bonaire, Sint Eustatius and Saba", "code": "BQ", "phone": 599, "symbol": "$", "currency": "USD", "continent_code": "NA" },
    { "name": "Bosnia and Herzegovina", "code": "BA", "phone": 387, "symbol": "KM", "currency": "BAM", "continent_code": "EU" },
    { "name": "Botswana", "code": "BW", "phone": 267, "symbol": "P", "currency": "BWP", "continent_code": "AF" },
    { "name": "Bouvet Island", "code": "BV", "phone": 55, "symbol": "kr", "currency": "NOK", "continent_code": "AN" },
    { "name": "Brazil", "code": "BR", "phone": 55, "symbol": "R$", "currency": "BRL", "continent_code": "SA" },
    { "name": "British Indian Ocean Territory", "code": "IO", "phone": 246, "symbol": "$", "currency": "USD", "continent_code": "AS" },
    { "name": "Brunei Darussalam", "code": "BN", "phone": 673, "symbol": "B$", "currency": "BND", "continent_code": "AS" },
    { "name": "Bulgaria", "code": "BG", "phone": 359, "symbol": "Лв.", "currency": "BGN", "continent_code": "EU" },
    { "name": "Burkina Faso", "code": "BF", "phone": 226, "symbol": "CFA", "currency": "XOF", "continent_code": "AF" },
    { "name": "Burundi", "code": "BI", "phone": 257, "symbol": "FBu", "currency": "BIF", "continent_code": "AF" },
    { "name": "Cambodia", "code": "KH", "phone": 855, "symbol": "KHR", "currency": "KHR", "continent_code": "AS" },
    { "name": "Cameroon", "code": "CM", "phone": 237, "symbol": "FCFA", "currency": "XAF", "continent_code": "AF" },
    { "name": "Canada", "code": "CA", "phone": 1, "symbol": "$", "currency": "CAD", "continent_code": "NA" },
    { "name": "Cape Verde", "code": "CV", "phone": 238, "symbol": "$", "currency": "CVE", "continent_code": "AF" },
    { "name": "Cayman Islands", "code": "KY", "phone": 1345, "symbol": "$", "currency": "KYD", "continent_code": "NA" },
    { "name": "Central African Republic", "code": "CF", "phone": 236, "symbol": "FCFA", "currency": "XAF", "continent_code": "AF" },
    { "name": "Chad", "code": "TD", "phone": 235, "symbol": "FCFA", "currency": "XAF", "continent_code": "AF" },
    { "name": "Chile", "code": "CL", "phone": 56, "symbol": "$", "currency": "CLP", "continent_code": "SA" },
    { "name": "China", "code": "CN", "phone": 86, "symbol": "¥", "currency": "CNY", "continent_code": "AS" },
    { "name": "Christmas Island", "code": "CX", "phone": 61, "symbol": "$", "currency": "AUD", "continent_code": "AS" },
    { "name": "Cocos (Keeling) Islands", "code": "CC", "phone": 672, "symbol": "$", "currency": "AUD", "continent_code": "AS" },
    { "name": "Colombia", "code": "CO", "phone": 57, "symbol": "$", "currency": "COP", "continent_code": "SA" },
    { "name": "Comoros", "code": "KM", "phone": 269, "symbol": "CF", "currency": "KMF", "continent_code": "AF" },
    { "name": "Congo", "code": "CG", "phone": 242, "symbol": "FC", "currency": "XAF", "continent_code": "AF" },
    { "name": "Congo, Democratic Republic of the Congo", "code": "CD", "phone": 242, "symbol": "FC", "currency": "CDF", "continent_code": "AF" },
    { "name": "Cook Islands", "code": "CK", "phone": 682, "symbol": "$", "currency": "NZD", "continent_code": "OC" },
    { "name": "Costa Rica", "code": "CR", "phone": 506, "symbol": "₡", "currency": "CRC", "continent_code": "NA" },
    { "name": "Cote D'Ivoire", "code": "CI", "phone": 225, "symbol": "CFA", "currency": "XOF", "continent_code": "AF" },
    { "name": "Croatia", "code": "HR", "phone": 385, "symbol": "kn", "currency": "HRK", "continent_code": "EU" },
    { "name": "Cuba", "code": "CU", "phone": 53, "symbol": "$", "currency": "CUP", "continent_code": "NA" },
    { "name": "Curacao", "code": "CW", "phone": 599, "symbol": "ƒ", "currency": "ANG", "continent_code": "NA" },
    { "name": "Cyprus", "code": "CY", "phone": 357, "symbol": "€", "currency": "EUR", "continent_code": "AS" },
    { "name": "Czech Republic", "code": "CZ", "phone": 420, "symbol": "Kč", "currency": "CZK", "continent_code": "EU" },
    { "name": "Denmark", "code": "DK", "phone": 45, "symbol": "Kr.", "currency": "DKK", "continent_code": "EU" },
    { "name": "Djibouti", "code": "DJ", "phone": 253, "symbol": "Fdj", "currency": "DJF", "continent_code": "AF" },
    { "name": "Dominica", "code": "DM", "phone": 1767, "symbol": "$", "currency": "XCD", "continent_code": "NA" },
    { "name": "Dominican Republic", "code": "DO", "phone": 1809, "symbol": "$", "currency": "DOP", "continent_code": "NA" },
    { "name": "Ecuador", "code": "EC", "phone": 593, "symbol": "$", "currency": "USD", "continent_code": "SA" },
    { "name": "Egypt", "code": "EG", "phone": 20, "symbol": "ج.م", "currency": "EGP", "continent_code": "AF" },
    { "name": "El Salvador", "code": "SV", "phone": 503, "symbol": "$", "currency": "USD", "continent_code": "NA" },
    { "name": "Equatorial Guinea", "code": "GQ", "phone": 240, "symbol": "FCFA", "currency": "XAF", "continent_code": "AF" },
    { "name": "Eritrea", "code": "ER", "phone": 291, "symbol": "Nfk", "currency": "ERN", "continent_code": "AF" },
    { "name": "Estonia", "code": "EE", "phone": 372, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Ethiopia", "code": "ET", "phone": 251, "symbol": "Nkf", "currency": "ETB", "continent_code": "AF" },
    { "name": "Falkland Islands (Malvinas)", "code": "FK", "phone": 500, "symbol": "£", "currency": "FKP", "continent_code": "SA" },
    { "name": "Faroe Islands", "code": "FO", "phone": 298, "symbol": "Kr.", "currency": "DKK", "continent_code": "EU" },
    { "name": "Fiji", "code": "FJ", "phone": 679, "symbol": "FJ$", "currency": "FJD", "continent_code": "OC" },
    { "name": "Finland", "code": "FI", "phone": 358, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "France", "code": "FR", "phone": 33, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "French Guiana", "code": "GF", "phone": 594, "symbol": "€", "currency": "EUR", "continent_code": "SA" },
    { "name": "French Polynesia", "code": "PF", "phone": 689, "symbol": "₣", "currency": "XPF", "continent_code": "OC" },
    { "name": "French Southern Territories", "code": "TF", "phone": 262, "symbol": "€", "currency": "EUR", "continent_code": "AN" },
    { "name": "Gabon", "code": "GA", "phone": 241, "symbol": "FCFA", "currency": "XAF", "continent_code": "AF" },
    { "name": "Gambia", "code": "GM", "phone": 220, "symbol": "D", "currency": "GMD", "continent_code": "AF" },
    { "name": "Georgia", "code": "GE", "phone": 995, "symbol": "ლ", "currency": "GEL", "continent_code": "AS" },
    { "name": "Germany", "code": "DE", "phone": 49, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Ghana", "code": "GH", "phone": 233, "symbol": "GH₵", "currency": "GHS", "continent_code": "AF" },
    { "name": "Gibraltar", "code": "GI", "phone": 350, "symbol": "£", "currency": "GIP", "continent_code": "EU" },
    { "name": "Greece", "code": "GR", "phone": 30, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Greenland", "code": "GL", "phone": 299, "symbol": "Kr.", "currency": "DKK", "continent_code": "NA" },
    { "name": "Grenada", "code": "GD", "phone": 1473, "symbol": "$", "currency": "XCD", "continent_code": "NA" },
    { "name": "Guadeloupe", "code": "GP", "phone": 590, "symbol": "€", "currency": "EUR", "continent_code": "NA" },
    { "name": "Guam", "code": "GU", "phone": 1671, "symbol": "$", "currency": "USD", "continent_code": "OC" },
    { "name": "Guatemala", "code": "GT", "phone": 502, "symbol": "Q", "currency": "GTQ", "continent_code": "NA" },
    { "name": "Guernsey", "code": "GG", "phone": 44, "symbol": "£", "currency": "GBP", "continent_code": "EU" },
    { "name": "Guinea", "code": "GN", "phone": 224, "symbol": "FG", "currency": "GNF", "continent_code": "AF" },
    { "name": "Guinea-Bissau", "code": "GW", "phone": 245, "symbol": "CFA", "currency": "XOF", "continent_code": "AF" },
    { "name": "Guyana", "code": "GY", "phone": 592, "symbol": "$", "currency": "GYD", "continent_code": "SA" },
    { "name": "Haiti", "code": "HT", "phone": 509, "symbol": "G", "currency": "HTG", "continent_code": "NA" },
    { "name": "Heard Island and Mcdonald Islands", "code": "HM", "phone": 0, "symbol": "$", "currency": "AUD", "continent_code": "AN" },
    { "name": "Holy See (Vatican City State)", "code": "VA", "phone": 39, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Honduras", "code": "HN", "phone": 504, "symbol": "L", "currency": "HNL", "continent_code": "NA" },
    { "name": "Hong Kong", "code": "HK", "phone": 852, "symbol": "$", "currency": "HKD", "continent_code": "AS" },
    { "name": "Hungary", "code": "HU", "phone": 36, "symbol": "Ft", "currency": "HUF", "continent_code": "EU" },
    { "name": "Iceland", "code": "IS", "phone": 354, "symbol": "kr", "currency": "ISK", "continent_code": "EU" },
    { "name": "India", "code": "IN", "phone": 91, "symbol": "₹", "currency": "INR", "continent_code": "AS" },
    { "name": "Indonesia", "code": "ID", "phone": 62, "symbol": "Rp", "currency": "IDR", "continent_code": "AS" },
    { "name": "Iran, Islamic Republic of", "code": "IR", "phone": 98, "symbol": "﷼", "currency": "IRR", "continent_code": "AS" },
    { "name": "Iraq", "code": "IQ", "phone": 964, "symbol": "د.ع", "currency": "IQD", "continent_code": "AS" },
    { "name": "Ireland", "code": "IE", "phone": 353, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Isle of Man", "code": "IM", "phone": 44, "symbol": "£", "currency": "GBP", "continent_code": "EU" },
    { "name": "Israel", "code": "IL", "phone": 972, "symbol": "₪", "currency": "ILS", "continent_code": "AS" },
    { "name": "Italy", "code": "IT", "phone": 39, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Jamaica", "code": "JM", "phone": 1876, "symbol": "J$", "currency": "JMD", "continent_code": "NA" },
    { "name": "Japan", "code": "JP", "phone": 81, "symbol": "¥", "currency": "JPY", "continent_code": "AS" },
    { "name": "Jersey", "code": "JE", "phone": 44, "symbol": "£", "currency": "GBP", "continent_code": "EU" },
    { "name": "Jordan", "code": "JO", "phone": 962, "symbol": "ا.د", "currency": "JOD", "continent_code": "AS" },
    { "name": "Kazakhstan", "code": "KZ", "phone": 7, "symbol": "лв", "currency": "KZT", "continent_code": "AS" },
    { "name": "Kenya", "code": "KE", "phone": 254, "symbol": "KSh", "currency": "KES", "continent_code": "AF" },
    { "name": "Kiribati", "code": "KI", "phone": 686, "symbol": "$", "currency": "AUD", "continent_code": "OC" },
    { "name": "Korea, Democratic People's Republic of", "code": "KP", "phone": 850, "symbol": "₩", "currency": "KPW", "continent_code": "AS" },
    { "name": "Korea, Republic of", "code": "KR", "phone": 82, "symbol": "₩", "currency": "KRW", "continent_code": "AS" },
    { "name": "Kosovo", "code": "XK", "phone": 381, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Kuwait", "code": "KW", "phone": 965, "symbol": "ك.د", "currency": "KWD", "continent_code": "AS" },
    { "name": "Kyrgyzstan", "code": "KG", "phone": 996, "symbol": "лв", "currency": "KGS", "continent_code": "AS" },
    { "name": "Lao People's Democratic Republic", "code": "LA", "phone": 856, "symbol": "₭", "currency": "LAK", "continent_code": "AS" },
    { "name": "Latvia", "code": "LV", "phone": 371, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Lebanon", "code": "LB", "phone": 961, "symbol": "£", "currency": "LBP", "continent_code": "AS" },
    { "name": "Lesotho", "code": "LS", "phone": 266, "symbol": "L", "currency": "LSL", "continent_code": "AF" },
    { "name": "Liberia", "code": "LR", "phone": 231, "symbol": "$", "currency": "LRD", "continent_code": "AF" },
    { "name": "Libyan Arab Jamahiriya", "code": "LY", "phone": 218, "symbol": "د.ل", "currency": "LYD", "continent_code": "AF" },
    { "name": "Liechtenstein", "code": "LI", "phone": 423, "symbol": "CHf", "currency": "CHF", "continent_code": "EU" },
    { "name": "Lithuania", "code": "LT", "phone": 370, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Luxembourg", "code": "LU", "phone": 352, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Macao", "code": "MO", "phone": 853, "symbol": "$", "currency": "MOP", "continent_code": "AS" },
    { "name": "Macedonia, the Former Yugoslav Republic of", "code": "MK", "phone": 389, "symbol": "ден", "currency": "MKD", "continent_code": "EU" },
    { "name": "Madagascar", "code": "MG", "phone": 261, "symbol": "Ar", "currency": "MGA", "continent_code": "AF" },
    { "name": "Malawi", "code": "MW", "phone": 265, "symbol": "MK", "currency": "MWK", "continent_code": "AF" },
    { "name": "Malaysia", "code": "MY", "phone": 60, "symbol": "RM", "currency": "MYR", "continent_code": "AS" },
    { "name": "Maldives", "code": "MV", "phone": 960, "symbol": "Rf", "currency": "MVR", "continent_code": "AS" },
    { "name": "Mali", "code": "ML", "phone": 223, "symbol": "CFA", "currency": "XOF", "continent_code": "AF" },
    { "name": "Malta", "code": "MT", "phone": 356, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Marshall Islands", "code": "MH", "phone": 692, "symbol": "$", "currency": "USD", "continent_code": "OC" },
    { "name": "Martinique", "code": "MQ", "phone": 596, "symbol": "€", "currency": "EUR", "continent_code": "NA" },
    { "name": "Mauritania", "code": "MR", "phone": 222, "symbol": "MRU", "currency": "MRO", "continent_code": "AF" },
    { "name": "Mauritius", "code": "MU", "phone": 230, "symbol": "₨", "currency": "MUR", "continent_code": "AF" },
    { "name": "Mayotte", "code": "YT", "phone": 269, "symbol": "€", "currency": "EUR", "continent_code": "AF" },
    { "name": "Mexico", "code": "MX", "phone": 52, "symbol": "$", "currency": "MXN", "continent_code": "NA" },
    { "name": "Micronesia, Federated States of", "code": "FM", "phone": 691, "symbol": "$", "currency": "USD", "continent_code": "OC" },
    { "name": "Moldova, Republic of", "code": "MD", "phone": 373, "symbol": "L", "currency": "MDL", "continent_code": "EU" },
    { "name": "Monaco", "code": "MC", "phone": 377, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Mongolia", "code": "MN", "phone": 976, "symbol": "₮", "currency": "MNT", "continent_code": "AS" },
    { "name": "Montenegro", "code": "ME", "phone": 382, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Montserrat", "code": "MS", "phone": 1664, "symbol": "$", "currency": "XCD", "continent_code": "NA" },
    { "name": "Morocco", "code": "MA", "phone": 212, "symbol": "DH", "currency": "MAD", "continent_code": "AF" },
    { "name": "Mozambique", "code": "MZ", "phone": 258, "symbol": "MT", "currency": "MZN", "continent_code": "AF" },
    { "name": "Myanmar", "code": "MM", "phone": 95, "symbol": "K", "currency": "MMK", "continent_code": "AS" },
    { "name": "Namibia", "code": "NA", "phone": 264, "symbol": "$", "currency": "NAD", "continent_code": "AF" },
    { "name": "Nauru", "code": "NR", "phone": 674, "symbol": "$", "currency": "AUD", "continent_code": "OC" },
    { "name": "Nepal", "code": "NP", "phone": 977, "symbol": "₨", "currency": "NPR", "continent_code": "AS" },
    { "name": "Netherlands", "code": "NL", "phone": 31, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Netherlands Antilles", "code": "AN", "phone": 599, "symbol": "NAf", "currency": "ANG", "continent_code": "NA" },
    { "name": "New Caledonia", "code": "NC", "phone": 687, "symbol": "₣", "currency": "XPF", "continent_code": "OC" },
    { "name": "New Zealand", "code": "NZ", "phone": 64, "symbol": "$", "currency": "NZD", "continent_code": "OC" },
    { "name": "Nicaragua", "code": "NI", "phone": 505, "symbol": "C$", "currency": "NIO", "continent_code": "NA" },
    { "name": "Niger", "code": "NE", "phone": 227, "symbol": "CFA", "currency": "XOF", "continent_code": "AF" },
    { "name": "Nigeria", "code": "NG", "phone": 234, "symbol": "₦", "currency": "NGN", "continent_code": "AF" },
    { "name": "Niue", "code": "NU", "phone": 683, "symbol": "$", "currency": "NZD", "continent_code": "OC" },
    { "name": "Norfolk Island", "code": "NF", "phone": 672, "symbol": "$", "currency": "AUD", "continent_code": "OC" },
    { "name": "Northern Mariana Islands", "code": "MP", "phone": 1670, "symbol": "$", "currency": "USD", "continent_code": "OC" },
    { "name": "Norway", "code": "NO", "phone": 47, "symbol": "kr", "currency": "NOK", "continent_code": "EU" },
    { "name": "Oman", "code": "OM", "phone": 968, "symbol": ".ع.ر", "currency": "OMR", "continent_code": "AS" },
    { "name": "Pakistan", "code": "PK", "phone": 92, "symbol": "₨", "currency": "PKR", "continent_code": "AS" },
    { "name": "Palau", "code": "PW", "phone": 680, "symbol": "$", "currency": "USD", "continent_code": "OC" },
    { "name": "Palestinian Territory, Occupied", "code": "PS", "phone": 970, "symbol": "₪", "currency": "ILS", "continent_code": "AS" },
    { "name": "Panama", "code": "PA", "phone": 507, "symbol": "B/.", "currency": "PAB", "continent_code": "NA" },
    { "name": "Papua New Guinea", "code": "PG", "phone": 675, "symbol": "K", "currency": "PGK", "continent_code": "OC" },
    { "name": "Paraguay", "code": "PY", "phone": 595, "symbol": "₲", "currency": "PYG", "continent_code": "SA" },
    { "name": "Peru", "code": "PE", "phone": 51, "symbol": "S/.", "currency": "PEN", "continent_code": "SA" },
    { "name": "Philippines", "code": "PH", "phone": 63, "symbol": "₱", "currency": "PHP", "continent_code": "AS" },
    { "name": "Pitcairn", "code": "PN", "phone": 64, "symbol": "$", "currency": "NZD", "continent_code": "OC" },
    { "name": "Poland", "code": "PL", "phone": 48, "symbol": "zł", "currency": "PLN", "continent_code": "EU" },
    { "name": "Portugal", "code": "PT", "phone": 351, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Puerto Rico", "code": "PR", "phone": 1787, "symbol": "$", "currency": "USD", "continent_code": "NA" },
    { "name": "Qatar", "code": "QA", "phone": 974, "symbol": "ق.ر", "currency": "QAR", "continent_code": "AS" },
    { "name": "Reunion", "code": "RE", "phone": 262, "symbol": "€", "currency": "EUR", "continent_code": "AF" },
    { "name": "Romania", "code": "RO", "phone": 40, "symbol": "lei", "currency": "RON", "continent_code": "EU" },
    { "name": "Russian Federation", "code": "RU", "phone": 70, "symbol": "₽", "currency": "RUB", "continent_code": "AS" },
    { "name": "Rwanda", "code": "RW", "phone": 250, "symbol": "FRw", "currency": "RWF", "continent_code": "AF" },
    { "name": "Saint Barthelemy", "code": "BL", "phone": 590, "symbol": "€", "currency": "EUR", "continent_code": "NA" },
    { "name": "Saint Helena", "code": "SH", "phone": 290, "symbol": "£", "currency": "SHP", "continent_code": "AF" },
    { "name": "Saint Kitts and Nevis", "code": "KN", "phone": 1869, "symbol": "$", "currency": "XCD", "continent_code": "NA" },
    { "name": "Saint Lucia", "code": "LC", "phone": 1758, "symbol": "$", "currency": "XCD", "continent_code": "NA" },
    { "name": "Saint Martin", "code": "MF", "phone": 590, "symbol": "€", "currency": "EUR", "continent_code": "NA" },
    { "name": "Saint Pierre and Miquelon", "code": "PM", "phone": 508, "symbol": "€", "currency": "EUR", "continent_code": "NA" },
    { "name": "Saint Vincent and the Grenadines", "code": "VC", "phone": 1784, "symbol": "$", "currency": "XCD", "continent_code": "NA" },
    { "name": "Samoa", "code": "WS", "phone": 684, "symbol": "SAT", "currency": "WST", "continent_code": "OC" },
    { "name": "San Marino", "code": "SM", "phone": 378, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Sao Tome and Principe", "code": "ST", "phone": 239, "symbol": "Db", "currency": "STD", "continent_code": "AF" },
    { "name": "Saudi Arabia", "code": "SA", "phone": 966, "symbol": "﷼", "currency": "SAR", "continent_code": "AS" },
    { "name": "Senegal", "code": "SN", "phone": 221, "symbol": "CFA", "currency": "XOF", "continent_code": "AF" },
    { "name": "Serbia", "code": "RS", "phone": 381, "symbol": "din", "currency": "RSD", "continent_code": "EU" },
    { "name": "Serbia and Montenegro", "code": "CS", "phone": 381, "symbol": "din", "currency": "RSD", "continent_code": "EU" },
    { "name": "Seychelles", "code": "SC", "phone": 248, "symbol": "SRe", "currency": "SCR", "continent_code": "AF" },
    { "name": "Sierra Leone", "code": "SL", "phone": 232, "symbol": "Le", "currency": "SLL", "continent_code": "AF" },
    { "name": "Singapore", "code": "SG", "phone": 65, "symbol": "$", "currency": "SGD", "continent_code": "AS" },
    { "name": "Sint Maarten", "code": "SX", "phone": 1, "symbol": "ƒ", "currency": "ANG", "continent_code": "NA" },
    { "name": "Slovakia", "code": "SK", "phone": 421, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Slovenia", "code": "SI", "phone": 386, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Solomon Islands", "code": "SB", "phone": 677, "symbol": "Si$", "currency": "SBD", "continent_code": "OC" },
    { "name": "Somalia", "code": "SO", "phone": 252, "symbol": "Sh.so.", "currency": "SOS", "continent_code": "AF" },
    { "name": "South Africa", "code": "ZA", "phone": 27, "symbol": "R", "currency": "ZAR", "continent_code": "AF" },
    { "name": "South Georgia and the South Sandwich Islands", "code": "GS", "phone": 500, "symbol": "£", "currency": "GBP", "continent_code": "AN" },
    { "name": "South Sudan", "code": "SS", "phone": 211, "symbol": "£", "currency": "SSP", "continent_code": "AF" },
    { "name": "Spain", "code": "ES", "phone": 34, "symbol": "€", "currency": "EUR", "continent_code": "EU" },
    { "name": "Sri Lanka", "code": "LK", "phone": 94, "symbol": "Rs", "currency": "LKR", "continent_code": "AS" },
    { "name": "Sudan", "code": "SD", "phone": 249, "symbol": ".س.ج", "currency": "SDG", "continent_code": "AF" },
    { "name": "Suriname", "code": "SR", "phone": 597, "symbol": "$", "currency": "SRD", "continent_code": "SA" },
    { "name": "Svalbard and Jan Mayen", "code": "SJ", "phone": 47, "symbol": "kr", "currency": "NOK", "continent_code": "EU" },
    { "name": "Swaziland", "code": "SZ", "phone": 268, "symbol": "E", "currency": "SZL", "continent_code": "AF" },
    { "name": "Sweden", "code": "SE", "phone": 46, "symbol": "kr", "currency": "SEK", "continent_code": "EU" },
    { "name": "Switzerland", "code": "CH", "phone": 41, "symbol": "CHf", "currency": "CHF", "continent_code": "EU" },
    { "name": "Syrian Arab Republic", "code": "SY", "phone": 963, "symbol": "LS", "currency": "SYP", "continent_code": "AS" },
    { "name": "Taiwan, Province of China", "code": "TW", "phone": 886, "symbol": "$", "currency": "TWD", "continent_code": "AS" },
    { "name": "Tajikistan", "code": "TJ", "phone": 992, "symbol": "SM", "currency": "TJS", "continent_code": "AS" },
    { "name": "Tanzania, United Republic of", "code": "TZ", "phone": 255, "symbol": "TSh", "currency": "TZS", "continent_code": "AF" },
    { "name": "Thailand", "code": "TH", "phone": 66, "symbol": "฿", "currency": "THB", "continent_code": "AS" },
    { "name": "Timor-Leste", "code": "TL", "phone": 670, "symbol": "$", "currency": "USD", "continent_code": "AS" },
    { "name": "Togo", "code": "TG", "phone": 228, "symbol": "CFA", "currency": "XOF", "continent_code": "AF" },
    { "name": "Tokelau", "code": "TK", "phone": 690, "symbol": "$", "currency": "NZD", "continent_code": "OC" },
    { "name": "Tonga", "code": "TO", "phone": 676, "symbol": "$", "currency": "TOP", "continent_code": "OC" },
    { "name": "Trinidad and Tobago", "code": "TT", "phone": 1868, "symbol": "$", "currency": "TTD", "continent_code": "NA" },
    { "name": "Tunisia", "code": "TN", "phone": 216, "symbol": "ت.د", "currency": "TND", "continent_code": "AF" },
    { "name": "Turkey", "code": "TR", "phone": 90, "symbol": "₺", "currency": "TRY", "continent_code": "AS" },
    { "name": "Turkmenistan", "code": "TM", "phone": 7370, "symbol": "T", "currency": "TMT", "continent_code": "AS" },
    { "name": "Turks and Caicos Islands", "code": "TC", "phone": 1649, "symbol": "$", "currency": "USD", "continent_code": "NA" },
    { "name": "Tuvalu", "code": "TV", "phone": 688, "symbol": "$", "currency": "AUD", "continent_code": "OC" },
    { "name": "Uganda", "code": "UG", "phone": 256, "symbol": "USh", "currency": "UGX", "continent_code": "AF" },
    { "name": "Ukraine", "code": "UA", "phone": 380, "symbol": "₴", "currency": "UAH", "continent_code": "EU" },
    { "name": "United Arab Emirates", "code": "AE", "phone": 971, "symbol": "إ.د", "currency": "AED", "continent_code": "AS" },
    { "name": "United Kingdom", "code": "GB", "phone": 44, "symbol": "£", "currency": "GBP", "continent_code": "EU" },
    { "name": "United States", "code": "US", "phone": 1, "symbol": "$", "currency": "USD", "continent_code": "NA" },
    { "name": "United States Minor Outlying Islands", "code": "UM", "phone": 1, "symbol": "$", "currency": "USD", "continent_code": "NA" },
    { "name": "Uruguay", "code": "UY", "phone": 598, "symbol": "$", "currency": "UYU", "continent_code": "SA" },
    { "name": "Uzbekistan", "code": "UZ", "phone": 998, "symbol": "лв", "currency": "UZS", "continent_code": "AS" },
    { "name": "Vanuatu", "code": "VU", "phone": 678, "symbol": "VT", "currency": "VUV", "continent_code": "OC" },
    { "name": "Venezuela", "code": "VE", "phone": 58, "symbol": "Bs", "currency": "VEF", "continent_code": "SA" },
    { "name": "Viet Nam", "code": "VN", "phone": 84, "symbol": "₫", "currency": "VND", "continent_code": "AS" },
    { "name": "Virgin Islands, British", "code": "VG", "phone": 1284, "symbol": "$", "currency": "USD", "continent_code": "NA" },
    { "name": "Virgin Islands, U.s.", "code": "VI", "phone": 1340, "symbol": "$", "currency": "USD", "continent_code": "NA" },
    { "name": "Wallis and Futuna", "code": "WF", "phone": 681, "symbol": "₣", "currency": "XPF", "continent_code": "OC" },
    { "name": "Western Sahara", "code": "EH", "phone": 212, "symbol": "MAD", "currency": "MAD", "continent_code": "AF" },
    { "name": "Yemen", "code": "YE", "phone": 967, "symbol": "﷼", "currency": "YER", "continent_code": "AS" },
    { "name": "Zambia", "code": "ZM", "phone": 260, "symbol": "ZK", "currency": "ZMW", "continent_code": "AF" },
    { "name": "Zimbabwe", "code": "ZW", "phone": 263, "symbol": "$", "currency": "ZWL", "continent_code": "AF" }
];
export default countries;