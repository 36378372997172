import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { utils, modal, settings } from "utils";

//Components
import IconText from "@components/IconText/IconText";
import IconCard from "@components/IconCard/IconCard";
import SidebarList from "@components/SidebarList/SidebarList";
import Content from "@components/Layout/Content/Content";
import Modals from "@components/Modals/Modals";
import { Row, Col } from "rsuite";
import ModalAddPassword from "./Modals/ModalAddPassword";


let classNames = require("classnames");

const SpaceAccesses = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { accessId, spaceId } = useParams();

    const passwords = [
        {
            id: 1,
            title: "123RF",
            type: "email_account",
            fields: [
                {
                    label: "Email",
                    value: "mail@email.com",
                    type: "email"
                },
            ]
        },
        {
            id: 2,
            title: "1password",
            type: "password",
            fields: [
                {
                    label: "Email",
                    value: "mail@email.com",
                    type: "email"
                },
            ]
        },
        {
            id: 3,
            title: "123RF",
            type: "server",
            fields: [
                {
                    label: "Email",
                    value: "mail@email.com",
                    type: "email"
                },
            ]
        },
        {
            id: 4,
            title: "1password",
            type: "api_credentials",
            fields: [
                {
                    label: "Email",
                    value: "mail@email.com",
                    type: "email"
                },
            ]
        },
    ];



    useEffect(() => {
        //Redirect to first password if no password is selected
        if (passwords.length > 0 && accessId === undefined) {
            let redirect = `/space/${spaceId}/accesses/${passwords[0].id}`;
            navigate(redirect);
        }
    })

    const PasswordItem = (props) => {

        let accessLinkClass = classNames({
            active: props.item.id === accessId,
        });

        return <IconCard
            icon={settings.spaces.accesses.icon_types[props.item.type]}
            title={props.item.title}
            subtitle={utils.findObjectByKey(settings.spaces.accesses.types, 'value', props.item.type)['label']}
            url={`/space/${spaceId}/accesses/${props.item.id}`}
            className={accessLinkClass}
        />

    };



    return <>

        <Modals
            id="addPassword"
            title={t("space.passwords.add_access")}
            content={<ModalAddPassword />}
            actions={[
                {
                    label: t("global.add"),
                    appearance: "primary",
                    hasLoading: true,
                    callback: () => modal.setCallback("addPassword"),
                },
            ]}
        />

        <Content
            seo={
                {
                    title: t("organization.title"),
                }
            }
            sidebar={

                passwords.length > 0 && <SidebarList
                    searchFields={["title"]}
                    searchBtns={[
                        {
                            tooltip: t("space.passwords.add_access"),
                            callback: () => modal.open("addPassword"),
                            icon: "plus"
                        }
                    ]}
                    data={passwords}
                    listComponent={<PasswordItem />}
                />

            }
            className="content-view__spaces__passwords"
        >
            {passwords.length > 0 && <Outlet />}

            {passwords.length === 0 &&
                <Row gutter={30} >
                    <Col md={24} className="content-view__spaces__passwords__no-data">
                        <IconText icon="password" title={t("space.passwords.no_data.title")} text={t("space.passwords.no_data.text")} className="md" button={{
                            label: t("space.passwords.add_access"),
                            callback: () => modal.open("addPassword"),
                            appearance: "primary"
                        }} />
                    </Col>
                </Row>
            }
        </Content>


    </>;
}
export default SpaceAccesses;
