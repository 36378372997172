import React, { forwardRef } from "react";
//Components
import { Button, Dropdown, Whisper, Popover } from "rsuite";
import Icon from "components/Icon/Icon";

const DropdownIcon = forwardRef(({ icon, customIcon, appearance, options, placement, title, trigger, onOpen, onClose }, ref) => {
    const renderEllipsisIcon = (props, ref) => {
        return (
            <Button {...props} ref={ref} appearance={appearance}>
                {icon && <Icon icon={icon} />}
                {customIcon && customIcon}
            </Button>
        );
    };

    const DropItem = ({ icon, to, as, onClick, label, onClose, danger }) => {
        return (
            <Dropdown.Item
                icon={icon && <Icon icon={icon} />}
                to={to && to}
                as={as && as}
                onClick={(e) => {
                    onClose();
                    if (onClick) {
                        onClick(e);
                    }
                }}
            >
                {label}
            </Dropdown.Item>
        );
    };

    const renderDropdown = ({ onClose, left, top, className, ...rest }, ref) => {
        return (
            <Popover ref={ref} className={className + " dropdown-icon"} style={{ left, top }} full>
                <Dropdown.Menu>
                    {title && <Dropdown.Item panel>{title}</Dropdown.Item>}
                    {options.map((option) => {
                        if (!option.submenu) {
                            return (
                                <DropItem
                                    icon={option.icon}
                                    to={option.to}
                                    as={option.as}
                                    onClick={option.onClick}
                                    label={option.label}
                                    onClose={onClose}
                                />
                            );
                        } else {
                            return (
                                <Dropdown.Menu title={option.label} icon={option.icon && <Icon icon={option.icon} />}>
                                    {option.submenu.map((suboption) => {
                                        return (
                                            <DropItem
                                                icon={suboption.icon}
                                                to={suboption.to}
                                                as={suboption.as}
                                                onClick={suboption.onClick}
                                                label={suboption.label}
                                                onClose={onClose}
                                            />
                                        );
                                    })}
                                </Dropdown.Menu>
                            );
                        }
                    })}
                </Dropdown.Menu>
            </Popover>
        );
    };

    return (
        <Whisper onOpen={() => onOpen && onOpen()} onClose={() => onClose && onClose()} placement={placement} trigger={trigger ? trigger : "click"} speaker={renderDropdown}>
            {renderEllipsisIcon()}
        </Whisper>
    );
});

export default DropdownIcon;
