
import { useTranslation } from "react-i18next";
import { Row, Col } from 'rsuite';
import ModalGeneralSettings from './Modals/GeneralSettings/ModalGeneralSettings'
import { modal } from 'utils';

//Components
import Block from "@components/Block/Block";
import Modals from "@components/Modals/Modals";

const General = () => {
    const { t } = useTranslation();

    return <>

        <Modals
            id="generalSettings"
            size="xs"
            title={t("organization.general_settings")}
            content={<ModalGeneralSettings />}
            actions={[
                {
                    label: t("global.save"),
                    appearance: "primary",
                    hasLoading: true,
                    callback: () => modal.setCallback("generalSettings"),
                },
            ]}
        />

        <Block title={t("global.general")} className="general-block" actions={[
            {
                label: t("organization.change_payment"),
                icon: "edit",
                callback: () => modal.open("generalSettings")
            }
        ]}>
            <Row gutter={30}>
                <Col xs={12}>
                    <dl>
                        <dt>{t('tables.name')}</dt>
                        <dd>Scorepixel</dd>
                    </dl>
                </Col>
                <Col xs={12}>
                    <dl>
                        <dt>{t('organization.organization_ownership')}</dt>
                        <dd>Bruno Santos</dd>
                    </dl>
                </Col>
            </Row>
        </Block>
    </>;
}

export default General;