import { Schema } from "rsuite";

export const auth = {

    login: Schema.Model({
        email: Schema.Types.StringType().isRequired('This field is required.'),
        password: Schema.Types.StringType().isRequired('This field is required.'),
    }),

    recover: Schema.Model({
        "password-recover": Schema.Types.StringType().isRequired('This field is required.'),
    })

}