import React from 'react';

//Components
import Icon from 'components/Icon/Icon';
import { IconButton, Whisper, Tooltip, ButtonToolbar } from 'rsuite';


const IconTitle = ({ subtitle, title, icon, actions }) => {

    return (
        <div className='icon-title'>
            <div className='icon-title__title'>
                <Icon icon={icon} />
                <div>
                    <div className="title">{title}</div>
                    <div className="subtitle">{subtitle}</div>
                </div>
            </div>
            {actions &&
                <div className='icon-title__actions'>
                    <ButtonToolbar>
                        {actions && actions.map((btn, index) => (
                            <Whisper
                                key={index}
                                placement="top"
                                trigger="hover"
                                speaker={
                                    <Tooltip>{btn.tooltip}</Tooltip>
                                }
                            >
                                <IconButton icon={<Icon icon={btn.icon} />} appearance="ghost" onClick={() => btn.callback()} />
                            </Whisper>
                        ))}
                    </ButtonToolbar>
                </div>
            }
        </div>
    );
}

export default IconTitle;