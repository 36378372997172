import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { modal } from "utils";

//Components
import SearchList from "@components/Search/SearchList";
import SearchInput from "@components/Search/SearchInput";
import AvatarLink from "@components/AvatarLink/AvatarLink";
import Icon from "@components/Icon/Icon";
import Loading from "components/Loading/Loading";
import { IconButton, Tooltip, Whisper, Button } from "rsuite";

//Images


const Spaces = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let spaces = [
    {
      id: 1,
      name: "The Microsoft Corporation The Microsoft Corporation The Microsoft Corporation The Microsoft Corporation",
      avatar: "https://avatars.githubusercontent.com/u/10924138",
      badge: 7,
    },
    {
      id: 2,
      name: "Scorepixel",
      avatar: "",
      badge: 0,
    },
    {
      id: 3,
      name: "Mindk",
      avatar: "https://avatars.githubusercontent.com/u/2797600",
      badge: 2,
    },
    {
      id: 4,
      name: "The Microsoft Corporation",
      avatar: "https://avatars.githubusercontent.com/u/10924138",
      badge: 7,
    },
    {
      id: 5,
      name: "Scorepixel",
      avatar: "",
      badge: 0,
    },
    {
      id: 6,
      name: "Mindk",
      avatar: "https://avatars.githubusercontent.com/u/2797600",
      badge: 2,
    },
    {
      id: 7,
      name: "The Microsoft Corporation",
      avatar: "https://avatars.githubusercontent.com/u/10924138",
      badge: 7,
    },
    {
      id: 8,
      name: "Scorepixel",
      avatar: "",
      badge: 0,
    },
    {
      id: 9,
      name: "Mindk",
      avatar: "https://avatars.githubusercontent.com/u/2797600",
      badge: 2,
    },
    {
      id: 10,
      name: "Scorepixel",
      avatar: "",
      badge: 0,
    },
    {
      id: 11,
      name: "Mindk",
      avatar: "https://avatars.githubusercontent.com/u/2797600",
      badge: 2,
    },
  ];

  useEffect(() => {
    console.log("Spaces mounted");
  });

  return (
    <div className="widget widget__spaces-wrapper">
      <div className="widget__name">
        <span>{t("spaces.title")}</span>
        <Whisper placement="right" trigger="hover" speaker={<Tooltip>{t("space.add_space")}</Tooltip>}>
          <IconButton size="xs" icon={<Icon icon="plus" />} onClick={() => modal.open("addSpace")} />
        </Whisper>
      </div>
      {isLoading && (
        <div className="widget__loading">
          <Loading size="md" label={t("spaces.loading_spaces")} />
        </div>
      )}
      {(spaces.length === 0 && !isLoading) && (
        <div className="widget__no-spaces">
          <Icon icon="projects" />
          <span>{t("spaces.no_spaces")}</span>
          <Button appearance="primary" size="sm" onClick={() => modal.open("addSpace")}>{t("space.add_space")}</Button>
        </div>
      )}
      {(spaces.length > 0 && !isLoading) && (
        <>
          <div className="widget__search">
            <SearchInput callback={setSearchText} />
          </div>
          <div className="widget__spaces">
            <SearchList search={searchText} data={spaces} fields={["name", "id"]}>
              <AvatarLink dark linkTo="/space/[id]/tasks" breadcrumbState={{ key: "projectName", value: "name" }} />
            </SearchList>
          </div>
        </>
      )}
    </div>
  );
};

export default Spaces;
