import { auth } from "./auth";
import { organization } from "./organization";
import { project } from "./project";
import { space } from "./space";
import { profile } from "./profile";

export const schemas = {

    auth: auth,

    organization: organization,

    project: project,

    space: space,

    profile: profile

}