import { Uploader as Upload, Placeholder } from "rsuite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Components
import Icon from "@components/Icon/Icon";

const Uploader = ({ show }) => {

    const { t } = useTranslation();
    const [placeholders, setPlaceholders] = useState([]);
    const [finishedUploads, setFinishedUploads] = useState(0);


    return show ?
        <div className="content-view__spaces__folders__item__uploader" >
            <Upload action="https://jsonplaceholder.typicode.com/posts/"
                draggable
                fileListVisible={false}
                className="upload-box"
                onSuccess={(data, file) => {
                    setPlaceholders(placeholders);
                    setFinishedUploads(finishedUploads + 1);
                }}
                onChange={(file) => {

                    setPlaceholders(file.length - finishedUploads);
                }}
            >
                <div className="label">
                    <Icon icon="upload" />
                    <span>Click or Drag files to this area to upload</span>
                </div>
            </Upload>
            <div className="content-view__spaces__folders__item__uploader__placeholders">
                {placeholders > 0 && //loop number 
                    Array(placeholders).fill(0).map((_, i) => {
                        return <Placeholder.Paragraph key={i} graph="square" active />
                    })
                }
            </div>
        </div >
        : null;
}

export default Uploader;