import React from "react";
import { useTranslation } from "react-i18next";
import { modal, utils } from 'utils';

//Stores
import { spaces } from 'stores';
import { useReactiveVar } from "@apollo/client";

//Components
import { DropdownIcon, Icon } from "components";
import { Avatar } from "rsuite";
import Modals from "@components/Modals/Modals";

const Name = ({ id }) => {
    const { t } = useTranslation();
    const { space } = useReactiveVar(spaces);


    const deleteSpace = async () => {
        modal.loading(true);
    };

    return (
        <div className="space-header__left__name">

            <Modals
                id="deleteSpace"
                size="xs"
                type="icon"
                icon="question-mark"
                title={t("space.modal.delete_space_title")}
                content={t("space.modal.sure_delete")}
                align="center"
                actions={[
                    {
                        label: t("global.delete"),
                        appearance: "primary",
                        hasLoading: true,
                        callback: () => deleteSpace(),
                    },
                ]}
            />

            <DropdownIcon customIcon={
                <div className="space-header__left__name__link">
                    <Avatar>
                        {utils.getInitials('Project Name')}
                    </Avatar>
                    Project Name <Icon icon="arrow-down" />
                </div>
            } placement="bottomStart" appearance="link" options={[
                {
                    label: t("global.add"),
                    icon: "plus",
                    onClick: (e) => {
                        e.preventDefault();
                    },
                    submenu: [
                        {
                            label: t("tasks.add_task"),
                            icon: "tasks",
                            onClick: (e) => {
                                e.preventDefault();
                            }
                        },
                        {
                            label: t("space.add_members"),
                            icon: "user",
                            onClick: (e) => {
                                e.preventDefault();
                                modal.activetab('invite');
                                modal.open("spaceMembers", {
                                    space_id: space.currentId
                                })
                            }
                        }
                    ]
                },
                {
                    label: t("space.edit_details"),
                    icon: "edit",
                    onClick: (e) => {
                        e.preventDefault();
                        modal.open('updateSpaceSettings');
                    }
                },
                {
                    label: t("global.copy_link"),
                    icon: "link",
                    onClick: (e) => {
                        e.preventDefault();
                        let url = window.location.protocol + '//' + window.location.host + '/space/' + space.currentId + '/';
                        utils.copyClipboard(url);
                    }
                },
                {
                    label: t("global.delete"),
                    icon: "trash",
                    darger: true,
                    onClick: (e) => {
                        modal.open('deleteSpace');
                    }
                },
            ]} />
        </div>
    );
};

export default Name;
