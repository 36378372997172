import React, { useState, useEffect } from "react";
import { IconButton, Whisper, Tooltip } from 'rsuite';

//Components
import Icon from "../Icon/Icon";
import SearchList from "../Search/SearchList";
import SearchInput from "../Search/SearchInput";
import IconCard from "../IconCard/IconCard";

const SidebarList = ({ searchFields, searchBtns, data, listComponent }) => {

    const [searchText, setSearchText] = useState("");

    return (
        <div className="sidebar-list">
            <div className="sidebar-list__inside">

                {searchFields && (
                    <div className="sidebar-list__inside__top">

                        <SearchInput callback={setSearchText} />

                        {searchBtns && searchBtns.map((btn, index) => (
                            <Whisper
                                key={index}
                                placement="top"
                                trigger="hover"
                                speaker={
                                    <Tooltip>{btn.tooltip}</Tooltip>
                                }
                            >
                                <IconButton icon={<Icon icon={btn.icon} />} appearance="primary" onClick={() => btn.callback()} />
                            </Whisper>
                        ))}

                    </div>
                )}

                <div className="sidebar-list__inside__bottom">
                    <SearchList search={searchText} data={data} fields={searchFields}>
                        {listComponent}
                    </SearchList>
                </div>
            </div>
        </div>
    );
};

export default SidebarList;