import { Table } from "rsuite";
const { Cell } = Table;

const PadCell = ({ rowData, dataKey, number, ...props }) => {
    return (
        <Cell {...props} >
            {String(rowData.id).padStart(number, '0')}
        </Cell>
    );
};

export default PadCell;