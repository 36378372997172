import React, { memo, useState, useEffect } from "react";
import { Button } from "rsuite";
import { useTranslation } from "react-i18next";

//Components
import SearchList from "../../Search/SearchList";
import SearchInput from "../../Search/SearchInput";
import AvatarLink from "../../AvatarLink/AvatarLink";


const AssignUserInput = ({ assignTome, assigned, callback, isTask }) => {

    const { t } = useTranslation();

    const [searchText, setSearchText] = useState();
    const [users, setUsers] = useState();

    useEffect(() => {
        setUsers([
            {
                id: 1,
                name: "Pedro Alves",
                email: "palves@sapo.pt",
                avatar: "https://i.pravatar.cc/100"
            },
            {
                id: 2,
                name: "John Doe",
                email: "john@email.com",
                avatar: "https://i.pravatar.cc/101"
            },
            {
                id: 3,
                name: "Pedro Alves",
                email: "palves@sapo.pt",
                avatar: "https://i.pravatar.cc/102"
            },
            {
                id: 4,
                name: "John Doe",
                email: "john@email.com",
                avatar: "https://i.pravatar.cc/103"
            },
            {
                id: 1,
                name: "Pedro Alves",
                email: "palves@sapo.pt",
                avatar: "https://i.pravatar.cc/104"
            },
            {
                id: 2,
                name: "John Doe",
                email: "john@email.com",
                avatar: "https://i.pravatar.cc/105"
            },
            {
                id: 3,
                name: "Pedro Alves",
                email: "palves@sapo.pt",
                avatar: "https://i.pravatar.cc/106"
            },
            {
                id: 4,
                name: "John Doe",
                email: "john@email.com",
                avatar: "https://i.pravatar.cc/107"
            },
        ]);
    }, [])


    return <div className={`assignUserInput-wrapper`}>

        <div className="assignUserInput-wrapper__title">{t("space.tasks.task.assignee")}</div>

        <div className="assignUserInput-wrapper__top">
            <div className="assignUserInput-wrapper__top__search">
                <div><SearchInput callback={setSearchText} /></div>
            </div>
            {assignTome && <div className="assignUserInput-wrapper__top__add-me">
                <Button size="md" appearance="ghost" onClick={() => callback(1)} >{isTask ? t("space.tasks.task.assign_me") : t("space.set_me_owner")}</Button>
            </div>}
        </div>

        <div className="assignUserInput-wrapper__bottom">
            <div className="assignUserInput-wrapper__bottom__list">
                <SearchList search={searchText} data={users} fields={["name", "id", "email"]}>
                    <AvatarLink light onClick={(id) => callback(id)} />
                </SearchList>
            </div>
        </div>

    </div>
};

export default memo(AssignUserInput);
