import React from "react";
import { BrowserSeo } from "../../components/";
import { useTranslation } from "react-i18next";
import { Divider, Button } from 'rsuite';


const NotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      <BrowserSeo title="Page not found" />
      <div className="content-view__notFound">

        <div className="not-found-inside">
          <div className="not-found-inside__headline">{t("not_found.title")}</div>
          <div className="not-found-inside__text">{t("not_found.text")}</div>
          <Divider />
          <div className="not-found-inside__button">
            <Button appearance="ghost" href="/">{t("not_found.go_back")}</Button>
          </div>
        </div>

      </div>
    </>
  );
};
export default NotFound;
