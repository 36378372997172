import React, { memo, useCallback, useEffect, useState } from "react";
import { Modal, Button } from "rsuite";
import { useTranslation } from "react-i18next";
import Nav from "@rsuite/responsive-nav";

//Modal stuff
import { useReactiveVar } from "@apollo/client";
import { modals } from "stores";
import { modal } from 'utils';

//Modal Types
import WithIcon from "./Types/WithIcon/WithIcon";

//Components
import Loading from "../Loading/Loading";

const Modals = ({
  id,
  size,
  title,
  type,
  actions,
  className,
  content,
  icon,
  align,
  overflow,
  tabs
}) => {

  const { t } = useTranslation();
  const { open, loading, activeTab } = useReactiveVar(modals);
  const [openModal, setOpenModal] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState(tabs && tabs.find(o => o.default === true));

  //Check which modal ID to open and load
  useEffect(() => {
    if (id === open.id) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [open])

  //Set default Tab
  useEffect(() => {
    if (tabs) {
      let defaultTab = tabs.find(o => o.default === true);
      if (!activeTab) {
        modal.activetab(defaultTab.screen);
      }
    }
  }, [open])


  //On close, reset all modal store
  const onClose = () => {
    modal.loading(false);
    modal.setCallback(null);
    modal.activetab(null);
    modal.open({
      id: null,
      args: null,
      activeTab: null
    });
  }

  //Check which of the contents to load
  const renderContent = useCallback(() => {
    let render;
    switch (type) {
      case "icon":
        render = <WithIcon content={content} icon={icon} align={align} className="icon-modal-content" />;
        break;
      default:
        render = content;
        break;
    }
    return render;
  }, [type]);

  return (
    <>
      <Modal

        backdrop="true"
        open={openModal}
        size={size}
        className={`${className} modal-${id}`}
        onClose={onClose}
        overflow={overflow ? overflow : true}
      >
        {title && (
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body id={"modal-" + id} className={tabs && "with-tabs"}>

          {loading && <Loading size="md" label={t("modal.please_wait")} />}

          {tabs &&
            <Nav onSelect={modal.activetab} appearance="subtle" active={"active"}>
              {tabs.map((tab) =>
                <Nav.Item active={activeTab === tab.screen} eventKey={tab.screen}>{tab.label}</Nav.Item>
              )}
            </Nav>
          }

          {renderContent()}

        </Modal.Body>

        {actions && (
          <Modal.Footer>
            <Button
              appearance="subtle"
              onClick={() => onClose()}
              disabled={loading}
            >
              {t("global.cancel")}
            </Button>
            {actions.map((action) => {
              return (
                <Button
                  color={action.color}
                  appearance={action.appearance}
                  onClick={action.callback}
                  loading={action.hasLoading && loading}
                  disabled={loading}
                >
                  {action.label}
                </Button>
              );
            })}
          </Modal.Footer>
        )}

      </Modal>
    </>
  );
};

export default memo(Modals);
