import React from "react";
import { Helmet } from "react-helmet";

const BrowserSeo = ({ title }) => {
  return (
    <Helmet titleTemplate={`%s - ${process.env.REACT_APP_TITLE}`}>
      <title>{title}</title>
    </Helmet>
  );
};

export default BrowserSeo;
