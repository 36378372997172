import api from 'utils/axios';
import { user } from "stores";
import { set } from "immutable-modify";
import { useSignIn } from 'react-auth-kit'
import jwt_decode from "jwt-decode";
import { useAuthHeader, createRefresh } from 'react-auth-kit'

const auth = {

    login: async (type, data) => {


        let url
        if (type === "login") {
            url = '/login';
        } else {
            url = '/recover';
        }

        api.defaults.baseURL = 'https://api.evatask.com';

        try {
            const resp = await api.post(url, {
                email: data.email,
                password: data.password
            })
                .then(response => {

                    console.log(response);
                    let tokenDecoded = jwt_decode(response.data.access_token);
                    let refreshTokenDecoded = jwt_decode(response.data.refresh_token);

                    console.log(tokenDecoded);
                    console.log(refreshTokenDecoded);

                    //Update user
                    user(set(user(), "login", {
                        isLoggedIn: true,
                        userId: tokenDecoded.user_id,
                        accessToken: response.data.access_token,
                        refreshToken: response.data.refresh_token,
                    }));

                    return {
                        access_token: response.data.access_token,
                        access_token_expires_in: tokenDecoded.exp,
                        refresh_token: response.data.refresh_token,
                        refresh_token_expires_in: refreshTokenDecoded.exp
                    };

                })
                .catch(function (error) {
                    return false;
                });

            return resp;
        } catch (error) {
            return false;
        }

    },

    logout: async () => {

        api.defaults.baseURL = 'https://api.evatask.com';
        const accessToken = localStorage.getItem(process.env.REACT_APP_API_REFRESH_TOKEN_NAME);

        try {

            const resp = await api.delete('/logout', {
                data: {
                    token: accessToken
                }
            })
                .then(response => {

                    console.log(response);
                    console.log(response.data.logout);

                    user(set(user(), "login", {
                        isLoggedIn: false,
                        accessToken: null,
                        refreshToken: null,
                    }));

                    //clear local storage
                    localStorage.clear();

                    return true;

                })
                .catch(function (error) {
                    return false;
                });

            return resp;


        } catch (error) {
            return false;
        }

    },

    refreshApi: createRefresh({
        interval: 10000,   // Refreshs the token in every 10 minutes
        refreshApiCallback: async (
            {   // arguments
                authToken,
                authTokenExpireAt,
                refreshToken,
                refreshTokenExpiresAt,
                authUserState
            }) => {
            try {
                /*
                const response = await axios.post("/refresh", { 'refresh': refreshToken }, {
                    headers: { 'Authorization': `Bearer ${authToken}` }
                });
                */
                return {
                    isSuccess: true,
                    newAuthToken: 1111,
                    newAuthTokenExpireIn: 10,
                    newRefreshTokenExpiresIn: 60
                }
            }
            catch (error) {
                console.error(error)
                return {
                    isSuccess: false
                }
            }
        }
    })

}

export default auth;