import { useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { modals } from "stores";
import { modal } from 'utils'


const useModalCallback = (callbackName, action) => {
    const { callback } = useReactiveVar(modals);

    useEffect(() => {
        if (callback == callbackName) {
            action();
        }
        modal.setCallback(null);
    }, [callback]);
};

export default useModalCallback;