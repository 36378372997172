import { Avatar, Whisper, Tooltip } from "rsuite";
import { Link, useNavigate } from "react-router-dom";
import Profile from "../Profile/Profile";
import { user } from "stores/";
import { set } from "immutable-modify";
import { useTranslation } from "react-i18next";
import { modal, api } from 'utils';

//Components
import Modals from "@components/Modals/Modals";
import Icon from "@components/Icon/Icon";
import DropdownIcon from "@components/DropdownIcon/DropdownIcon";
import ModalSettings from "@views/Space/Modals/ModalSettings/ModalSettings";
import ModalTeamMember from "@views/Organization/Team/Modals/ModalTeamMember";
import Notifications from "../Notifications/Notifications";


const UserNav = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openDrawer = () => {
    user(set(user(), "editModal.open", true));
  };


  const logoutUser = async () => {
    modal.loading(true);

    const logout = await api.auth.logout();
    if (logout) {

      //simulate wait for UX
      setTimeout(() => {
        modal.open(false);
        modal.loading(false);
        //redirect to login
        navigate("/login");
      }, 1000);


    } else {
      modal.open(false);
      modal.loading(false);
    }
  };

  return (
    <>
      <Profile />

      <Modals
        id="logout"
        size="xs"
        type="icon"
        icon="question-mark"
        title={t("logout.title")}
        content={t("modal.sure_logout")}
        align="center"
        actions={[
          {
            label: t("logout.title"),
            appearance: "primary",
            hasLoading: true,
            callback: () => logoutUser(),
          },
        ]}
      />


      <Modals
        id="addSpace"
        title={t("space.space_details")}
        content={<ModalSettings />}
        actions={[
          {
            label: t("global.add"),
            appearance: "primary",
            hasLoading: true,
            callback: () => modal.setCallback("addSpace"),
          },
        ]}
      />

      <Modals
        id="addMember"
        size="xs"
        title={t("organization.add_members")}
        content={<ModalTeamMember />}
        actions={[
          {
            label: t("global.add"),
            appearance: "primary",
            hasLoading: true,
            callback: () => modal.setCallback("addTeamMember"),
          },
        ]}
      />



      <ul className="user-nav">
        <li className="user-nav__organization">
          <Whisper
            placement="bottom"
            trigger="hover"
            speaker={
              <Tooltip>{t("organization.organization_settings")}</Tooltip>
            }
          >
            <Link to="/organization/workspaces">
              <Icon icon="building" /> <label>Scorepixel</label>
            </Link>
          </Whisper>
        </li>

        <li className="user-nav__notifications">
          <Notifications />
        </li>

        <li className="user-nav__add">
          <DropdownIcon icon="plus" placement="bottomEnd" appearance="link" options={[
            {
              label: t("tasks.add_task"),
              icon: "tasks",
              as: Link,
              to: "/"
            },
            {
              label: t("space.add_space"),
              icon: "projects",
              onClick: (e) => {
                e.preventDefault();
                modal.open("addSpace");
              }
            },
            {
              label: t("organization.invite"),
              icon: "user",
              onClick: (e) => {
                e.preventDefault();
                modal.open("addMember");
              }
            },
          ]} />
        </li>


        <li className="user-nav__avatar">


          <DropdownIcon customIcon={<Avatar size="sm" circle src="https://404.error" alt="BS" />} placement="bottomEnd" appearance="link" options={[
            {
              label: t("profile.settings"),
              icon: "user",
              onClick: (e) => {
                e.preventDefault();
                openDrawer();
              }
            },
            {
              label: t("logout.title"),
              icon: "logout",
              onClick: (e) => {
                e.preventDefault();
                modal.open("logout");
              }
            },
          ]} />


        </li>
      </ul>
    </>
  );
};

export default UserNav;
