import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Highcharts, { chart } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useResizeDetector } from 'react-resize-detector';

import { useReactiveVar } from "@apollo/client";
import { global } from "stores";


const TaskUpComing = () => {
    const { t } = useTranslation();
    const { colors, charts } = useReactiveVar(global);
    const { width, height, resizeRef } = useResizeDetector();
    const chartRef = useRef(null);

    useEffect(() => {
        const chart = chartRef.current?.chart;
        if (chart) chart.reflow(false);
    }, [width, height]);

    const avatars = [
        'https://i.stack.imgur.com/2RAv2.png',
        'https://i.stack.imgur.com/Tq5DA.png',
        'https://i.stack.imgur.com/3KRtW.png',
        'https://i.stack.imgur.com/iLyVi.png',
        'https://i.stack.imgur.com/Tq5DA.png',
        'https://i.stack.imgur.com/3KRtW.png',
    ];


    const chartOptions = {
        chart: {
            type: 'column',
            height: 250
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        colors: [colors.blue],
        credits: {
            enabled: false
        },
        title: {
            text: ''
        },



        xAxis: {
            categories: [
                'User 1',
                'User 2',
                'User 3',
                'User 4',
                'User 5',
                'User 6',
            ],

            labels: {
                useHTML: true,
                formatter: function () {
                    console.log(this);
                    return '<img src="' + avatars[this.pos] + '"></img>';
                }
            },

            crosshair: true
        },

        series: [
            {
                name: "Tasks",
                showInLegend: false,
                pointWidth: charts.barThickness,
                data: [1, 2, 1, 4, 3, 6]
            }
        ]
    };


    return (
        <div style={{ "max-height": 250, "height": 250 }} ref={resizeRef} >
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                ref={chartRef}
            />
        </div>
    );
};

export default TaskUpComing;
