import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Components
import { IconButton, ButtonToolbar } from 'rsuite';
import Board from "./Board/Board";
import Content from "@components/Layout/Content/Content";
import SearchInput from "@components/Search/SearchInput";
import Icon from "@components/Icon/Icon";
import DropdownIcon from "@components/DropdownIcon/DropdownIcon";
import IconText from "@components/IconText/IconText";

//Utils
import { board } from "@utils";

//Stores
import { spaces } from "stores";
import { useReactiveVar } from "@apollo/client";


const SpaceTasks = () => {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState("");
    const { space } = useReactiveVar(spaces);

    useEffect(() => {
        //check if searchText is different from the previous state
        board.handleSearchOnColumns(searchText);
    }, [searchText]);

    return <Content
        seo={{ title: t("organization.title"), }}
        className="content-view__spaces__tasks"
        actionHeader={
            {
                left: <>
                    <SearchInput callback={setSearchText} />
                </>,
                right: <><ButtonToolbar>
                    <IconButton icon={<Icon icon='userAlt' />} appearance="subtle" onClick={() => console.log(111)} size="xs">
                        Me
                    </IconButton>
                    <IconButton icon={<Icon icon='users' />} appearance="subtle" onClick={() => console.log(111)} size="xs">
                        Assignees
                    </IconButton>
                    <DropdownIcon customIcon={
                        <IconButton icon={<Icon icon='layers' />} appearance="subtle" onClick={() => console.log(111)} size="xs">
                            Status
                        </IconButton>
                    } placement="bottomEnd" appearance="link" options={[
                        {
                            label: t("global.add"),
                            icon: "plus",
                            onClick: (e) => {
                                e.preventDefault();
                            },
                        },
                    ]} />
                    <DropdownIcon customIcon={
                        <IconButton icon={<Icon icon='sort' />} appearance="subtle" onClick={() => console.log(111)} size="xs">
                            Sort by
                        </IconButton>
                    } placement="bottomEnd" appearance="link" options={[
                        {
                            label: t("global.add"),
                            icon: "plus",
                            onClick: (e) => {
                                e.preventDefault();
                            },
                        },
                    ]} />
                </ButtonToolbar></>,
            }
        }
    >

        {
            /* IF it just started or if there is something to search */
            (
                (space.board.search.text !== '' && space.board.search.count > 0)
                || space.board.search.text === ''
            ) && <Board />
        }

        {
            /* I there is something to search and we get no results */
            (space.board.search.text !== '' && space.board.search.count === 0) && <IconText icon="search" title={t("search.no_results.title")} text={t("search.no_results.text")} />
        }

    </Content>;


};
export default SpaceTasks;
