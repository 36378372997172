import { Avatar, Table } from "rsuite";
import { utils } from 'utils';
const { Cell } = Table;

const RemoteAvatar = ({ rowData, dataKey, ...props }) => {
    console.log(rowData);
    return (
        <Cell {...props} >
            <Avatar src={dataKey ? rowData.avatar : false}>{utils.getInitials(rowData.name)}</Avatar>
        </Cell>
    );
};

export default RemoteAvatar;