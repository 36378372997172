import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Breadcrumb } from "rsuite";

//Routes
import routes from "@routes/main-routes";
import organizationRoutes from "@routes/organization-routes";
import spaceRoutes from "@routes/space-routes";

const Breadcrumbs = () => {

  //Merge all routes
  const allRoutes = [...routes, ...organizationRoutes, ...spaceRoutes];
  console.log(allRoutes);
  const breadcrumbs = useBreadcrumbs(allRoutes);

  return (
    <Breadcrumb maxItems={4}>
      {breadcrumbs.map(({ match, breadcrumb, disabled }, i, row) => {

        let disabledPaths = ['/project'];

        if (i + 1 === row.length) {
          return <Breadcrumb.Item active>{breadcrumb}</Breadcrumb.Item>
        } else {
          if (disabledPaths.includes(match.pattern.path)) {
            return <Breadcrumb.Item active>{breadcrumb}</Breadcrumb.Item>
          } else {
            return <Breadcrumb.Item href={match.pathname}>{breadcrumb}</Breadcrumb.Item>
          }
        }
      }
      )}
    </Breadcrumb >
  );
};

export default Breadcrumbs;
