import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import "./locale/i18n";
import "rsuite/dist/rsuite.min.css";
import "./styles/app.scss";
import Layout from "./components/Layout/Layout";
import toast, { Toaster } from "react-hot-toast";
import { RequireAuth } from 'react-auth-kit'

//Views
import { Space, Login, NotFound, Organization } from "./views";

//Utils
import { createRoutes } from "./utils/routes/routes";
import { auth } from "utils";

//All routes
import routes from "./utils/routes/main-routes";
import organizationRoutes from "./utils/routes/organization-routes";
import spaceRoutes from "./utils/routes/space-routes";


const App = () => {

    return <Suspense fallback={<span>Loading...</span>}>

        <Routes>

            <Route path="login" element={<Login />} />
            <Route path="recover-password" element={<Login recover />} />

            <Route element={<RequireAuth loginPath={'/login'}><Layout /></RequireAuth>}>

                {createRoutes(routes)}

                <Route element={<Space />}>
                    {createRoutes(spaceRoutes)}
                </Route>

                <Route element={<Organization />}>
                    {createRoutes(organizationRoutes)}
                </Route>

            </Route>

            <Route path="not-found" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/not-found/" />} />

        </Routes>

        <Toaster />

    </Suspense>;
}

export default App;