import React from "react";
import { NavLink } from "react-router-dom";

//Components
import Icon from "../Icon/Icon";

const Link = ({ label, icon, iconColor, path, badge, bigIcon, isActive }) => {
  return (
    <NavLink to={path ? path : "/"} className={`app-link ${isActive && 'active'}`}>
      {icon && <Icon icon={icon} className={(bigIcon) ? "big-icon" : iconColor} color={iconColor} />}
      <span className="app-link__label">{label}</span>
    </NavLink>
  );
};

export default Link;
