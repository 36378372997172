import React, { Children, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Whisper, Tooltip, Avatar, Popover } from "rsuite";
import Icon from "../../Icon/Icon";


const UserInfoBox = ({ children, trigger, user, hide }) => {

    const { t } = useTranslation();

    const box = <div className="user-info-box">

        <div className="user-info-box__avatar">
            <img src={user.avatar} />
        </div>
        <div className="user-info-box__info">
            <div className="user-info-box__info__name">{user.name} </div>
            <div className="user-info-box__info__email">
                <Icon icon="envelope" />
                <span>{user.email}</span>
            </div>
        </div>
    </div>;


    return <Whisper
        placement="autoVerticalStart"
        trigger={trigger ? trigger : "hover"}
        speaker={!hide ? <Popover arrow={false} className="user-info-box-popover">{box}</Popover> : <span></span>}
    >
        {children}
    </Whisper>;
};

export default UserInfoBox;
