
import { useTranslation } from "react-i18next";

//Components
import Block from "@components/Block/Block";
import BrowserSeo from "@components/Layout/BrowserSeo/BrowserSeo";
import Content from "@components/Layout/Content/Content";
import { Grid, Row, Col } from "rsuite";
import TaskCompletionTime from './TaskCompletionTime';
import TaskCompletionStatus from './TaskCompletionStatus';
import TaskSimple from './TaskSimple';
import HoursOverTime from './HoursOverTime';
import TaskUpComing from "./TaskUpcoming";

const ProjectReports = () => {
    const { t } = useTranslation();

    return (
        <Content
            seo={
                {
                    title: t("organization.title"),
                }
            }
        >
            <Grid fluid className="content-view__spaces__reports">
                <BrowserSeo title={`${t("space.title")} ${t("space.submenu.reports")}`} />
                <Row gutter={30}>
                    <Col md={12}>
                        <Block title={t("space.reports.task_completion_time")}>
                            <TaskCompletionTime />
                        </Block>
                    </Col>
                    <Col md={12}>
                        <Block title={t("space.reports.task_completion_status")}>
                            <TaskCompletionStatus />
                        </Block>
                    </Col>
                    <Col md={6}>
                        <Block title={t("space.reports.completed_tasks")}>
                            <TaskSimple number={33} />
                        </Block>
                    </Col>
                    <Col md={6}>
                        <Block title={t("space.reports.incomplete_tasks")}>
                            <TaskSimple number={82} />
                        </Block>
                    </Col>
                    <Col md={6}>
                        <Block title={t("space.reports.overdue_tasks")}>
                            <TaskSimple number={12} />
                        </Block>
                    </Col>
                    <Col md={6}>
                        <Block title={t("space.reports.total_tasks")}>
                            <TaskSimple number={2} />
                        </Block>
                    </Col>

                    <Col md={12}>
                        <Block title={t("space.reports.upcoming_tasks")}>
                            <TaskUpComing />
                        </Block>
                    </Col>
                    <Col md={12}>
                        <Block title={t("space.reports.hours_over_time")}>
                            <HoursOverTime key={2} />
                        </Block>
                    </Col>
                </Row>
            </Grid >
        </Content>
    );
};
export default ProjectReports;
