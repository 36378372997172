import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as PremiumIcon } from 'assets/icons/plans/premium.svg';
import { ReactComponent as FreeIcon } from 'assets/icons/plans/free.svg';
import { ReactComponent as StarterIcon } from 'assets/icons/plans/starter.svg';

//Components
import { Grid, Row, Col, Button, List, FlexboxGrid } from 'rsuite';
import Block from "@components/Block/Block";
import BrowserSeo from "@components/Layout/BrowserSeo/BrowserSeo";
import Icon from "@components/Icon/Icon";


const Plans = () => {
    const { t } = useTranslation();

    <BrowserSeo title={t("plans.title")} />

    const PlanBox = ({ title, plan }) => {

        const [planIcon, setPlanIcon] = useState();
        const [planList, setPlanList] = useState([]);
        const [planDesc, setPlanDesc] = useState();
        const [planPrice, setPlanPrice] = useState(0);

        useEffect(() => {
            switch (plan) {
                case "free":
                    setPlanIcon(<FreeIcon />);
                    setPlanList([
                        'Time tracking',
                        'Timeline',
                        'Auto-tracker',
                        'Idle detection',
                        'Pomodoro Timer',
                        'Imports & Exports',
                        '100+ Integrations',
                    ]);
                    setPlanDesc('Compact time tracking & reporting designed with freelancers in mind');
                    setPlanPrice(0);
                    break;
                case "starter":
                    setPlanIcon(<StarterIcon />);
                    setPlanList([
                        'Everything in Free +',
                        'Billable Rates',
                        'Time Rounding',
                        'Time Estimates',
                        'Tasks',
                        'Project Templates',
                        'iCal Integration',
                    ]);
                    setPlanDesc('Built for small teams to work at a fast pace without a lot of overhead');
                    setPlanPrice(9)
                    break;
                default:
                    setPlanIcon(<PremiumIcon />);
                    setPlanList([
                        'Everything in Starter +',
                        'Time tracking reminders',
                        'Scheduled Reports',
                        'Time audits',
                        'Insights',
                        'Project Dashboard',
                        'Admin Dashboard',
                        'Add & Lock Time Entries',
                        'Required fields',
                        'Single Sign-On (SSO)',
                    ]);
                    setPlanDesc('Tools to facilitate consistent processes across multiple teams');
                    setPlanPrice(18)
                    break;
            }
        }, [])



        return <Block title={title} className="plan-block">
            <div className="content">
                <div className="content__icon">
                    {planIcon}
                </div>
                <div className="content__desc">
                    {planDesc}
                </div>
                <div className="content__price">
                    <div className="content__price__inside">
                        <div className="content__price__inside__value">${planPrice}</div>
                        <div className="content__price__inside__recurring-time">
                            per user<br />
                            per month
                        </div>
                    </div>
                </div>
                <div className="content__list">
                    <List>
                        {planList.map((item, index) => (
                            <List.Item key={index} index={index}>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item colspan={3} >
                                        <Icon icon="check" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={19}>
                                        {item}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                        ))}
                    </List>
                </div>
            </div>
            <div className="button">
                <Button appearance="primary" block size="md">Upgrade now!</Button>
            </div>
        </Block >
    }


    return <Grid fluid className="content-view__organization__content__plans">
        <Row className="content-view__organization__content__plans__title">
            <Col md={24}>
                <h3 className="center">Something for Everyone</h3>
                <p>Get the most out of your time and the best out of your team with our paid plans</p>
            </Col>
        </Row>
        <Row gutter={40} className="content-view__organization__content__plans__wrapper">
            <Col md={8}>
                <PlanBox title={t("plans.starter")} plan="starter" />
            </Col>
            <Col md={8}>
                <PlanBox title={t("plans.premium")} plan="premium" />
            </Col>
            <Col md={8}>
                <PlanBox title={t("plans.free")} plan="free" />
            </Col>
        </Row>
    </Grid>;
};

export default Plans;
