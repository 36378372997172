import { useTranslation } from "react-i18next";
import { Row, Col } from 'rsuite';
import { modal } from 'utils';

//Components
import ModalBillingInfo from './Modals/BillingInfo/ModalBillingInfo'
import Block from "@components/Block/Block";
import Modals from "@components/Modals/Modals";

const BillingInfo = () => {
    const { t } = useTranslation();

    return <>


        <Modals
            id="billingInfo"
            size="md"
            title={t("organization.billing_info")}
            content={<ModalBillingInfo />}
            actions={[
                {
                    label: t("global.save"),
                    appearance: "primary",
                    hasLoading: true,
                    callback: () => modal.setCallback("billingInfo"),
                },
            ]}
        />


        <Block title={t("organization.billing_info")} className="billing-block" actions={[
            {
                label: t("organization.change_billing"),
                icon: "edit",
                callback: () => modal.open("billingInfo")
            }
        ]}>
            <Row gutter={30}>
                <Col xs={12}>
                    <dl>
                        <dt>{t('organization.company')}</dt>
                        <dd>Scorepixel</dd>
                        <dt>{t('organization.address')}</dt>
                        <dd>1824 23rd Street</dd>
                        <dt>{t('organization.zip_code')}</dt>
                        <dd>94107</dd>
                        <dt>{t('organization.billing_tax_location')}</dt>
                        <dd>{t('organization.billing_tax_no_applied')}</dd>
                    </dl>
                </Col>
                <Col xs={12}>
                    <dl>
                        <dt>{t('organization.country')}</dt>
                        <dd>United States</dd>
                        <dt>{t('organization.state')}</dt>
                        <dd>California</dd>
                        <dt>{t('organization.billing_tax_id')}</dt>
                        <dd>513123123</dd>
                    </dl>
                </Col>
            </Row>
        </Block>
    </>;
}

export default BillingInfo;