import React, { useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { layout } from "@stores";
import { set } from "immutable-modify";
import moment from 'moment';
import { Whisper, Popover } from 'rsuite';
import { useTranslation } from "react-i18next";
import DetailsPopover from "./DetailsPopover/DetailsPopover";

//Components
import Icon from "@components/Icon/Icon";

let classNames = require("classnames");

const TimeRecord = () => {

    const { t } = useTranslation();
    const { timer } = useReactiveVar(layout);

    let startTime = moment(Date.now()).subtract(5, 'h');;

    const [timePassed, setTimePassed] = useState(moment().diff(startTime));

    useEffect(() => {
        const interval = setInterval(() => {
            setTimePassed(moment().diff(startTime));
        }, 1000); // Update every second

        return () => {
            clearInterval(interval);
        };
    }, []);

    const formattedTimePassed = moment.duration(timePassed).format('hh:mm:ss', { trim: false });


    const stopTimer = () => {
        layout(set(layout(), "timer.active", false));
    };

    let timerClassWrapper = classNames({
        "layout-header__time-record__wrapper": true,
        active: timer.active,
    });

    return <div className={timerClassWrapper}>

        <Whisper placement="bottom" trigger="hover" speaker={<Popover arrow={false} className="time-details"><DetailsPopover /></Popover>} >
            <div className="layout-header__time-record__wrapper__task">
                TN-0387
            </div>
        </Whisper>

        <div className="layout-header__time-record__wrapper__time">
            <div className="layout-header__time-record__wrapper__time__clock">
                {formattedTimePassed}
            </div>
            <div className="layout-header__time-record__wrapper__time__stop" onClick={() => stopTimer()}>
                <Icon icon="square" />
            </div>
        </div>

    </div>;
};

export default TimeRecord;