import FileItem from "../FileItem/FileItem";

const FileList = ({ items }) => {


    return (
        <div className='content-view__spaces__folders__item__file-list'>
            {items.map((item, index) => <FileItem item={item} key={index} />)}
        </div >
    );
}

export default FileList;