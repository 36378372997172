import React, { useEffect } from "react";

//Components
import DropdownIcon from "@components/DropdownIcon/DropdownIcon";
import { Table } from "rsuite";
const { Cell } = Table;



const RemoteTableActions = ({ actions, rowData, dataKey, ...props }) => {

    const generateActions = () => {
        return actions.map((action) => {
            return {
                label: action.label,
                onClick: (e) => {
                    e.preventDefault();
                    action.callback(rowData.id)
                }
            }
        })
    }

    return <Cell {...props} >
        <div className="actions">
            <DropdownIcon icon="verticalDots" placement="bottomEnd" appearance="link" options={generateActions()} />
        </div>
    </Cell>;
};

export default RemoteTableActions;