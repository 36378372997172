import { Table } from "rsuite";
import moment from "moment";
const { Cell } = Table;

const RemoteDate = ({ rowData, dataKey, ...props }) => {
    const date = moment(rowData.date).format("MM/DD/YYYY");
    return (
        <Cell {...props} >
            {date}
        </Cell>
    );
};

export default RemoteDate;