import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { settings } from "utils";
import { useLocation } from 'react-router-dom'

//Components
import SimpleSidebarMenu from "@components/Navigation/SimpleSidebarMenu/SimpleSidebarMenu";


const Sidebar = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const [activeItem, setActiveItem] = useState(null);

    useEffect(() => {
        const parts = location.pathname.split("/");
        const path = parts[2];
        setActiveItem(path);
    }, [location])

    const menuItems = [
        {
            path: '/notifications/',
            icon: 'bell',
            label: t("notifications.all_notifications"),
            bigIcon: true,
            isActive: activeItem === undefined || activeItem === null || activeItem === ""
        },
        {
            path: `/notifications/${settings.notifications.types.comment.slug}/`,
            icon: settings.notifications.types.comment.icon,
            label: settings.notifications.types.comment.label,
            bigIcon: true
        },
        {
            path: `/notifications/${settings.notifications.types.assigned_task.slug}/`,
            icon: settings.notifications.types.assigned_task.icon,
            label: settings.notifications.types.assigned_task.label,
            bigIcon: true
        },
        {
            path: `/notifications/${settings.notifications.types.mentioned.slug}/`,
            icon: settings.notifications.types.mentioned.icon,
            label: settings.notifications.types.mentioned.label,
            bigIcon: true
        },
        {
            path: `/notifications/${settings.notifications.types.assigned_group.slug}/`,
            icon: settings.notifications.types.assigned_group.icon,
            label: settings.notifications.types.assigned_group.label,
            bigIcon: true
        },
    ]

    return (
        <div className="content-view__organization__sidebar">
            <div className="content-view__organization__sidebar__inside">
                <SimpleSidebarMenu items={menuItems} />
            </div>
        </div>
    );
};

export default Sidebar;
