import FieldItem from '../FieldItem/FieldItem';

const FieldList = ({ items }) => {

    return (
        <div className='content-view__spaces__passwords__item__field-list'>
            {items.map((item, index) => <FieldItem item={item} value={item.value} key={index} />)}
        </div >
    );
}

export default FieldList;