import toast from "react-hot-toast";
import { Notification } from 'rsuite';
import i18n from "i18next";


export const utils = {
  notify: (message, type) => {
    let toastArgs = {
      position: "top-center",
      duration: 5000,
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    };
    switch (type) {
      case "error":
        toast.error(message, { ...toastArgs, duration: 4000 });
        break;
      case "success":
        toast.success(message, toastArgs);
        break;
      case "info":
        toast.info(message, toastArgs);
        break;
      case "warning":
        toast.warning(message, toastArgs);
        break;
      default:
        toast(message, toastArgs);
    }

    return true;
  },

  /***
 * Update an object
 * @param {Object} oldObject
 * @param {Object} updatedProperties
 */
  updatedObject: (oldObject, updatedProperties) => {
    return {
      ...oldObject,
      ...updatedProperties,
    };
  },

  /***
   * Get initials from a name
   * @param {String} name
   * @returns {String}
   */
  getInitials: (name) => {
    if (!name) {
      return false;
    }
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
    let initials = [...name.matchAll(rgx)] || [];
    initials = (
      (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
    ).toUpperCase();
    return initials;
  },

  /***
   * Get the current page title
   * @param {String} documentTitle
   * @param {String} pageTitle
   * @returns {String}
   */
  pageTitle: (documentTitle, pageTitle) => {
    let pageTitleEl = document.querySelector(".layout-header__title");
    document.title = documentTitle + " - " + process.env.REACT_APP_TITLE;
    if (pageTitleEl) {
      document.querySelector(".layout-header__title").innerHTML = !pageTitle
        ? documentTitle
        : pageTitle;
    }
  },


  /***
 * Get type of credit card and validation
 * @param {Array} cards
 * @returns {Object}
 */
  creditCard: ((cards) => {
    const cardFromNumber = (n) => {
      n = (n + '').replace(/\D/g, '');
      for (let i = 0; i < cards.length; i++) {
        const card = cards[i];
        if (card.pattern.test(n)) {
          return card;
        }
      }
    };

    const getType = (n) => {
      const card = cardFromNumber(n);
      return card && card.type;
    };

    const defaultFormat = /(\d{1,4})/g;

    const format = (n) => {
      let card = cardFromNumber(n = n + ''), maxLen, groups;
      if (!card) return n;
      maxLen = card.length[card.length.length - 1];
      n = n.replace(/\D+/g, '').slice(0, +maxLen + 1);

      const re = card.format || defaultFormat;
      if (re.global) {
        groups = n.match(re);
      } else {
        groups = re.exec(n);
        if (groups != null) groups.shift();
      }
      return groups && groups.join(' ');
    };

    const checkLuhn = (n) => {
      let sum = 0;
      for (let odd = 1, digits = n.split('').reverse(), i = 0; i < digits.length; i++) {
        let digit = parseInt(digits[i], 10);
        if ((odd = !odd)) digit *= 2;
        if (digit > 9) digit -= 9;
        sum += digit;
      }
      return !(sum % 10);
    };

    const isNumeric = (n) => /^\d+$/.test(n);

    const validate = (n, cvc = '') => {
      n = (n + '').replace(/\s+|-/g, '');
      if (!isNumeric(n)) return { type: '?', error: 'card number not numeric' };

      const card = cardFromNumber(n);
      if (!card) return { type: '?', error: 'card type not recognized' };

      const { type } = card;
      if (card.length.indexOf(n.length) < 0)
        return { type, error: 'invalid card number length' };
      if (card.luhn && !checkLuhn(n))
        return { type, error: 'invalid card number' };
      if (cvc) {
        if (!isNumeric(cvc = cvc + ''))
          return { type, error: 'card cvc not numeric' };
        if (card.cvcLength.indexOf(cvc.length) < 0)
          return { type, error: 'invalid cvc length' };
      }
    };

    return { validate, getType, format };
  })(
    [{
      type: 'amex'
      , pattern: /^3[47]/
      , format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/
      , length: [15]
      , cvcLength: [3, 4]
      , luhn: true
    }
      , {
      type: 'dinersclub'
      , pattern: /^(36|38|30[0-5])/
      , length: [14]
      , cvcLength: [3]
      , luhn: true
    }
      , {
      type: 'discover'
      , pattern: /^(6011|65|64[4-9]|622)/
      , length: [16]
      , cvcLength: [3]
      , luhn: true
    }
      , {
      type: 'jcb'
      , pattern: /^35/
      , length: [16]
      , cvcLength: [3]
      , luhn: true
    }
      , {
      type: 'laser'
      , pattern: /^(6706|6771|6709)/
      , length: [16, 17, 18, 19]
      , cvcLength: [3]
      , luhn: true
    }
      , {
      type: 'maestro'
      , pattern: /^(5018|5020|5038|6304|6759|676[1-3])/
      , length: [12, 13, 14, 15, 16, 17, 18, 19]
      , cvcLength: [3]
      , luhn: true
    }
      , {
      type: 'mastercard'
      , pattern: /^5[1-5]/
      , length: [16]
      , cvcLength: [3]
      , luhn: true
    }
      , {
      type: 'unionpay'
      , pattern: /^62/
      , length: [16, 17, 18, 19]
      , cvcLength: [3]
      , luhn: false
    }
      , {
      type: 'visa'
      , pattern: /^4/
      , length: [13, 14, 15, 16]
      , cvcLength: [3]
      , luhn: true
    }
    ]),

  /*** 
   * Validate email address
   * @param emailAddress
   * @returns {boolean}
   */

  validateEmail: (emailAddress) => {
    return String(emailAddress)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },


  /***
 * Populate select options
 * @param labelKey
 * @param valueKey
 * @param data
 * @returns {Array}
 */
  setSelectOptions: (labelKey, valueKey, data) => {
    let array = [];
    data.map((item) => {
      array.push({
        label: item[labelKey],
        value: item[valueKey]
      });
    });
    return array;
  },


  /***
   * Tag input on change
   * @param values
   * @returns {*}
   */
  tagInputOnChange: (values) => {
    let lastVal = [...values].pop();
    if (!utils.validateEmail(lastVal)) {
      console.log("NOT VALID");
      values.pop()
    }
  },

  /***
 * Debug state
 * @param data
 */
  debugState: (data) => {
    return <Notification><pre>{JSON.stringify(data, null, 2)}</pre></Notification>;
  },


  /***
 * Convert string to underscore
 * @param str
 * @returns {string}
 */
  stringToUnderscore: (str) => {
    return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
  },


  /***
 * Find object by key
 * @param array
 * @param key
 * @param value
 * @returns {*}
 */

  findObjectByKey: (array, key, value) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
    return null;
  },

  getFileIcon: (extension) => {
    let icon = require('../assets/icons/file_extensions/high-contrast/' + extension + '.svg');
    console.log(icon);
    return icon;
  },

  orderByDate: (array, key) => {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  },

  //array of objects search and remove
  removeItemArrayObjects: (array, key, value) => {
    return array.filter(function (obj) {
      return obj[key] !== value;
    });
  },

  copyClipboard: (text) => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(text);
    } else {
      document.execCommand('copy', true, text);
    }
    utils.notify(i18n.t('space.link_copied_clipboard'), 'success')
  },

  ellipsis: (str, length) => {
    return str.length > length ? str.substring(0, length - 3) + "..." : str;
  }

}

