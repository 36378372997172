import { useState, useRef, useEffect } from "react";
import { Grid, Row, Col, Uploader } from "rsuite";
import { useTranslation } from "react-i18next";
import useModalCallback from "@hooks/useModalCallback";
import { modal } from "utils";


const ModalAddFile = () => {

    const { t } = useTranslation();
    const [name, setName] = useState();


    //ON SUBMIT
    useModalCallback("addFile", () => {

        console.log("SUBMITING FORM");

        modal.loading(true);
    });


    return (
        <>

            <Grid fluid>
                <Row className="">
                    <Col xs={24} >
                        <Uploader action="//jsonplaceholder.typicode.com/posts/" draggable disabledFileItem={true} fileListVisible={false}>
                            <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span>Click or Drag files to this area to upload</span>
                            </div>
                        </Uploader>
                    </Col>
                </Row>
            </Grid>

        </>
    );
}

export default ModalAddFile;