import React from "react";
import { Divider } from 'rsuite';

//Components
import Link from "../../Link/Link";


const SimpleSidebarMenu = ({ items }) => {

    return (
        <div className="simple-sidebar-menu">
            {items.map((item) => {
                if (item.divider) {
                    return <Divider />;
                } else {
                    return <Link path={item.path} icon={item.icon} label={item.label} bigIcon={item.bigIcon} />
                }
            })}
        </div>
    );
};

export default SimpleSidebarMenu;
