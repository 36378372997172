import React, { useState, useEffect, useRef } from "react";
import { user } from "stores";
import { set } from "immutable-modify";
import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { utils, schemas, settings, forms } from "utils";

//Components
import Block from "../../../Block/Block";
import Icon from "../../../Icon/Icon";
import Modals from "../../../Modals/Modals";
import TimezonePicker from "@rsuite/timezone-picker";
import "@rsuite/timezone-picker/lib/styles/index.less";
import { Drawer, Button, SelectPicker, Grid, Row, Col, Form, Toggle } from "rsuite";
import ProfileAvatar from "./ProfileAvatar";

const Profile = () => {

  const { t } = useTranslation();
  const { editModal } = useReactiveVar(user);
  const closeDrawer = () => {
    user(set(user(), "editModal.open", false));
  };

  //State stuff
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  //Form stuff
  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState();


  //Delete user
  const deleteUser = () => {
    setModalLoading(true);
    setTimeout(function () {
      setOpenDeleteModal(false);
      closeDrawer();
      utils.notify(t("notification.user_deleted"), "success");
      setModalLoading(false);
    }, 1000);
  };

  //Save settings
  const saveSettings = () => {

    console.log("SAVE SETTINGS");
    console.log(formValue);
    return;
    //closeDrawer();

    ///Check for errors
    if (!formRef.current.check()) {
      console.error('Form Error');
      return;
    }

    console.log("SUBMITING FORM");
    console.log(formValue);
  };



  return (
    <Drawer
      open={editModal.open}
      onClose={() => closeDrawer()}
      className="profile-settings-drawer"
    >
      <Drawer.Header>
        <Drawer.Title>{t("profile.settings")}</Drawer.Title>
      </Drawer.Header>

      <Drawer.Body>
        <div className="rs-drawer-body__top">
          <Form
            ref={formRef}
            model={schemas.organization.userProfile}
            formValue={formValue}
            onCheck={setFormError}
            onChange={setFormValue}
          >
            <Block
              className="rs-drawer-body__top__user-info"
              title={t("profile.your_details")}
            >
              <Grid fluid>
                <Row>
                  <Col xs={24} sm={24}>
                    <div className="rs-drawer-body__top__user-info__avatar">
                      <ProfileAvatar />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={12}>
                    <Form.Group controlId="firstname">
                      <Form.ControlLabel>
                        {t("profile.firstname")}
                      </Form.ControlLabel>
                      <Form.Control name="firstname" />
                    </Form.Group>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Group controlId="lastname">
                      <Form.ControlLabel>
                        {t("profile.lastname")}
                      </Form.ControlLabel>
                      <Form.Control name="lastname" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12}>
                    <Form.Group controlId="email">
                      <Form.ControlLabel>
                        {t("profile.email")}
                      </Form.ControlLabel>
                      <Form.Control name="email" />
                    </Form.Group>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Group controlId="phone">
                      <Form.ControlLabel>
                        {t("profile.phone")}
                      </Form.ControlLabel>
                      <Form.Control name="phone" />
                    </Form.Group>
                  </Col>
                </Row>
              </Grid>
            </Block>

            <Block
              title={t('profile.notifications.title')}
              className="rs-drawer-body__top__email-settings"
            >
              <Grid fluid>
                <Row>
                  <Col xs={24} sm={12}>
                    <Toggle
                      checkedChildren={<Icon icon="check" color="white" />}
                      unCheckedChildren={<Icon icon="close" color="white" />}
                      onChange={(value) => {
                        setFormValue(forms.saveOption("notifications.campaigns", value, formValue));
                      }}
                    />
                    <label>{t('profile.notifications.campaigns')}</label>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Toggle
                      checkedChildren={<Icon icon="check" color="white" />}
                      unCheckedChildren={<Icon icon="close" color="white" />}
                      onChange={(value) => {
                        setFormValue(forms.saveOption("notifications.reminders", value, formValue));
                      }}
                    />
                    <label>{t('profile.notifications.reminders')}</label>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12}>
                    <Toggle
                      checkedChildren={<Icon icon="check" color="white" />}
                      unCheckedChildren={<Icon icon="close" color="white" />}
                      onChange={(value) => {
                        setFormValue(forms.saveOption("notifications.comments", value, formValue));
                      }}
                    />
                    <label>{t('profile.notifications.comments')}</label>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Toggle
                      checkedChildren={<Icon icon="check" color="white" />}
                      unCheckedChildren={<Icon icon="close" color="white" />}
                      onChange={(value) => {
                        setFormValue(forms.saveOption("notifications.daily_report", value, formValue));
                      }}
                    />
                    <label>{t('profile.notifications.daily_report')}</label>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12}>
                    <Toggle
                      checkedChildren={<Icon icon="check" color="white" />}
                      unCheckedChildren={<Icon icon="close" color="white" />}
                      onChange={(value) => {
                        setFormValue(forms.saveOption("notifications.weekly_report", value, formValue));
                      }}
                    />
                    <label>{t('profile.notifications.weekly_report')}</label>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Toggle
                      checkedChildren={<Icon icon="check" color="white" />}
                      unCheckedChildren={<Icon icon="close" color="white" />}
                      onChange={(value) => {
                        setFormValue(forms.saveOption("notifications.monthly_report", value, formValue));
                      }}
                    />
                    <label>{t('profile.notifications.monthly_report')}</label>
                  </Col>
                </Row>
              </Grid>
            </Block>

            <Block
              title={t("profile.time_and_date")}
              className="rs-drawer-body__top__time"
            >
              <Grid fluid>
                <Row>
                  <Col xs={24} sm={12}>
                    <Form.Group controlId="time_zone">
                      <Form.ControlLabel>
                        {t("profile.time_zone")}
                      </Form.ControlLabel>
                      <TimezonePicker block onChange={(value) => {
                        setFormValue(forms.saveOption("time_date.time_zone", value, formValue));
                      }} />
                    </Form.Group>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Group controlId="date_format">
                      <Form.ControlLabel>
                        {t("profile.date_format")}
                      </Form.ControlLabel>
                      <SelectPicker
                        defaultValue="DD-MM-YYYY"
                        data={settings.time.dateFormat}
                        searchable={false}
                        cleanable={false}
                        block
                        onChange={(value) => {
                          setFormValue(forms.saveOption("time_date.date_format", value, formValue));
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12}>
                    <Form.Group controlId="name">
                      <Form.ControlLabel>
                        {t("profile.duration_format")}
                      </Form.ControlLabel>
                      <SelectPicker
                        defaultValue="HH:MM"
                        data={settings.time.durationFormat}
                        searchable={false}
                        cleanable={false}
                        block
                        onChange={(value) => {
                          setFormValue(forms.saveOption("time_date.duration_format", value, formValue));
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Group controlId="name">
                      <Form.ControlLabel>
                        {t("profile.time_format")}
                      </Form.ControlLabel>
                      <SelectPicker
                        defaultValue="24-hour"
                        data={settings.time.timeFormat}
                        searchable={false}
                        cleanable={false}
                        block
                        onChange={(value) => {
                          setFormValue(
                            forms.saveOption("time_date.time_format", value, formValue)
                          );
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12}>
                    <Form.Group controlId="name">
                      <Form.ControlLabel>
                        {t("profile.first_day")}
                      </Form.ControlLabel>
                      <SelectPicker
                        defaultValue="monday"
                        data={settings.time.daysWeek}
                        searchable={false}
                        cleanable={false}
                        block
                        onChange={(value) => {
                          setFormValue(forms.saveOption("time_date.first_day", value, formValue));
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Grid>
            </Block>
            <Block
              title={t("profile.change_password")}
              className="rs-drawer-body__top__change-password"
            >
              <Grid fluid>
                <Row>
                  <Col xs={24} sm={12}>
                    <Form.Group controlId="name">
                      <Form.ControlLabel>
                        {t("profile.current_password")}
                      </Form.ControlLabel>
                      <Form.Control name="name" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12}>
                    <Form.Group controlId="name">
                      <Form.ControlLabel>
                        {t("profile.new_password")}
                      </Form.ControlLabel>
                      <Form.Control name="name" />
                    </Form.Group>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Group controlId="name">
                      <Form.ControlLabel>
                        {t("profile.confirm_password")}
                      </Form.ControlLabel>
                      <Form.Control name="name" />
                    </Form.Group>
                  </Col>
                </Row>
              </Grid>
            </Block>

            <Block
              title={t("profile.account_actions")}
              className="rs-drawer-body__top__account-actions"
            >
              <Grid fluid>
                <Row>
                  <Col
                    xs={24}
                    className="rs-drawer-body__top__account-actions__buttons"
                  >
                    <Button
                      color="red"
                      appearance="primary"
                      onClick={() => setOpenDeleteModal(true)}
                    >
                      {t("profile.delete_account")}
                    </Button>

                    <Modals
                      open={openDeleteModal}
                      size="xs"
                      title={t("profile.delete_account")}
                      className=""
                      type="icon"
                      align="center"
                      onClose={() => setOpenDeleteModal(false)}
                      content={t("modal.sure_delete_account")}
                      loading={modalLoading}
                      icon="question-mark"
                      actions={[
                        {
                          label: t("global.cancel"),
                          appearance: "subtle",
                          callback: () => setOpenDeleteModal(false),
                        },
                        {
                          label: t("global.delete"),
                          appearance: "primary",
                          color: "red",
                          callback: () => deleteUser(),
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Grid>
            </Block>
          </Form>
        </div>

        <div className="rs-drawer-body__bottom">
          <Drawer.Actions>
            <Button appearance="subtle" onClick={() => closeDrawer()}>
              {t("global.cancel")}
            </Button>
            <Button onClick={() => saveSettings()} appearance="primary">
              {t("global.save")}
            </Button>
          </Drawer.Actions>
        </div>
      </Drawer.Body>
    </Drawer>
  );
};

export default Profile;
