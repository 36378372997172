import groups from './groups';
import auth from './auth';
import team from './team';
import workspaces from './workspaces';


export const api = {

    groups: groups,

    auth: auth,

    team: team,

    workspaces: workspaces

}