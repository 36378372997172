import React, { useState, useEffect } from "react";
import { Grid, Row, Col } from "rsuite";

//Components
import Block from "@components/Block/Block";
import BrowserSeo from "@components/Layout/BrowserSeo/BrowserSeo";
import Content from "@components/Layout/Content/Content";

const Dashboard = () => {
  return (
    <Content>
      <Grid fluid className="content-view__dashboard">
        <BrowserSeo title="Dashboard" />
        <Row gutter={30}>
          <Col md={12}>
            <Block title="My tasks" actions={[
              {
                label: "label is here...",
                icon: "search",
                callback: () => alert(1)
              },
              {
                label: "label 222 here...",
                icon: "search",
                callback: () => alert(2)
              }
            ]}>2222</Block>
          </Col>
          <Col md={12}>
            <Block title="Recent Activity">2222</Block>
          </Col>
          <Col md={6}>
            <Block title="Block 4">2222</Block>
          </Col>
          <Col md={6}>
            <Block title="Block 4">2222</Block>
          </Col>
          <Col md={6}>
            <Block title="Block 4">2222</Block>
          </Col>
          <Col md={6}>
            <Block title="Block 4">2222</Block>
          </Col>
        </Row>
      </Grid>
    </Content>
  );
};
export default Dashboard;
