import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Row, Col, Avatar } from 'rsuite';
import { useReactiveVar } from "@apollo/client";
import { modals } from "stores";

//Components
import Block from "@components/Block/Block";
import BrowserSeo from "@components/Layout/BrowserSeo/BrowserSeo";
import RemoteTable from "@components/RemoteTable/RemoteTable";
import RemoteTableActions from "@components/RemoteTable/CustomCell/RemoteTableActions";
import Modals from "@components/Modals/Modals";
import ModalTeamMember from './Modals/ModalTeamMember'
import RemoteAvatar from "@components/RemoteTable/CustomCell/RemoteAvatar";

import {
    api,
    modal,
    utils
} from 'utils'


const Team = () => {
    const { t } = useTranslation();
    const { open } = useReactiveVar(modals);
    const [workspaces, setWorkspaces] = useState();
    const [groups, setGroups] = useState();

    <BrowserSeo title={t("organization.groups")} />

    const fetchData = async () => {
        const workspaces = await api.workspaces.getWorkspaces();
        const groups = await api.groups.getGroups();
        setWorkspaces(utils.setSelectOptions("name", "id", workspaces.results));
        setGroups(utils.setSelectOptions("name", "id", groups.results));
    }
    useEffect(() => {
        fetchData();
    }, [])



    const columns = [
        {
            dataKey: 'avatar',
            label: '',
            width: 60,
            customCell: <RemoteAvatar />
        }, {
            dataKey: 'name',
            label: t('tables.name'),
            flexGrow: 1,
        }, {
            dataKey: 'email',
            label: t('profile.email'),
        }, {
            dataKey: 'workspaces',
            label: t('organization.workspaces'),
            fullText: false
        }, {
            dataKey: 'groups',
            label: t('tables.groups'),
            fullText: false
        }, {
            dataKey: 'id',
            label: '',
            align: "center",
            width: 60,
            customCell: <RemoteTableActions actions={[
                {
                    label: t("global.edit"),
                    callback: (id) => modal.open("editTeamMember", {
                        member_id: id
                    })
                },
                {
                    label: t("global.delete"),
                    callback: (id) => modal.open("removeTeamMember", {
                        member_id: id
                    })
                }
            ]} />
        }
    ];

    const removeTeamMember = () => {
        modal.loading(true);
        setTimeout(() => {
            alert("Deleted " + open.args.member_id);
            modal.close();
        }, 1000);
    }

    return <>

        <Modals
            id="addTeamMember"
            size="xs"
            title={t("organization.add_members")}
            content={<ModalTeamMember />}
            actions={[
                {
                    label: t("global.add"),
                    appearance: "primary",
                    hasLoading: true,
                    callback: () => modal.setCallback("addTeamMember"),
                },
            ]}
        />

        <Modals
            id="editTeamMember"
            size="xs"
            title={t("organization.edit_members")}
            content={<ModalTeamMember edit />}
            actions={[
                {
                    label: t("global.edit"),
                    appearance: "primary",
                    hasLoading: true,
                    callback: () => modal.setCallback("editTeamMember"),
                },
            ]}
        />

        <Modals
            id="removeTeamMember"
            size="xs"
            type="icon"
            icon="question-mark"
            title={t("modal.delete_member")}
            content={t("modal.sure_delete_member")}
            align="center"
            actions={[
                {
                    label: t("global.delete"),
                    appearance: "primary",
                    hasLoading: true,
                    callback: () => removeTeamMember(),
                },
            ]}
        />

        <Grid fluid className="content-view__organization__content__team">
            <Row>
                <Col md={24}>
                    <Block title={t("organization.groups")} className="team-block" withTable noPadding actions={[
                        {
                            label: t("organization.add_members"),
                            icon: "plus",
                            callback: () => modal.open("addTeamMember")
                        }
                    ]}>
                        <RemoteTable wordWrap={"break-word"} rowHeight={65} columns={columns} itemsPerPage="20" tableKey="1" remoteUrl="/team" sizePerPage={10} search filters={[
                            {
                                placeholder: t("organization.workspaces"),
                                type: "select",
                                name: "workspace",
                                values: workspaces
                            },
                            {
                                placeholder: t("organization.groups"),
                                type: "select",
                                name: "group",
                                values: groups
                            }
                        ]} />
                    </Block>
                </Col>
            </Row>
        </Grid>
    </>;
};

export default Team;
