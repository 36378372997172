import { makeVar } from "@apollo/client";

export const modals = makeVar({
    open: {
        id: null,
        args: null
    },
    loading: false,
    callback: null,
    activeTab: null
});

export default modals;
