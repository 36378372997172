import React, { useEffect } from "react";

//Components
import SideFooter from "./SideFooter/SideFooter";
import SideNav from "./SideNav/SideNav";
import Logo from "../../../assets/images/logo.svg";

const Sidebar = () => {


  useEffect(() => {
    console.log("SideNav mounted");
  });


  return (
    <div className="layout-sidebar">
      <div className="logo-wrap">
        <img src={Logo} />
      </div>
      <SideNav />
      <SideFooter />
    </div>
  );
};

export default Sidebar;

