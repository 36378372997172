import { SpaceTasks, SpaceFiles, SpaceAccesses, SpaceReports } from "../../views/Space";
import Access from "views/Space/SpaceAccesses/Access/Access";
import Folder from "views/Space/SpaceFiles/Folder/Folder";
import i18n from "../../locale/i18n";

const getSpaceName = ({ match, location }) => {
    return match.params.spaceId ? "GET SPACE NAME FROM API" : 111;
};

const getAccessName = ({ match, location }) => {
    return match.params.accessId ? "GET ACCESS NAME FROM API" : 222;
};

const getFolderName = ({ match, location }) => {
    return match.params.accessId ? "GET FOLDER NAME FROM API" : 222;
};

export default [

    {
        path: "/space",
        children: [
            {
                path: ":spaceId",
                breadcrumb: getSpaceName,
                exact: true,
                children: [
                    {
                        path: "files",
                        element: <SpaceFiles />,
                        breadcrumb: i18n.t("space.submenu.files"),
                        exact: true,
                        children: [
                            {
                                path: ":folderId",
                                element: <Folder />,
                                breadcrumb: getFolderName,
                            },
                        ]
                    },
                    { path: "tasks", element: <SpaceTasks />, breadcrumb: i18n.t("space.submenu.tasks"), exact: true },
                    { path: "reports", element: <SpaceReports />, breadcrumb: i18n.t("space.submenu.reports"), exact: true },
                    {
                        path: "accesses",
                        element: <SpaceAccesses />,
                        breadcrumb: i18n.t("space.submenu.passwords"),
                        exact: true,
                        children: [
                            {
                                path: ":accessId",
                                element: <Access />,
                                breadcrumb: getAccessName,
                            },
                        ]
                    },
                ]
            }
        ]
    },

];