import api from 'utils/axios';

const groups = {

    getGroups: async () => {
        return api({
            method: 'get',
            url: '/groups',
        })
            .then(resp => {
                if (resp.data.results.length > 0) {
                    console.log(resp.data);
                    return resp.data;
                } else {
                    return false;
                }
            })
            .catch(error => {
                return error;
            });
    }

}

export default groups;