import React, { useState, useEffect } from 'react';

const ScrollText = ({ text }) => {
    const [isScrolling, setIsScrolling] = useState(false);
    const [animationDuration, setAnimationDuration] = useState(0);

    useEffect(() => {
        const calculateDuration = () => {
            const textLength = text.length;
            const baseDuration = 10; // Base duration in seconds
            const charsPerSecond = 30; // Number of characters to scroll per second

            const duration = (textLength / charsPerSecond) + baseDuration;
            setAnimationDuration(duration + 's');
        };

        calculateDuration();
    }, [text]);

    const startScroll = () => {
        setIsScrolling(true);
    };

    const resetScroll = () => {
        setIsScrolling(false);
    };

    return (
        <div
            className="scroll-container"
            onMouseEnter={startScroll}
            onMouseLeave={resetScroll}
        >
            <div
                className={`scroll-content ${isScrolling ? 'marquee' : ''}`}
                style={{ animationDuration }}
            >
                {text}
            </div>
        </div>
    );
};

export default ScrollText;
