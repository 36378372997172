import { makeVar } from "@apollo/client";

const user = makeVar({
  login: {
    isLoggedIn: false,
    token: null,
    refreshToken: null,
  },
  editModal: {
    open: false,
  },
});

export default user;
