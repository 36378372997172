import { Schema } from "rsuite";

export const space = {

    settings: Schema.Model({
        name: Schema.Types.StringType().isRequired('This field is required.'),
        owner: Schema.Types.StringType().isRequired('This field is required.'),
    }),


}