import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Highcharts, { chart } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useResizeDetector } from 'react-resize-detector';

import { useReactiveVar } from "@apollo/client";
import { global } from "stores";


const TaskCompletionStatus = ({ id }) => {
    const { t } = useTranslation();
    const { colors, charts } = useReactiveVar(global);
    const { width, height, resizeRef } = useResizeDetector();
    const chartRef = useRef(null);

    useEffect(() => {
        const chart = chartRef.current?.chart;
        if (chart) chart.reflow(false);
    }, [width, height]);

    const chartOptions = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 250
        },
        plotOptions: {
            pie: {
                allowPointSelect: false,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true
            }
        },

        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
        },


        yAxis: {
            title: {
                text: ''
            }
        },
        colors: [colors.green, colors.lightGray],
        credits: {
            enabled: false
        },
        title: {
            text: ''
        },
        series: [{
            name: 'Tasks',
            colorByPoint: true,
            data: [{
                name: t("charts.completed"),
                y: 100,
                sliced: true,
                selected: false
            }, {
                name: t("charts.incomplete"),
                y: 30,
            }]
        }]
    };

    return (
        <div style={{ "max-height": 250, "height": 250 }} ref={resizeRef}>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                ref={chartRef}
            />
        </div>
    );
};

export default TaskCompletionStatus;
