import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

//Components
import Icon from "@components/Icon/Icon";
import AssignUserIcon from "@components/User/AssignUserIcon/AssignUserIcon";
import PopoverLabel from "@components/PopoverLabel/PopoverLabel";
import { Tooltip, Whisper, Popover } from "rsuite";

let classNames = require("classnames");


const Task = ({ id, task, index, isOver }) => {

    const { t } = useTranslation();
    const [disableDrag, setDisableDrag] = useState(false);
    const [assignUser, setAssignUser] = useState(false);


    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: task.id,
        index,
        disabled: disableDrag || assignUser,
        data: {
            item: task,
            type: 'task'
        }
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };

    let taskClass = classNames({
        'view__spaces__tasks__board__column__inside__tasks__card': true,
        'dragging': isDragging,
        'is-over': isOver,
        'assign-user': assignUser,
    });



    return (
        <div className={taskClass} id={task.id} {...attributes} {...listeners} style={style} ref={setNodeRef}>
            <div className="view__spaces__tasks__board__column__inside__tasks__card__title">
                {task.title} - {task.id} - {index} - {disableDrag.toString()}

            </div>
            <div className="view__spaces__tasks__board__column__inside__tasks__card__info">
                <div className="view__spaces__tasks__board__column__inside__tasks__card__info__left">
                    <div className="view__spaces__tasks__board__column__inside__tasks__card__info__left__start-task">
                        <Whisper placement="autoVerticalStart" trigger="hover" speaker={<Tooltip>{t("space.tasks.task.start_task")}</Tooltip>} onMouseEnter={() => setDisableDrag(true)} onMouseLeave={() => setDisableDrag(false)}>
                            <div><Icon icon="play" /></div>
                        </Whisper>
                    </div>
                    <div className="view__spaces__tasks__board__column__inside__tasks__card__info__left__dates">
                        <PopoverLabel placement="autoVerticalStart" trigger="hover" followCursor={false} label={t("space.tasks.task.created_at")} value={'12-03-2023'}>
                            <Icon icon="calendar" />
                        </PopoverLabel>
                        <PopoverLabel placement="autoVerticalStart" trigger="hover" followCursor={false} label={t("space.tasks.task.due_date")} value={'12-03-2023'}>
                            <Icon icon="calendar-time" />
                        </PopoverLabel>
                        <PopoverLabel placement="autoVerticalStart" trigger="hover" followCursor={false} label={t("space.tasks.task.work_time")} value={'12:33:26'}>
                            <Icon icon="clock" />
                        </PopoverLabel>
                    </div>

                    <div className="view__spaces__tasks__board__column__inside__tasks__card__info__left__id">
                        SC-3628
                    </div>
                </div>
                <div className="view__spaces__tasks__board__column__inside__tasks__card__info__right">
                    <div className="view__spaces__tasks__board__column__inside__tasks__card__info__right__assigned">
                        <AssignUserIcon
                            isTask={{
                                setDisableDrag: setDisableDrag,
                                setAssignUser: setAssignUser,
                                taskId: task.id,
                            }}
                            assigned={task.assigned}
                            callback={(id) => { alert(id) }}
                        />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Task;
