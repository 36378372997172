import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = ({ value, onChange }) => {
    return (
        <div className="editor">
            <ReactQuill
                theme="snow"
                value={value}
                modules={{
                    toolbar: [
                        [{ 'header': [1, 2, 3, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                        ['link']
                    ],
                }}
                onChange={onChange}
                bounds={'.editor'}
            />
        </div>
    );
}

export default Editor;
