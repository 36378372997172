import React from "react";
import { useTranslation } from "react-i18next";

const TaskSimple = ({ number }) => {
    const { t } = useTranslation();

    return (
        <div className="task-simple">
            {number}
        </div>
    );
};

export default TaskSimple;
