import { useState, useRef } from "react";
import { Grid, Row, Col, Form } from "rsuite";
import { useTranslation } from "react-i18next";
import useModalCallback from "utils/hooks/useModalCallback";
import { schemas, modal } from "utils"


const ModalGeneralSettings = ({ modalAction }) => {

    const { t } = useTranslation();
    const formRef = useRef();

    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState();

    //ON SUBMIT
    useModalCallback("generalSettings", () => {

        //Check for errors
        if (!formRef.current.check()) {
            console.error('Form Error');
            return;
        }

        console.log("SUBMITING FORM");
        console.log(formValue);

        modal.loading(true);
    });



    return (
        <Form
            ref={formRef}
            model={schemas.organization.generalSettings}
            formValue={formValue}
            onCheck={setFormError}
            onChange={setFormValue}
        >
            <Grid fluid>
                <Row className="mb-3">
                    <Col xs={24} >
                        <Form.Group controlId="name">
                            <Form.ControlLabel>
                                {t("tables.name")}
                            </Form.ControlLabel>
                            <Form.Control name="name" />
                        </Form.Group>
                    </Col>

                </Row>
                <Row className="mb-3">
                    <Col xs={24} >
                        <Form.Group controlId="organization_ownership">
                            <Form.ControlLabel>
                                {t("organization.organization_ownership")}
                            </Form.ControlLabel>
                            <Form.Control name="organization_ownership" />
                        </Form.Group>
                    </Col>
                </Row>
            </Grid>
        </Form>
    );
}

export default ModalGeneralSettings;