//Component 
import Icon from "components/Icon/Icon";
import { NavLink } from "react-router-dom";
import { Button, ButtonToolbar, Tooltip, Whisper } from "rsuite";

const IconCard = ({ icon, title, subtitle, className, url, actions }) => {
    return <NavLink className={`icon-card ${className}`} to={url} >
        <div className="icon-card__icon">
            <Icon icon={icon} />
        </div>
        <div className="icon-card__name">
            <div>{title}</div>
            <span>{subtitle}</span>
        </div>
        {actions &&
            <div className="icon-card__actions">
                <ButtonToolbar>
                    {actions.map((action, index) =>
                        <Whisper key={index} placement="top" trigger="hover" delayOpen={1000} speaker={<Tooltip >{action.tooltip}</Tooltip>} >
                            <Button appearance="subtle" size="xs" onClick={() => action.callback()}>
                                <Icon icon={action.icon} />
                            </Button>
                        </Whisper>
                    )}
                </ButtonToolbar>
            </div>
        }
    </NavLink>;
}

export default IconCard;