import React from "react";

//Components
import Icon from "components/Icon/Icon";
import { Tooltip, Whisper } from 'rsuite';
var classNames = require("classnames");

const Block = ({ children, title, className, actions, noPadding, withTable }) => {

  let blockClasses = classNames({
    block: true,
    "no-padding": noPadding,
    "with-table": withTable
  });

  const Actions = () => {
    console.log(actions);
    if (actions) {
      console.log(actions);
      let allActions = [];
      actions.map((action) => {
        allActions.push(
          <Whisper delayOpen={600} placement="top" controlId="control-id-hover" trigger="hover" preventOverflow={true} placement={(action.tooltipPosition) ? action.tooltipPosition : "top"} speaker={<Tooltip > {action.label}</Tooltip >}>
            <div className="block__header__actions__item" onClick={() => action.callback()}>
              <Icon icon={action.icon} />
            </div >
          </Whisper >
        );
      });
      return allActions;
    }
    return null;
  }

  return (
    <div className={` ${blockClasses} ${className}`}>
      {(title || actions) && (
        <div className="block__header">
          <div className="block__header__title">{title}</div>
          <div className="block__header__actions"><Actions /></div>
        </div>
      )}
      <div className="block__content">{children}</div>
    </div>
  );
};

export default Block;
