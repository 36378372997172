import { Route } from "react-router-dom";

export const createRoutes = (routes) => {
    return routes.map(({ path, element, children, exact }, key) => {
        return <Route exact={exact} path={path} key={key} element={element}>
            {children && children.map(({ path, element, children, exact }, key) => {
                return <Route exact={exact} path={path} key={key} element={element}>
                    {children && children.map(({ path, element, children, exact }, key) => {
                        return <Route exact={exact} path={path} key={key} element={element}>
                            {children && children.map(({ path, element, children, exact }, key) => {
                                return <Route exact={exact} path={path} key={key} element={element} />
                            })}
                        </Route>
                    })}
                </Route>
            })}
        </Route>
    })
}