import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Navigation = () => {
    const { t } = useTranslation();
    let params = useParams();

    return (
        <div className="space-header__right__navigation">
            <ul className="space-header__right__navigation__list">
                <li className="space-header__right__navigation__list__item">
                    <NavLink to={`/space/${params.spaceId}/tasks`}>{t("space.submenu.tasks")}</NavLink>
                </li>
                <li className="space-header__right__navigation__list__item">
                    <NavLink to={`/space/${params.spaceId}/reports`}>{t("space.submenu.reports")}</NavLink>
                </li>
                <li className="space-header__right__navigation__list__item">
                    <NavLink to={`/space/${params.spaceId}/accesses`}>{t("space.submenu.passwords")}</NavLink>
                </li>
                <li className="space-header__right__navigation__list__item">
                    <NavLink to={`/space/${params.spaceId}/files`}>{t("space.submenu.files")}</NavLink>
                </li>
            </ul>
        </div>
    );
};

export default Navigation;
